import React, { Component } from "react";
import Pagination from 'react-js-pagination';
export default class Documents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activePage: 1,
			pageItems: [],
            width: window.innerWidth, height:window.innerHeight,
		};
	}
    updateDimensions = () => {
       
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
    componentDidMount() {
        const { setResetMethod } = this.props;
        const { activePage } = this.state;

         this.onPageChange(activePage);
    
        setResetMethod(this.onPageChange);
    
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidUpdate(prevProps) {
        const { activePage } = this.state;
        const { projectFileList } = this.props;
        const strProjectFileList = JSON.stringify(projectFileList);
        const _projectFileList = JSON.stringify(prevProps.projectFileList);

        if (strProjectFileList !== _projectFileList) {
            this.onPageChange(activePage);
          
        }
      
    }

    onPageChange = (activePage) => {
        const { projectFileList } = this.props;
        const start = (activePage - 1) * this.props.ITEMS_PER_PAGE;
        const end = (start + this.props.ITEMS_PER_PAGE);
        const slice = projectFileList?.slice(start, end);
          this.setState({
            pageItems: slice,
            activePage,
        });
    };

	render() {
        const {  activePage,pageItems } = this.state;
        const { projectFileList } = this.props;
        const projectListSize = projectFileList?.length;
       
        console.log('projectFileList',pageItems);

		return <>
             { this.state.width > 767 ?  <>
             <div className="table-responsive"  id={this.props.gotoLink}>
                <table className="table table-striped jambo_table">
                    <thead>
                        <tr>
                           {/* <th>Fund</th> */}
                           <th>Form Type</th>
                          <th>Download Files</th>
                        </tr>
                    </thead>
                    <tbody>
					 

   
                    {pageItems?.map(files => {
        return (  files?.map(file => 
            {
                return(
         <tr>
         <td>{file?.documentName}</td>
         <td> 
         <a
          className="capitalize-text cursor-pointer"
          href={file?.s3Location}
          target="_blank"
      > Download
      </a></td>
      </tr>
                )
      }
      
      )
        )
     
})}  </tbody>
                </table> </div>
                 </> :  
                    pageItems?.map(files => {
                        return (  files?.map(file => 
                            {
                                return(
                                    <ul className="list-unstyled">
                                    <li>{file?.documentName}</li> 
                                      <li> <a
                                    className="capitalize-text cursor-pointer"
                                     href={file?.s3Location}
                                     target="_blank"
                                 > Download
                               </a></li>
                               </ul>
                                )
                      }
                      
                      )
                        )
                     
                })
                     }	
                 <div className="text-right">
                    <Pagination
                        firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                        lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                        prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                        nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                        activePage={activePage}
                        itemsCountPerPage={this.props.ITEMS_PER_PAGE}
                        totalItemsCount={projectListSize}
                        onChange={this.onPageChange}
                    />
                </div>
 
               

        </>;
	}
}

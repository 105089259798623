// APPLICATION VERSION
export const APP_VERSION = 'V2.22.06.22A';

// Dynamic CurrentYear
export const currentYear = function() { return new Date().getFullYear() };

// PROJECT/REGULATION/USER TYPES
export const PROJECT_REG_CF = 'PROJECT_REG_CF';
export const PROJECT_REG_D = 'PROJECT_REG_D';
export const PROJECT_REG_ALL = 'PROJECT_REG_All';
export const PROJECT_UPDATE_EDIT = 'PROJECT_UPDATE_EDIT';
export const PROJECT_UPDATE_VIEW = 'PROJECT_UPDATE_VIEW';
export const PROJECT_UPDATE_CREATE = 'PROJECT_UPDATE_CREATE';
export const PROJECT_UPDATE_CLONE_AS_NEW = 'PROJECT_UPDATE_CLONE_AS_NEW';
export const USER_INVESTOR = 'USER_INVESTOR';
export const USER_ADMIN = 'USER_ADMIN';
export const PERFORM_RETURN_OF_CAPITAL = 'PERFORM_RETURN_OF_CAPITAL';
export const VIEW_RETURN_OF_CAPITAL = 'VIEW_RETURN_OF_CAPITAL';
export const TEMPLATE_VERSION = 2;
export const TRACKING_BY_INVESTOR = 'TRACKING_BY_INVESTOR';
export const TRACKING_BY_PROJECT = 'TRACKING_BY_PROJECT';
export const TRACKING_BY_PROJECT_TYPE = 'TRACKING_BY_PROJECT_TYPE';
export const ENTITY_VERIFY_BUSINESS_WITH_DOC = 'verify-business-with-document';
export const ENTITY_VERIFY_CONTROLLER_WITH_DOC = 'verify-with-document';
export const ENTITY_VERIFY_BUSINESS_AND_WITH_DOC = 'verify-controller-and-business-with-document';
export const ROCKET_DOLLAR_IRA_ACCOUNT = 'Rocket Dollar IRA';
export const IRA_OR_OTHER_RETIREMENT = 'IRA or Other Retirement Account';
export const TRUST_ENTITY = 'Trust';

export const ALL_PROJECTS ={value:"allprojects",label:"All Projects"};
export const PROJECT_DETAIL_ICONS = [
    {
        imageName: 'clock',
        imageIcon: 'imgIconClock',
    }, {
        imageName: 'insider deal',
        imageIcon: 'imgIconInsiderDeal',
    }, {
        imageName: 'lower risk',
        imageIcon: 'imgIconLowerRiskIcon',
    }, {
        imageName: 'medal',
        imageIcon: 'imgIconMedal',
    }, {
        imageName: 'money',
        imageIcon: 'imgIconMoneySign',
    }, {
        imageName: 'oil derrick',
        imageIcon: 'imgIconOilDerrick',
    }, {
        imageName: 'oil prices',
        imageIcon: 'imgIconOilPrices',
    }, {
        imageName: 'opportunities',
        imageIcon: 'imgIconOpportunities',
    }, {
        imageName: 'stack of money',
        imageIcon: 'imgIconStackOfMoneyIcon',
    },
];

export const YEAR_MONTHS = [
    {
        id: 'January',
        title: 'January',
    },
    {
        id: 'February',
        title: 'February',
    },
    {
        id: 'March',
        title: 'March',
    },
    {
        id: 'April',
        title: 'April',
    },
    {
        id: 'May',
        title: 'May',
    },
    {
        id: 'June',
        title: 'June',
    },
    {
        id: 'July',
        title: 'July',
    },
    {
        id: 'August',
        title: 'August',
    },
    {
        id: 'September',
        title: 'September',
    },
    {
        id: 'October',
        title: 'October',
    },
    {
        id: 'November',
        title: 'November',
    },
    {
        id: 'December',
        title: 'December',
    },
];

export const ENTITY_TYPES_LIST_WITH_PERSONAL = [
	{
		id: 'Personal',
		title: 'Personal Entity For a Client',
	},
    {
        id: 'LLC',
        title: 'LLC',
    },
    {
        id: 'Partnership',
        title: 'Partnership',
    },
    {
        id: 'Corporation',
        title: 'Corporation',
    },
    {
        id: TRUST_ENTITY,
        title: 'Trust (Revocable or Irrevocable)',
    },
    {
        id: IRA_OR_OTHER_RETIREMENT,
        title: 'IRA or Other Retirement Account',
    },

    {
        id: ROCKET_DOLLAR_IRA_ACCOUNT,
        title: 'Rocket Dollar IRA',
    },
    {
        id: 'soleProprietorship',
        title: 'Sole Proprietorship',
    },
];

export const ACCOUNNT_TYPES = [
	{
		id: 'Checking',
		title: 'Checking',
	},
    {
        id: 'Saving',
        title: 'Saving',
    },
    {
        id: 'Receive only',
        title: 'Receive only',
    },

];


export const ENTITY_TYPES_LIST_NO_PERSONAL = [
	{
		id: 'LLC',
		title: 'LLC',
	},
	{
		id: 'Partnership',
		title: 'Partnership',
	},
	{
		id: 'Corporation',
		title: 'Corporation',
	},
	{
		id: TRUST_ENTITY,
		title: 'Trust (Revocable or Irrevocable)',
	},
    {
        id: IRA_OR_OTHER_RETIREMENT,
        title: 'IRA or Other Retirement Account',
    },
	{
		id: ROCKET_DOLLAR_IRA_ACCOUNT,
		title: 'Rocket Dollar IRA',
	},
    {
        id: 'soleProprietorship',
        title: 'Sole Proprietorship',
    },
];



export const PROJECT_STATUS_LIST = [
    {
        id: 'Open for Investment',
        title: 'Open for Investment',
    },
    {
        id: 'Asset Sold',
        title: 'Asset Sold',
    },
    {
        id: 'Completion',
        title: 'Completion',
    },
    {
        id: 'Confidential',
        title: 'Confidential',
    },
    {
        id: 'Drilling Soon',
        title: 'Drilling Soon',
    },
    {
        id: 'Drilling',
        title: 'Drilling',
    },
    {
        id: 'Dry Hole',
        title: 'Dry Hole',
    },
    {
        id: 'Pending',
        title: 'Pending',
    },
    {
        id: 'Producing',
        title: 'Producing',
    },
    {
        id: 'Unassigned',
        title: 'Unassigned',
    },
];

export const REG_D_REGULATION_TYPES = [
    {
        id: '506B',
        title: '506 B',
    },
    {
        id: '506C',
        title: '506 C',
    },
];

export const PROJECT_REG_D_PROJECT_TYPES = [
    {
        id: 'Conventional',
        title: 'Conventional',
    },
    {
        id: 'Unconventional',
        title: 'Unconventional',
    },
];

export const GP_LP_FORCED_TYPES = [
	{
		id: 0,
		title: 'GP and LP Both Allowed'
	},
	{
		id: 1,
		title: 'GP Only'
	},
	{
		id: 2,
		title: 'LP Only'
	}
]

export const PROJECT_REG_D_CASHCALL_TYPES = [
	{
		id: 1,
		title: 'LP/GP Equal'
	},
	{
		id: 2,
		title: 'LP First, Then GP'
	}
];

export const STATUS_MAP = {
    '0': 'pending',
    '1': 'failed',
    '2': 'canceled',
    '3': 'success',
};

//  TOOLTIP CONTENT
export const ALL_INVESTMENTS_CARD_ROI = `Return on Investment ("ROI", "MOIC", "Multiple on Invested Capital", or "Return
    multiple") measures the gain or loss generated on an investment relative to the amount of money invested. It is
    calculated by dividing your total returned net revenue by the total net invested capital.`;
export const ALL_INVESTMENTS_CARD_IRR = `Internal Rate of Return ("IRR", "ROR" or "Rate of Return") is a metric used to
    estimate the profitability of a project. The internal rate of return is the interest rate (or discount rate) at
    which the net present value of all the cash flows (both positive and negative) from a project or investment equal
    zero. The term internal refers to the fact that the internal rate excludes external factors, such as inflation,
    the cost of capital, or various financial risks.`;
export const ALL_INVESTMENTS_CARD_PAYBACK = `Payback Period ("payback" or "break-even period") is the length of time
    required to recover the initial cost(s) of an investment. The payback period ignores the time value of money (TVM),
    unlike other methods of capital budgeting such as net present value (NPV), internal rate of return (IRR), and
    discounted cash flow.`;
export const ALL_INVESTMENTS_PROJECTED_METRICS = `Not a guarantee. Forward-looking projection only. Read the Disclosure Memorandum,
    the risk factors therein, and invest wisely.`;

// HTTP CODES
export const HTTP_SUCCESS = 200;
export const HTTP_SUCCESS_CREATED = 201;
export const HTTP_SUCCESS_ACCEPTED = 202;

export const HTTP_ERR_BAD_REQUEST = 400;
export const HTTP_ERR_UNAUTHORIZED = 403;
export const HTTP_ERR_NOT_FOUND = 404;

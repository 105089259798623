/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';
import React from 'react';
import { convertObjectToArray, getUUID } from '../../../../../utils/General';
import SelectedFilesComponent from '../SelectedFilesComponent/SelectedFilesComponent';
import Icon from '../../../IconComponent/IconComponent';
import NotificationService from '../../../../../utils/NotificationService';
import FileDetailsEditorComponent from '../../../FileDetailsEditorComponent/FileDetailsEditorComponent';

class InvestorFilesUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: [],
            fileToEdit: {
                name: '',
            },
            isEditingFile: false,
        };

        this.refFileInput = React.createRef();
    }

    onEditClickHandler = (fileToEdit) => {
        this.setState({
            fileToEdit,
            isEditingFile: true,
        });
    };

    onCancelEdit = () => {
        this.setState({
            isEditingFile: false,
            fileToEdit: {
                name: '',
            },
        });
    };

    onSaveFileChanges = (fileData) => {
        const { fileToEdit, selectedFiles } = this.state;
        const filesCopy = [...selectedFiles];
        const fileIndex = selectedFiles.indexOf(fileToEdit);

        filesCopy[fileIndex] = Object.assign(filesCopy[fileIndex], fileData);

        this.setState({ selectedFiles: filesCopy }, this.onCancelEdit);
    };

    onFileInputChange = (event) => {
        const oldSelectedFiles = [...this.state.selectedFiles]; // eslint-disable-line
        const { files } = event.target;

        const _files = convertObjectToArray(files)
            .filter(this.isCorrectSize)
            .filter(this.isAllowedFileType)
            .map(this.addFieldsToFile);

        if (_files.length !== files.length) {
            NotificationService.warning(
                'Remember that the files must be 32MB of size or less and we '
                + 'only accept this files types:\n"PDF", "DOC", "DOCX", "XLS", "XLSX", '
                + '"PPT", "PPTX", "GIF", "JPG", "PNG" and "TXT"',
                10000,
            );
        }

        const _selectedFiles = [...oldSelectedFiles, ..._files];

        this.setState({ selectedFiles: _selectedFiles });
        this.refFileInput.current.value = null;
    };

    addFieldsToFile = (file) => {
        file.key = getUUID();
        file.docYear = '';
        file.fileType = 0;
        file.docMonth = '';
        file.projectType = '';
        file.docDescription = '';
        file.selectedProjectId = 0;
        file.displayNotes = '';
        file.privateNotes = '';
        return file;
    };

    isAllowedFileType = (file) => {
        const acceptedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'gif', 'jpg', 'png', 'txt'];
        const type = file.name.split('.')
            .pop();

        return acceptedFileTypes.some(fType => fType === type);
    };

    isCorrectSize = (file) => {
        const MAX_MB_PER_FILE = 32;
        const fileSize = file.size;
        const result = parseInt(((fileSize / 1024) / 1000));

        return (MAX_MB_PER_FILE >= result);
    };

    onRemoveFile = (file) => {
        const { selectedFiles } = this.state;
        const listCopy = [...selectedFiles];
        const index = selectedFiles.indexOf(file);

        listCopy.splice(index, 1);

        this.setState({ selectedFiles: listCopy });
    };

    cleanSelectedFiles = () => {
        this.setState({ selectedFiles: [] });
    };

    onUploadFiles = () => {
        const { onUploadFile } = this.props;
        const { selectedFiles } = this.state;

        const mappedFiles = {};
        const formData = new FormData();

        selectedFiles.forEach((file) => {
            const {
                docYear, fileType, docMonth, projectType, name,
                docDescription, selectedProjectId, displayNotes, privateNotes,
            } = file;
            const _name = name.split(' ')
                .join('_');

            mappedFiles[_name] = {
                docYear,
                fileType,
                docMonth,
                projectType,
                docDescription,
                selectedProjectId,
                displayNotes,
                privateNotes,
            };
            formData.append('investorFiles', file);
        });

        formData.append('mappedData', JSON.stringify(mappedFiles));

        onUploadFile(formData, this.cleanSelectedFiles);
    };

    // RENDER MANAGER

    renderUploadButton = () => {
        const { selectedFiles } = this.state;

        if (!selectedFiles || !selectedFiles.length) return null;

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <button
                    className="btn btn-primary upper-case-text"
                    onClick={this.onUploadFiles}
                    type="button"
                >
                    <Icon iconName="upload" extraClasses="white-text" />
                    {' '}
                    Upload Documents
                </button>
            </div>
        );
    };

    renderSelectedFiles = () => {
        const { selectedFiles, isEditingFile, fileToEdit } = this.state;
        const { mappedFileTypes, allProjects } = this.props;
        const { mappedRegDProject, mappedRegCFProjects } = allProjects;

        if (!selectedFiles || !selectedFiles.length) return null;

        const items = selectedFiles.map(item => (
            <SelectedFilesComponent
                file={item}
                fileToEdit={fileToEdit}
                isEditingFile={isEditingFile}
                mappedFileTypes={mappedFileTypes}
                onSelectFileToEdit={this.onEditClickHandler}
                onRemoveFile={this.onRemoveFile}
                mappedRegDProject={mappedRegDProject}
                mappedRegCFProjects={mappedRegCFProjects}
            />
        ));

        return (
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Project Name</th>
                            <th>Document Type</th>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Display Note</th>
                            <th>Private Note</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        );
    };

    renderFileEditor = () => {
        const { fileTypeList, allProjects } = this.props;
        const { isEditingFile, fileToEdit } = this.state;
        const { regCfProjectList, regDProjectList } = allProjects;

        if (!isEditingFile) return null;

        return (
            <FileDetailsEditorComponent
                investorFileTypes={fileTypeList}
                selectedFileName={fileToEdit.name}
                onCancelFileEdit={this.onCancelEdit}
                onSaveChanges={this.onSaveFileChanges}
                fileId={fileToEdit.key}
                fileToEdit={fileToEdit}
                regCfProjectList={regCfProjectList}
                regDProjectList={regDProjectList}
            />
        );
    };

    render = () => (
        <div className="col-xs-12">
            <fieldset>
                <h2>Actions</h2>
             
                    <label
                        className="py-3 btn btn-default upper-case-text btn-green text-white"
                        htmlFor="investorFilesInput"
                    >
                        Upload Documents
                        <input
                            multiple
                            type="file"
                            className="no-display"
                            id="investorFilesInput"
                            ref={this.refFileInput}
                            onChange={this.onFileInputChange}
                        />
                    </label>
          
                {this.renderSelectedFiles()}
                {this.renderUploadButton()}
            </fieldset>

            {this.renderFileEditor()}
        </div>
    );
}

InvestorFilesUploader.propTypes = {
    allProjects: PropTypes.object.isRequired,
    fileTypeList: PropTypes.array.isRequired,
    mappedFileTypes: PropTypes.object.isRequired,
    onUploadFile: PropTypes.func.isRequired,
};

InvestorFilesUploader.defaultProps = {
    allProjects: {},
    fileTypeList: [],
    mappedFileTypes: {},
    onUploadFile: () => null,
};

export default InvestorFilesUploader;

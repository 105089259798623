import React from 'react';
import crypto from 'crypto';
import NotificationService from './NotificationService';
import { STATUS_MAP } from './ApplicationConstants';


const SEC_PER_MIN = 60;
const MIL_SEC = 1000;
export const No_Fee_Project =591;
export const Half_Fee_Project = 0;
export const Wildcat_Project_ID = 586;
export const Bitcoin_Project_ID = 585;
export const LP_Project_ID =581;
export const GP_Project_ID =584;
export const Yield_Fund_2_ID = 588;
export const America_First_Energy_Fund_1 = 590;
export const Innovation_Fund_1 = 591;
export const Opportunity_Fund_1_ID = 592;

export const  Bitcoin_Wallet_Verification_Code = {
    needsWalletInfo:1,
	inProgress: 3,
	depositMade: 3,
    retry: 4,
	connected:5,
	
	
  }

export const getUUID = () => {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
        .toString(32);

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};


export const isCorrectEmail = (emailStr) => {
    const validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validator.test(emailStr);
};


export const checkValidPWChars = (str) => {
    return (!str.includes('&') && !str.includes('[') && !str.includes(']') && !str.includes('<') && !str.includes('>') && !str.includes('"') && !str.includes("'"));
};


export const updateRequestCounter = (state, countKey) => {
    const element = state[countKey];
    const plus = (element + 1);
    const minus = element <= 0 ? 0 : (element - 1);

    return {
        plus,
        minus,
    };
};



export const computeFundPercentage = (amountRecollected, minGoalAmount, maxGoalAmount) => {
    const maxGoal = maxGoalAmount || 1;
    const minGoal = minGoalAmount || 1;
    const fundedIdentifier = ((amountRecollected / maxGoal) * 100).toFixed(0);
    const recollectionPercentage = ((amountRecollected / minGoal) * 100).toFixed(0);
    const minGoalIdentifier = ((minGoal / maxGoal) * 100).toFixed(0);

    return {
        maxGoal,
        minGoal,
        fundedIdentifier,
        minGoalIdentifier,
        recollectionPercentage,
    };
};


export const convertObjectToArray = filesObject => Object.keys(filesObject)
    .map(key => filesObject[key]);

export const isCorrectFileSize = (file, maxFileSizeMB = 32) => {
    const fileSize = file.size;
    const result = parseInt(((fileSize / 1024) / 1000));

    return (maxFileSizeMB >= result);
};


export const isAllowedFileType = (file, acceptedFileTypes) => {
    let fileTypes = acceptedFileTypes;
    const type = file.name
        .toLowerCase()
        .split('.')
        .pop();

    if (!fileTypes || !fileTypes.length) {
        fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'gif', 'jpg', 'png', 'txt'];
    }

    return fileTypes.some(fType => fType === type);
};


export const showFileTypeWarning = (filteredFileList, originalFileList, maxFileSizeMB) => {
    if (filteredFileList.length !== originalFileList.length) {
        NotificationService.warning(
            `Remember that the files must be ${maxFileSizeMB}MB of size or less.\n 
             Only these files types are accepted:
             PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, GIF, JPG, PNG, TXT`,
            10000,
        );
    }
};

export const getInputFileList = (newFileList, oldFileList, maxFileSizeMB = 32) => {
    const _files = convertObjectToArray(newFileList)
        .filter(file => isCorrectFileSize(file, maxFileSizeMB))
        .filter(file => isAllowedFileType(file));

    showFileTypeWarning(_files, newFileList, maxFileSizeMB);

    return [...oldFileList, ..._files];
};


export const yearSelectGenerator = (start, end) => {
    const years = [];

    for (let year = start; end >= year; year++) {
        const strYear = year.toString();
        const yearObj = {
            id: strYear,
            title: strYear,
        };

        years.push(yearObj);
    }

    return years;
};


export const DATE_FORMAT_MDY_HIS = {year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit'};


export const getStatusFromCode = (status) => {
    const newStatus = STATUS_MAP[status];

    if (!newStatus) {
        return 'un set';
    }

    return newStatus;
};


export const formatPhoneNumber = (phoneNumber) => {
 const match = phoneNumber.replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    return !match[2] ? match[1] : `${match[1]}-${match[2]}${match[3] ? `-${match[3]}` : ''}`;
};
export const RegFormatPhoneNumber = (phoneNumber) => {
   return phoneNumber?.replace(/-/g, "");

};

export const getHash = (el) => {
    const str = JSON.stringify(el);

    return crypto.createHash('sha1')
        .update(str)
        .digest('hex');
};


export const isValidUrl = (strUrl) => {
    const URLRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    return URLRegex.test(strUrl);
};


export const minutesToMilliSec = min => (min * SEC_PER_MIN * MIL_SEC);

export const ROI =(investment,earnings)=>{
    if(investment,earnings){
    let ROI = Number(earnings / investment) * 100;
      return ROI;
        }
    
    }

export const formatMoney = (num) => {
	if (num) {
		num = Number(num).toFixed(2);
		return String(num).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
	}
	else {
		return null;
	}
};
export const formatChartLabel = (num) => {
	if (num) {
		num = Number(num).toFixed(0);
	let amount = String(num).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
         return `$${amount}`
	}
	else {
		return null;
	}
};
export const formatChartMoney = (num) => {
	if (num) {
		num = Number(num).toFixed(2);
	let amount = String(num).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
         return `$${amount}`
	}
	else {
		return null;
	}
};

export const formatMoneyShowZero = (num) => {
	if (num || num === 0) {
		num = Number(num).toFixed(2);
		return String(num).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
	}
	else {
		return null;
	}
};

// Notes on Rounding (and EPSILON):  https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
export function round2(num) {
	return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getImpersonateID =  () =>{

    let impersonateID =	localStorage.getItem('investorId');
    let parseImpersonateID =JSON.parse(impersonateID);
    if(parseImpersonateID ===null) parseImpersonateID ="";
    return parseImpersonateID;
}

export function formatPageTitle(page){

   const pathname =page.pathname?.toLowerCase()?.toString()?.trim();
	if(pathname ==="/app/earnings2") {
       
		return "Earnings"
	}
   else if(pathname ==="/app/investordashboard") {
		return "Account Dashboard"
	}
    else if(pathname ==="/app/investmentopportunities") {
		return "Investment Opportunities"
	}
    // else if(pathname ==="/app/drillingfund") {
	// 	return "Drilling Fund I LLC"
	// }
    else if(pathname ==="/app/bitcoindiscoveryfund") {
		return "Bitcoin Discovery Fund"
	}
    // else if(pathname === "/app/yieldfund2") {
	// 	 return "Yield Fund II "
	// }
    else if(pathname === "/app/americafirstyieldfund1") {
        return "America First Energy Fund I"
   }
   else if(pathname ==="/app/opportunityfund1") {
    return "Opportunity Fund I"
}
    else if(pathname ==="/app/usernotifications") {
		return "Fund Overview"
	}
    else if(pathname ==="/app/innovationfund1"){
        return "Innovation Fund I"
    }
    else if(pathname ==="/app/mybankaccounts") {
		return "Bank Accounts"
	}
    else if(pathname ==="/app/investor/my-files") {
		return "Documents"
	}
    else if(pathname ==="/app/messages") {
		return "Messages"
	}
    else if(pathname ==="/app/profile") {
		return "My Account Settings"
	}
    else if(pathname ==="/app/admindashboard") {
		return "Admin Dashboard"
	}
    else return ""
}

export function numberMaskify(number){ 
let accountNumber = number?.toString();
  if(accountNumber?.length > 1){
      return accountNumber?.replace(/.(?=.{4})/g, "*");
  }
  else return ""
}
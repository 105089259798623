import React from 'react';
import PropTypes from 'prop-types';
import SelectComponent from '../../InputComponents/SelectComponent/SelectComponent';
import DateInputComponent from '../../InputComponents/DateInputComponent/DateInputComponent';
import SingleLineInputComponent from '../../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import HideComponent from '../../HideComponent/HideComponent';

class RecordBusinessInfoComponent extends React.Component {
    ENTITY_VERIFICATION_OBJ = 'entityVerificationInfo';

    OWNER_TYPE_LIST = [
        {
            id: '1',
            title: 'Single Owner',
        },
        {
            id: '2',
            title: 'Multiple Owners',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            classificationList: [],
        };
    }

    onSelectBusinessCategory = (event) => {
        const { businessClassificationsList, onInputChange } = this.props;
        const { value } = event.target;

        this.setState({ classificationList: businessClassificationsList[value] });
        onInputChange(event, this.ENTITY_VERIFICATION_OBJ);
    };

    onEntityInputChange = (event) => {
        const { onInputChange } = this.props;
        const { name } = event.target;

        if (name === 'businessCategories') {
            this.onSelectBusinessCategory(event);
        }

        onInputChange(event, this.ENTITY_VERIFICATION_OBJ);
    };

    onSSNChange = (event, objName) => {
        const { name, value } = event.target;
        const { onInputChange } = this.props;

		let match = value.replace(/\D/g, '')
			.match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
		let result = !match[3] ? (!match[2] ? match[1] : `${match[1]}-${match[2]}`) : `${match[1]}-${match[2]}-${match[3]}`;

		onInputChange({
			target: {
				name,
				value: result,
			},
		}, objName);
    };

    onEINChange = (event) => {
        const { name, value } = event.target;
        const { onInputChange } = this.props;

        const match = value.replace(/\D/g, '')
            .match(/(\d{0,2})(\d{0,7})/);
        const result = !match[2] ? match[1] : `${match[1]}-${match[2]}`;

        onInputChange({
            target: {
                name,
                value: result,
            },
        }, this.ENTITY_VERIFICATION_OBJ);
    };



    onCertifyChange = (event) => {
		const { name } = event.target;
		const { onInputChange } = this.props;

		onInputChange({
			target: {
				name,
				value: event.target.checked,
			},
		}, this.ENTITY_VERIFICATION_OBJ);
	};
    
    // onBusinessNameInputChange = (event) => {
	// 	const { name } = event.target;
	// 	const { onInputChange } = this.props;

	// 	onInputChange({
	// 		target: {
	// 			name,
	// 			value: event.target.value,
	// 		},
	// 	}, this.ENTITY_VERIFICATION_OBJ);
	// };

   
   
    render = () => {
        const { classificationList } = this.state;
        const {
            businessCategoriesList, isTrustEntity, isRocketDollarIRA, isLLC_Corp_Part,
            ein, ssn, dob, ownerType, businessCategories, isOtherIRA,
            businessClassification, investorJobTitle, legalFN, legalLN, isSoleProprietorship
            // ,businessName
        } = this.props;
        // console.log('propsss',this.props);
        const containerClass = isTrustEntity || isRocketDollarIRA || isOtherIRA() ? 'col-md-6 col-lg-4' : 'col-md-4 col-lg-3';
        return (
            <div className="container">
                <HideComponent hide={isLLC_Corp_Part || isTrustEntity || isRocketDollarIRA || isSoleProprietorship || isOtherIRA()}>
                    <div className="col-xs-12">
                        <fieldset>
                            <SelectComponent
                                xs={12}
                                name="ownerType"
                                value={ownerType}
                                onChange={this.onEntityInputChange}
                                selectTitle="Entity Ownership Composition"
                                options={this.OWNER_TYPE_LIST}
                                isRequired
                            />
                        </fieldset>
                    </div>
                </HideComponent>

                <div className="col-xs-12">
                    <fieldset>
                        <HideComponent hide={isRocketDollarIRA || isTrustEntity || isOtherIRA()}>
                            <div className="col-xs-12 col-sm-6">
                                <SelectComponent
                                    name="businessCategories"
                                    value={businessCategories}
                                    onChange={this.onEntityInputChange}
                                    selectTitle="Business category"
                                    options={businessCategoriesList}
                                    isRequired
                                />
                            </div>

                            <div className="col-xs-12 col-sm-6">
                                <SelectComponent
                                    name="businessClassification"
                                    value={businessClassification}
                                    onChange={this.onEntityInputChange}
                                    selectTitle="Business Classification"
                                    options={classificationList}
                                    isRequired
                                />
                            </div>
                        </HideComponent>

                        <HideComponent hide={isTrustEntity || isRocketDollarIRA || isSoleProprietorship || isOtherIRA()}>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={this.onEntityInputChange}
                                    elementValue={investorJobTitle}
                                    placeholder="CEO, COO, etc."
                                    inputTitle="Your Job Title"
                                    inputName="investorJobTitle"
                                    isRequired
                                />
                            </div>
                        </HideComponent>
                        {/* <HideComponent hide={!isSoleProprietorship}>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={this.onBusinessNameInputChange}
                                    elementValue={businessName}
                                    placeholder="Jane Corp, etc"
                                    inputTitle="Business Name"
                                    inputName="businessName"
                                    isRequired
                                />
                            </div>
                        </HideComponent> */}

                        <div className={`col-xs-12 col-sm-6 ${containerClass}`}>
                            <SingleLineInputComponent
                                maxInputLength={null}
                                onInputUpdate={event => this.onSSNChange(event, this.ENTITY_VERIFICATION_OBJ)}
                                elementValue={ssn}
                                inputTitle="Full nine digits of your SSN"
                                inputName="ssn"
                                placeholder="XXX-XX-XXXX"
                                isRequired
                            />
                        </div>

                        <div className={`col-xs-12 col-sm-6 ${isTrustEntity || isRocketDollarIRA || isOtherIRA() ? 'col-md-6 col-lg-4' : 'col-md-4 col-lg-3'}`}>
                            <DateInputComponent
                                onInputChange={this.onEntityInputChange}
                                elementValue={dob}
                                inputTitle="Your Date Of Birth"
                                inputName="dob"
                                dateType="date"
                                isRequired
                            />
                        </div>

                        <div className={`col-xs-12 ${isTrustEntity || isRocketDollarIRA || isSoleProprietorship || isOtherIRA() ? 'col-sm-12 col-md-12 col-lg-4' : 'col-sm-12 col-md-12 col-lg-3'}`}>
                            <SingleLineInputComponent
                                maxInputLength={null}
                                onInputUpdate={this.onEINChange}
                                elementValue={ein}
                                inputTitle={"Employer Identification Number" + ((isTrustEntity || isSoleProprietorship) ? ' (optional)' : '')}
                                inputName="ein"
                                placeholder="XX-XXXXXXX"
                                isRequired={!isTrustEntity || !isSoleProprietorship}
                            />
                        </div>
						<div className={`col-xs-12`}>
							<label className="custom-container input-label" style={{ textTransform: 'none' }}>
								I, {legalFN} {legalLN}, hereby certify, to the best of my knowledge, that the information provided above is complete and correct.
								<input type="checkbox" required ref="certify" name="certify" id="certify" onChange={this.onCertifyChange} />
								<span className="checkmark"></span>
							</label>
						</div>
                    </fieldset>
                </div>
            </div>
        );
    };
}

RecordBusinessInfoComponent.propTypes = {
    businessCategories: PropTypes.string.isRequired,
    businessCategoriesList: PropTypes.array.isRequired,
    businessClassification: PropTypes.string.isRequired,
    businessClassificationsList: PropTypes.array.isRequired,
    dob: PropTypes.string.isRequired,
    ein: PropTypes.string.isRequired,
    investorJobTitle: PropTypes.string.isRequired,
    // businessName: PropTypes.string.isRequired,
    isLLC_Corp_Part: PropTypes.bool.isRequired,
    isRocketDollarIRA: PropTypes.bool.isRequired,
    isTrustEntity: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    ownerType: PropTypes.string.isRequired,
    ssn: PropTypes.string.isRequired,
};

RecordBusinessInfoComponent.defaultProps = {
    businessCategories: '',
    businessCategoriesList: [],
    businessClassification: '',
    businessClassificationsList: [],
    dob: '',
    ein: '',
    investorJobTitle: '',
    // businessName: '',
	isLLC_Corp_Part: false,
    isRocketDollarIRA: false,
    isTrustEntity: false,
    onInputChange: () => null,
    ownerType: '',
    ssn: '',
};

export default RecordBusinessInfoComponent;

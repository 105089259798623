/*
 * This file is used for all 3 steps of the /register page.
 */

import React, { Component } from "react";
import swal from "sweetalert2";
import ReactPasswordStrength from "react-password-strength";
import { sessionService } from "redux-react-session";
import { withRouter } from "react-router-dom";
import NavBar from "./components/shared/NavigationManager/NavigationBar/NavBarNoAuth";
import FooterFirst from "./components/shared/NavigationManager/Footer/FooterFirst";
import RequestService from "./api/RequestService";
import { checkValidPWChars, RegFormatPhoneNumber } from "./utils/General";
import { currentYear } from "./utils/ApplicationConstants";
import Stepper from "react-stepper-horizontal";


import NavSignUp from "./components/shared/NavigationManager/NavigationBar/NavSignUp";
class User1 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			regStepZeroPointFiveCompleted: false,
			regStepOneCompleted: false,
			regStepTwoCompleted: false,
			regStepThreeCompleted: false,
			SITE_KEY: "6LcElCMhAAAAAD8TkK1BgugdzSngYxr7dlpIfu7J",
			steps: [
				{ title: "Create Account" },
				{ title: "Investment Profile" },
				{ title: "Terms & Conditions" },
			],
			currentStep: 0,
			Email:
				props.location.state && props.location.state.Email
					? props.location.state.Email
					: "", // May get from login/sessionAction, after redoing Accreditation.
			Password: "",
			IsValid: false,

			UserID:
				props.location.state && props.location.state.UserID
					? props.location.state.UserID
					: 0,
			PWHash:
				props.location.state && props.location.state.PWHash
					? props.location.state.PWHash
					: "",
			NeedAcc:
				props.location.state && props.location.state.NeedAcc
					? props.location.state.NeedAcc
					: false,
			NeedTOS:
				props.location.state && props.location.state.NeedTOS
					? props.location.state.NeedTOS
					: false,

			EntityID:
				props.location.state && props.location.state.EntityID
					? props.location.state.EntityID
					: 0,
			EntityAccreditationProfileID:
				props.location.state &&
					props.location.state.EntityAccreditationProfileID
					? props.location.state.EntityAccreditationProfileID
					: 0,
			RegistrationStep:
				props.location.state && props.location.state.RegistrationStep
					? props.location.state.RegistrationStep
					: 0,
			EmailVerified: 0,
			// userType: (props.location.state && props.location.state.UserType ? props.location.state.UserType : 0),
			userType: 1,
			firmType: "",
			utm_source: "",
			utm_medium: "",
			utm_campaign: "",
			utm_id: "",
			utm_term: "",
			utm_content: "",
		};

		this.submitStep05 = this.submitStep05.bind(this);
		this.submitStep1 = this.submitStep1.bind(this);
		this.submitStep2 = this.submitStep2.bind(this);
		this.submitStep3 = this.submitStep3.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.clearFieldError = this.clearFieldError.bind(this);
	}

	componentDidMount() {
		let invitecode = localStorage.getItem("invitecode");
		console.log("in users invitecode", invitecode);
		localStorage.clear();
		localStorage.setItem("invitecode",invitecode);
		sessionService.deleteSession();
		sessionService.deleteUser();
		this.searchParams();
		this.addFormatListener();
		this.focusFirstField();
		const loadScriptByURL = (id, url, callback) => {
			const isScriptExist = document.getElementById(id);

			if (!isScriptExist) {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (isScriptExist && callback) callback();
		}

		// load the script by passing the URL
		loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.state.SITE_KEY}`, function () {
			console.log("Script loaded!");
		});
		if (this.props.location.search === "?hubspotsignup") {
			this.getHubspotCookieData();
		}

		if (this.props.isMarketplace) {
			this.setSignupType(1);
		}


	}
	searchParams() {
		const searchParams = new URLSearchParams(this.props.location.search);
		const utm_source = searchParams?.get("utm_source");
		const utm_medium = searchParams?.get("utm_medium");
		const utm_campaign = searchParams?.get("utm_campaign");
		const utm_id = searchParams?.get("utm_id");
		const utm_term = searchParams?.get("utm_term");
		const utm_content = searchParams?.get("utm_content");

		this.setState({
			utm_source: utm_source,
			utm_medium: utm_medium,
			utm_campaign: utm_campaign,
			utm_id: utm_id,
			utm_term: utm_term,
			utm_content: utm_content,
		});


	}

	onPasswordChange({ score, password, isValid }) {
		if (isValid && checkValidPWChars(password)) {
			document.getElementById("error_password").innerHTML = "";
		} else {
			document.getElementById("error_password").innerHTML =
				"Please increase your password strength by using letters, numbers and special symbols (excluding & [ ] < > \" ').";
		}

		this.setState({
			Password: password,
			IsValid: isValid,
		});
	}

	async submitStep1(e) {
		e.preventDefault();
		let ret = true;
		if (
			this.refs.verificationcode.value.trim() === ""
		) {
			this.refs.verificationcode.classList.add("signup-form-control-warning");
			ret = false;
		}
		if(ret) {
			if (!this.state.regStepOneCompleted) {
				let formBody = [];

				try {
					window.grecaptcha.ready(async () => {
						const val = await window.grecaptcha.execute(this.state.SITE_KEY, { action: 'submit' });
						console.log("recaptcha verified ", val);
						if (val) {
							formBody.push("recapToken=" + encodeURIComponent(val));
							formBody.push("verificationcode=" + encodeURIComponent(this.refs.verificationcode.value));
							formBody.push("UserID=" + encodeURIComponent(this.state.UserID));
							formBody = formBody.join("&");
							const { data } = await RequestService.post({
								url: "/api/registerPhoneVerification",
								data: formBody,
								credential: {
									// credentials: 'include' // Don't forget to specify this if you need cookies passed to controller -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
									credentials: "same-origin", // Don't forget to specify this if you need cookies passed to controller, this is more secure. -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
								},
							});

							if (data.code === 200) {
								// localStorage.setItem("Email", this.refs.email.value);
								this.setState({
									RegistrationStep: 1,
									regStepOneCompleted: true,
									currentStep: 1
								});
								this.props.history.push("/register/step2");
								this.refs.submit1.innerHTML = "Continue";
								this.refs.submit1.removeAttribute("disabled");
								window.scrollTo(0, 0);
							} else if (data.code === 407) {
								swal("Recaptcha Verification failed", "Please try again. If you continue to have issues, please call customer support at 425-301-4053", "error");
								this.refs.submit1.removeAttribute("disabled");
								this.refs.submit1.innerHTML = "Continue";
							} else {
								swal("Please contact Customer Support.", "Your account requires further assistance. Please call customer support at 425-301-4053", "error");
								this.refs.submit1.setAttribute("disabled", "disabled");
								this.refs.submit1.innerHTML =
									"Your account requires further assistance. Please call customer support at 425-301-4053";
							}
						}
					});

				} catch (err) {
					console.log(err);
				}
			} else {
				this.setState({
					RegistrationStep: 1,
				});
				this.props.history.push("/register/step2");
				this.refs.submit1.innerHTML = "Continue";
				this.refs.submit1.removeAttribute("disabled");
			}
		}
	}

	async submitStep05(e) {
		e.preventDefault();
		console.log("in step 0.5")
		let ret = true;

		if (
			this.refs.fname.value.trim() === "" ||
			this.refs.lname.value.trim() === "" ||
			this.refs.email.value.trim() === "" ||
			this.refs.phoneNumber.value.trim() === ""
		) {
			if (this.refs.fname.value.trim() === "") {
				this.refs.fname.classList.add("signup-form-control-warning");
			}

			if (this.refs.lname.value.trim() === "") {
				this.refs.lname.classList.add("signup-form-control-warning");
			}

			if (this.refs.phoneNumber.value.trim() === "") {
				this.refs.phoneNumber.classList.add(
					"signup-form-control-warning"
				);
			}

			if (this.refs.email.value.trim() === "") {
				this.refs.email.classList.add("signup-form-control-warning");
			}
			console.log("this.refs.email.value", this.refs.email.value, "this.refs.fname.value", this.refs.fname.value, "this.refs.lname.value", this.refs.lname.value, "this.refs.phoneNumber.value", this.refs.phoneNumber.value);
			ret = false;
		} else if (this.refs.email.value.trim() !== "") {
			let emailRegex =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

			if (emailRegex.test(this.refs.email.value) === false) {
				this.refs.email.classList.add("signup-form-control-warning");
				ret = false;
			} else if (emailRegex.test(this.refs.email.value) === true) {
				this.refs.email.classList.remove("signup-form-control-warning");
			}
		}

		if (this.refs.phoneNumber.value.trim() !== "") {
			let phoneNumber = RegFormatPhoneNumber(this.refs.phoneNumber?.value);
			if (phoneNumber.length < 10) {
				this.refs.phoneNumber.classList.add(
					"signup-form-control-warning"
				);
				console.log("phoneNumber.length", phoneNumber.length);
				ret = false;
			}

		}

		if (
			!this.state.IsValid ||
			!checkValidPWChars(document.getElementById("password_input").value)
		) {
			document.getElementById("error_password").innerHTML =
				"Please increase your password strength by using letters, numbers and special symbols (excluding & [ ] < > \" ').";
			console.log("error_password");
			ret = false;
		}

		if (!this.refs.termsCB1.checked) {
			this.refs.tc1_error.innerHTML =
				"To continue, please check the box to consent to our legal agreements shown here.";
				console.log("this.refs.termsCB1.checked", this.refs.termsCB1.checked);
			ret = false;
		}
		console.log("ret", ret);
		if (ret) {
			this.refs.submit1.setAttribute("disabled", "disabled");
			this.refs.submit1.innerHTML = "Please Wait ... ";

			this.setState({
				Email: this.refs.email.value,
			});
			let PhoneNumber = RegFormatPhoneNumber(this.refs.phoneNumber?.value);
			let formBody = [];
			formBody.push("ft_channel=" + encodeURIComponent(this.refs.ft_channel?.value));
			formBody.push("lt_channel=" + encodeURIComponent(this.refs.lt_channel?.value));
			formBody.push("ft_source=" + encodeURIComponent(this.refs.ft_source?.value));
			formBody.push("lt_source=" + encodeURIComponent(this.refs.lt_source?.value));
			formBody.push("ft_keyword=" + encodeURIComponent(this.refs.ft_keyword?.value));
			formBody.push("lt_keyword=" + encodeURIComponent(this.refs.lt_keyword?.value));
			formBody.push("ft_medium=" + encodeURIComponent(this.refs.ft_medium?.value));
			formBody.push("lt_medium=" + encodeURIComponent(this.refs.lt_medium?.value));
			formBody.push("ft_audience=" + encodeURIComponent(this.refs.ft_audience?.value));
			formBody.push("lt_audience=" + encodeURIComponent(this.refs.lt_audience?.value));
			formBody.push("ft_device=" + encodeURIComponent(this.refs.ft_device?.value));
			formBody.push("lt_device=" + encodeURIComponent(this.refs.lt_device?.value));
			formBody.push("ft_campaign=" + encodeURIComponent(this.refs.ft_campaign?.value));
			formBody.push("lt_campaign=" + encodeURIComponent(this.refs.lt_campaign?.value));
			formBody.push("ft_creative=" + encodeURIComponent(this.refs.ft_creative?.value));
			formBody.push("lt_creative=" + encodeURIComponent(this.refs.lt_creative?.value));
			formBody.push("ga_id=" + encodeURIComponent(this.refs.ga_id?.value));
			formBody.push("gclid=" + encodeURIComponent(this.refs.gclid?.value));


			formBody.push("fname=" + encodeURIComponent(this.refs.fname.value));
			formBody.push("lname=" + encodeURIComponent(this.refs.lname.value));
			formBody.push(
				"email=" +
				encodeURIComponent(this.refs.email.value.toLowerCase())
			);
			formBody.push("pass=" + encodeURIComponent(this.state.Password));
			formBody.push(
				"phoneNumber=" + encodeURIComponent(PhoneNumber)
			);
			formBody.push(
				"homephone=" + encodeURIComponent(this.refs.homephone.value)
			); // Honey Pot Field.
			formBody.push(
				"ContactPrefSMS=" + (this.refs.ContactPrefSMS.checked ? 1 : 0)
			);
			formBody.push(
				"ContactPrefEmail=" + (this.refs.ContactPrefEmail.checked ? 1 : 0)
			);
			formBody.push(
				"Verification1=" +
				encodeURIComponent(
					`I agree to the Terms of Service, Privacy Policy, Investor Agreement, and the Electronic Consent & Delivery Agreement.`
				)
			);
			formBody.push(
				"userType=" + encodeURIComponent(this.state.userType)
			);

			console.log("this.state.regStepZeroPointFiveCompleted", this.state.regStepZeroPointFiveCompleted);
			if (!this.state.regStepZeroPointFiveCompleted) {
				try {
					window.grecaptcha.ready(async () => {
						const val = await window.grecaptcha.execute(this.state.SITE_KEY, { action: 'submit' });
						console.log("recaptcha verified ", val);
						if (val) {
							formBody.push("recapToken=" + encodeURIComponent(val));
							formBody = formBody.join("&");
							const { data } = await RequestService.post({
								url: "/api/register1",
								data: formBody,
								credential: {
									// credentials: 'include' // Don't forget to specify this if you need cookies passed to controller -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
									credentials: "same-origin", // Don't forget to specify this if you need cookies passed to controller, this is more secure. -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
								},
							});
							console.log("data.code", data.code);
							if (data.code === 200) {
								localStorage.setItem("Email", this.refs.email.value);
								this.setState({
									RegistrationStep: 0.5,
									regStepZeroPointFiveCompleted: true,
									currentStep: 0.5,
									UserID: data.UserID,
									PWHash: data.PWHash,
									EntityID: data.EntityID,
								});
								this.props.history.push("/register/step1");
								this.refs.submit1.innerHTML = "Continue";
								this.refs.submit1.removeAttribute("disabled");
								window.scrollTo(0, 0);
							} else if (data.code === 406) {
								swal("Email Already Registered", "Please proceed to login. If you forgot your password, you can reset it from the login page.", "warning");
								this.refs.submit1.removeAttribute("disabled");
								this.refs.submit1.innerHTML = "Continue";
							} else {
								swal("Please contact Customer Support.", "Your account requires further assistance. Please call customer support at 425-301-4053", "error");
								this.refs.submit1.setAttribute("disabled", "disabled");
								this.refs.submit1.innerHTML =
									"Your account requires further assistance. Please call customer support at 425-301-4053";
							}
						}
					});

				} catch (err) {
					console.log(err);
				}
			} else {
				this.setState({
					RegistrationStep: 0.5,
				});
				this.props.history.push("/register/step05");
				this.refs.submit1.innerHTML = "Continue";
				this.refs.submit1.removeAttribute("disabled");
			}
		} else {
			console.log("aborting")
		}
	}

	async submitStep2(e) {
		e.preventDefault();
		let formComplete = true;
		let formBody = [];
		formBody.push("userType=" + this.state.userType);

		if (this.state.userType === 1) {
			let maritalStatus1 = this.refs.maritalStatus1.checked;
			let maritalStatus2 = this.refs.maritalStatus2.checked;
			let incomeThisYear = this.refs.incomeThisYear.value;
			let incomeLastYear = this.refs.incomeLastYear.value;
			let incomeTwoYearsBack = this.refs.incomeTwoYearsBack.value;
			let netWorth = this.refs.netWorth.value;
			let currInvest1 = this.refs.currInvest1?.checked | 0;
			let currInvest2 = this.refs.currInvest2?.checked | 0;
			let investTypes = this.refs.investTypes?.value | "0";
			let profCert1 = this.refs.profCert1.checked;
			let profCert2 = this.refs.profCert2.checked;

			if (maritalStatus1 || maritalStatus2) {
				if (maritalStatus1) {
					formBody.push(`maritalStatus=single`);
				}
				if (maritalStatus2) {
					formBody.push(`maritalStatus=married`);
				}
			} else {
				this.refs.error_maritalStatus.innerHTML = "Please select one.";
				formComplete = false;
			}

			if (incomeThisYear !== "0") {
				formBody.push(
					"incomeThisYear=" + encodeURIComponent(incomeThisYear)
				);
			} else {
				this.refs.incomeThisYear.classList.add(
					"verify-form-control-warning"
				);
				formComplete = false;
			}

			if (incomeLastYear !== "0") {
				formBody.push(
					"incomeLastYear=" + encodeURIComponent(incomeLastYear)
				);
			} else {
				this.refs.incomeLastYear.classList.add(
					"verify-form-control-warning"
				);
				formComplete = false;
			}

			if (incomeTwoYearsBack !== "0") {
				formBody.push(
					"incomeTwoYearsBack=" +
					encodeURIComponent(incomeTwoYearsBack)
				);
			} else {
				this.refs.incomeTwoYearsBack.classList.add(
					"verify-form-control-warning"
				);
				formComplete = false;
			}

			if (netWorth !== "0") {
				formBody.push("netWorth=" + encodeURIComponent(netWorth));
			} else {
				this.refs.netWorth.classList.add("verify-form-control-warning");
				formComplete = false;
			}

			if (currInvest1 || currInvest2) {
				if (currInvest1) {
					if (investTypes !== "0") {
						formBody.push("currInvest=" + investTypes);
					} else {
						this.refs.investTypes.classList.add(
							"verify-form-control-warning"
						);
						formComplete = false;
					}
				}
				if (currInvest2) {
					formBody.push(`currInvest=No`);
				}
			} else {
				// this.refs.error_currInvest.innerHTML = 'Please select one.';
				// formComplete = false;
			}

			if (profCert1 || profCert2) {
				if (profCert1) {
					formBody.push(`profCert=1`);
				} else {
					formBody.push(`profCert=0`);
				}
			} else {
				this.refs.error_profCert.innerHTML = "Please select one.";
				formComplete = false;
			}
		} else if (this.state.userType === 2) {
			let firmType = this.refs.firmType.value;
			let aum = this.refs.assetsUnderManagement.value;

			if (firmType !== "0") {
				formBody.push("firmType=" + encodeURIComponent(firmType));
			} else {
				this.refs.firmType.classList.add("verify-form-control-warning");
				formComplete = false;
			}

			if (firmType !== "CPA" && aum !== "0") {
				formBody.push("aum=" + encodeURIComponent(aum));
			} else {
				this.refs.assetsUnderManagement.classList.add(
					"verify-form-control-warning"
				);
				formComplete = false;
			}
		}

		// Form Validation Passed:
		if (formComplete) {
			this.refs.submit2.setAttribute("disabled", "disabled");
			this.refs.submit2.innerHTML = "Please Wait ... ";

			if (!this.state.regStepTwoCompleted) {
				try {
					window.grecaptcha.ready(async () => {
						const val = await window.grecaptcha.execute(this.state.SITE_KEY, { action: 'submit' });
						console.log("recaptcha verified step 2 ", val);
						if (val) {
							formBody.push("recapToken=" + encodeURIComponent(val));
							formBody = formBody.join("&");
							const { data } = await RequestService.post({
								url: "/api/register2",
								data: formBody,
								headers: {
									Authorization: JSON.stringify({
										id: this.state.UserID,
										marketing: this.state.PWHash,
										isRegistering: true,
									}),
								},
								credential: {
									// credentials: 'include', // Don't forget to specify this if you need cookies passed to controller -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
									credentials: "same-origin", // Don't forget to specify this if you need cookies passed to controller, this is more secure. -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
								},
							});

							if (data.code === 200) {
								this.setState({
									RegistrationStep: 2,
									regStepTwoCompleted: true,
									currentStep: 2,
									EntityID: data.EntityID,
									EntityAccreditationProfileID:
										data.EntityAccreditationProfileID,
									EmailVerified: data.EmailVerified,
								});
								this.props.history.push("/register/step3");
								this.refs.submit2.innerHTML = "Continue";
								this.refs.submit2.removeAttribute("disabled");
								window.scrollTo(0, 0);
							} else {
								swal("Please contact Customer Support.", "Your account requires further assistance. Please call customer support at 425-301-4053", "error");
								this.refs.submit2.setAttribute("disabled", "disabled");
								this.refs.submit1.innerHTML =
									"Your account requires further assistance. Please call customer support at 425-301-4053";
							}
						}
					})
				} catch (err) {
					console.log(err);
				}
			} else {
				this.setState({
					RegistrationStep: 2,
				});
				this.props.history.push("/register/step3");
				this.refs.submit2.innerHTML = "Continue";
				this.refs.submit2.removeAttribute("disabled");
			}
		} else {
			return false;
		}
	}

	async submitStep3(e) {
		e.preventDefault();

		let terms2 = this.refs.termsCB2.checked;
		let terms3 = this.refs.termsCB3.checked;
		let terms4 = this.refs.termsCB4.checked;

		// alert(`${terms1} - ${terms2} - ${terms3} - ${terms4}`);

		if (terms2 && terms3 && terms4) {
			this.refs.submit3.setAttribute("disabled", "disabled");
			this.refs.submit3.innerHTML = "Please Wait ... ";

			let formBody = [];
			formBody.push(
				"Verification1=" +
				encodeURIComponent(
					`I agree to the Terms of Service, Privacy Policy, Investor Agreement, and the Electronic Consent & Delivery Agreement.`
				)
			);
			formBody.push(
				"Verification2=" +
				encodeURIComponent(
					`I understand EnergyFunders does not offer investment advice. I have the sophistication to evaluate investments on my own.`
				)
			);
			formBody.push(
				"Verification3=" +
				encodeURIComponent(
					`I understand securities on EnergyFunders are not easily re-sold. EnergyFunders is currently pursuing an agreement with tZERO to list our securities on a secondary market, but there can be no guarantee of liquidity if/when the agreement is finalized. I can wait years for a return.`
				)
			);
			formBody.push(
				"Verification4=" +
				encodeURIComponent(
					`I understand: (a) all of the risks of investing, including the risk that I could lose all of the money I invest, (b) that I am advised to review all of the information in an offering's Disclosure Document prior to investing in such offering, and (c) that the financial outcomes of investments offered on the EnergyFunders platform are not guaranteed.`
				)
			);
			formBody.push("EntityID=" + this.state.EntityID);
			formBody.push(
				"EntityAccreditationProfileID=" +
				this.state.EntityAccreditationProfileID
			);
			formBody.push(
				"email=" +
				this.state.Email
			);
			formBody.push(
				"utm_source=" +
				this.state.utm_source
			);
			formBody.push(
				"utm_medium=" +
				this.state.utm_medium
			);
			formBody.push(
				"utm_campaign=" +
				this.state.utm_campaign
			);
			formBody.push(
				"utm_id=" +
				this.state.utm_id
			);
			formBody.push(
				"utm_term=" +
				this.state.utm_term
			);
			formBody.push(
				"utm_content=" +
				this.state.utm_content
			);
			formBody.push(
				"ContactPrefSMS=" + (this.refs.ContactPrefSMS.checked ? 'Yes' : 'No')
			);

			try {
				window.grecaptcha.ready(async () => {
					const val = await window.grecaptcha.execute(this.state.SITE_KEY, { action: 'submit' });
					console.log("recaptcha verified step3 ", val);
					if (val) {
						formBody.push("recapToken=" + encodeURIComponent(val));
						formBody = formBody.join("&");
						const { data } = await RequestService.post({
							url: "/api/register3",
							data: formBody,
							headers: {
								Authorization: JSON.stringify({
									id: this.state.UserID,
									marketing: this.state.PWHash,
									isRegistering: true,
								}),
							},
							credential: {
								// credentials: 'include', // Don't forget to specify this if you need cookies passed to controller -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
								credentials: "same-origin", // Don't forget to specify this if you need cookies passed to controller, this is more secure. -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
							},
						});

						if (data.code === 200) {
							if (data.EmailVerified > 0) {
								swal({
									title: "Registration Successful",
									html: `<span style="font-size: 1.4em">One moment while we log you in.</span>`,
									timer: 2000,
									onOpen: () => {
										swal.showLoading();
									},
								}).then((result) => {
									if (result.dismiss === "timer") {
										this.props.history.push({
											pathname: "/login",
											state: {
												AutoUID: this.state.UserID,
												AutoPWHash: this.state.PWHash,
												AutoEmail: this.state.Email,
											},
										});
									}
								});
							} else {
								swal({
									title: "Registration Successful",
									html: `<span style="font-size: 1.4em">Please verify Email to Login.</span>`,
									timer: 3000,
									onOpen: () => {
										swal.showLoading();
									},
								}).then((result) => {
									if (result.dismiss === "timer") {
										this.props.history.push({
											pathname: "/resendmail",
											// pathname: '/regverified',
											state: {
												Email: this.state.Email,
												UserID: this.state.UserID,
												PWHash: this.state.PWHash,
											},
										});
									}
								});
							}
						} else {
							swal("Please contact Customer Support.", "Your account requires further assistance. Please call customer support at 425-301-4053", "error");
							return false;
						}
					}
				})
			} catch (err) {
				console.log(err);
			}
		} else {
			this.refs.tc_error.innerHTML =
				"You must agree to all the terms and conditions.";
			return false;
		}
	}

	addFormatListener() {

		if (this.state.RegistrationStep === 0) {
			this.refs.email.addEventListener("blur", function (e) {
				let emailRegex =
					/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

				if (emailRegex.test(this.value) === false) {
					this.classList.add("signup-form-control-warning");
				} else if (emailRegex.test(this.value) === true) {
					this.classList.remove("signup-form-control-warning");
				}
			});

			this.refs.phoneNumber.addEventListener("blur", function (e) {

				let x = e.target.value
					.replace(/\D/g, "")
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
				e.target.value = !x[2]
					? x[1]
					: x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
			});

			this.refs.phoneNumber.addEventListener("input", function (e) {

				let x = e.target.value
					.replace(/\D/g, "")
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
				e.target.value = !x[2]
					? x[1]
					: x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
				document.getElementById("error_phoneNumber").innerHTML = "";
				this.classList.remove("signup-form-control-warning");
			});
		}
	}

	focusFirstField() {
		if (this.state.RegistrationStep === 0) {
			document.getElementById("fname").focus();
		}
	}

	clearFieldError(field, event) {
		// this.setState({
		// 	[event?.target?.fname]: event?.target?.value
		//   });
		if (field === "fname") {
			this.refs.fname.classList.remove("signup-form-control-warning");
		}

		if (field === "lname") {
			this.refs.lname.classList.remove("signup-form-control-warning");
		}

		if (field === "email") {
			this.refs.email.classList.remove("signup-form-control-warning");
		}

		if (field === "phoneNumber") {
			this.refs.phoneNumber.classList.remove(
				"signup-form-control-warning"
			);
		}

		if (field === "termsCB1") {
			this.refs.tc1_error.innerHTML = "";
		}

		if (field === "verificationcode") {
			this.refs.verificationcode.innerHTML = "";
		}

		if (field === "maritalStatus") {
			this.refs.error_maritalStatus.innerHTML = "";
		}

		if (field === "incomeThisYear") {
			this.refs.incomeThisYear.classList.remove(
				"verify-form-control-warning"
			);
		}

		if (field === "incomeLastYear") {
			this.refs.incomeLastYear.classList.remove(
				"verify-form-control-warning"
			);
		}

		if (field === "incomeTwoYearsBack") {
			this.refs.incomeTwoYearsBack.classList.remove(
				"verify-form-control-warning"
			);
		}

		if (field === "netWorth") {
			this.refs.netWorth.classList.remove("verify-form-control-warning");
		}

		if (field === "currInvest") {
			this.refs.error_currInvest.innerHTML = "";
			if (this.refs.currInvest1.checked) {
				this.refs.investTypesDiv.style = "display: block";
			} else if (this.refs.currInvest2.checked) {
				this.refs.investTypesDiv.style = "display: none";
			}
		}

		if (field === "investTypes") {
			this.refs.investTypes.classList.remove(
				"verify-form-control-warning"
			);
		}

		if (field === "profCert") {
			this.refs.error_profCert.innerHTML = "";
		}

		if (field === "firmType") {
			this.refs.firmType.classList.remove("verify-form-control-warning");

			this.setState({
				firmType: this.refs.firmType.value,
			});
		}
	}

	async getHubspotCookieData() {
		try {
			const { data } = await RequestService.get(
				"/api/getHubspotCookieData"
			);
			if (data.code === 200) {
				this.refs.fname.value = data.data.FirstName;
				this.refs.lname.value = data.data.LastName;
				this.refs.email.value = data.data.Email;
				document.getElementById("password_input").value =
					data.data.Password;

				this.setState({
					IsValid: true,
					Password: data.data.Password,
				});

				this.refs.submit1.click();
			} else {
				// Nothing ...
			}
		} catch (err) {
			console.log(err);
		}
	}

	setSignupType(val) {
		if (val === 2) {
			this.setState({
				userType: 2,
			});
		} else {
			this.setState({
				userType: 1,
			});
		}

		window.scrollTo({
			top: 100, // could be negative value
			left: 0,
			behavior: "smooth",
		});
	}

	renderRegistrationStep0() {
		return (
			<div
				hidden={
					!this.state.RegistrationStep === 0 ||
					this.state.RegistrationStep === 0.5 ||
					this.state.RegistrationStep === 1 ||
					this.state.RegistrationStep === 2
				}
			>

				<h1>Join Today</h1>
				<p>Set up your account or <a href="/login" rel="noopener noreferrer" className="font-bold">Log In</a></p>
				<div style={{ overflow: "hidden" }}>

					<div className="step0" hidden={this.state.userType === 0}>
						<div
							className="panel panel-primary"
							style={{
								border: 0,
								borderRadius: 0,
								boxShadow: "none",
							}}
						>
							<div className="panel-body">
								<form
									name="form1"
									onsubmit="return checkForm(this);"
								>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div hidden={true} className="form-group">
												<input type="text" name="ft_channel" ref="ft_channel" id="ft_channel" />
												<input type="text" name="lt_channel" ref="lt_channel" id="lt_channel" />
												<input type="text" name="ft_source" ref="ft_source" id="ft_source" />
												<input type="text" name="lt_source" ref="lt_source" id="lt_source" />
												<input type="text" name="ft_keyword" ref="ft_keyword" id="ft_keyword" />
												<input type="text" name="lt_keyword" ref="lt_keyword" id="lt_keyword" />
												<input type="text" name="ft_medium" ref="ft_medium" id="ft_medium" />
												<input type="text" name="lt_medium" ref="lt_medium" id="lt_medium" />
												<input type="text" name="ft_audience" ref="ft_audience" id="ft_audience" />
												<input type="text" name="lt_audience" ref="lt_audience" id="lt_audience" />
												<input type="text" name="ft_device" ref="ft_device" id="ft_device" />
												<input type="text" name="lt_device" ref="lt_device" id="lt_device" />
												<input type="text" name="ft_campaign" ref="ft_campaign" id="ft_campaign" />
												<input type="text" name="lt_campaign" ref="lt_campaign" id="lt_campaign" />
												<input type="text" name="ft_creative" ref="ft_creative" id="ft_creative" />
												<input type="text" name="lt_creative" ref="lt_creative" id="lt_creative" />
												<input type="text" name="ga_id" ref="ga_id" id="ga_id" />
												<input type="text" name="gclid" ref="gclid" id="gclid" />

											</div>
											<div className="form-group">
												{/* <label htmlFor="fname">First Name</label> */}

												<input
													id="fname"
													ref="fname"
													className="signup-form-control form-control"
													type="text"
													data-validation="required"
													placeholder="First Name"
													onChange={() =>
														this.clearFieldError(
															"fname"
														)
													}
													readOnly={
														this.state
															.regStepZeroPointFiveCompleted
													}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
												{/* <label htmlFor="lname">Last Name</label> */}
												<input
													id="lname"
													ref="lname"
													className="signup-form-control form-control"
													type="text"
													data-validation="required"
													placeholder="Last Name"
													onChange={() =>
														this.clearFieldError(
															"lname"
														)
													}
													readOnly={
														this.state
															.regStepZeroPointFiveCompleted
													}
												/>
												<span
													id="error_lname"
													className="text-danger"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
												{/* <label htmlFor="phoneNumber">Mobile Number</label> */}
												<input
													id="phoneNumber"
													ref="phoneNumber"
													name="phoneNumber"
													className="signup-form-control form-control"
													type="text"
													data-validation="required"
													placeholder="phone number"
													onChange={() =>
														this.clearFieldError(
															"phoneNumber"
														)
													}
													readOnly={
														this.state
															.regStepZeroPointFiveCompleted
													}

												/>
											</div>
										</div>
										<div
											id="error_phoneNumber"
											ref="error_phoneNumber"
											className="text-danger"
										>
											{" "}
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
												{/* <label htmlFor="email">Email</label> */}
												<input
													id="email"
													ref="email"
													className="signup-form-control form-control"
													type="email"
													data-validation="required email"
													placeholder="Email"
													onChange={() =>
														this.clearFieldError(
															"email"
														)
													}
													readOnly={
														this.state
															.regStepZeroPointFiveCompleted
													}
												/>
												<span
													id="error_email"
													className="text-danger"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-7">
											<div className="form-group">
												{/* <label htmlFor="password">Password</label> */}
												<ReactPasswordStrength
													className="signup-form-control form-group"
													minLength={8}
													minScore={1}
													scoreWords={[
														"weak",
														"okay",
														"good",
														"strong",
														"stronger",
													]}
													tooShortWord="short"
													changeCallback={
														this.onPasswordChange
													}
													inputProps={{
														id: "password_input",
														name: "password_input",
														autoComplete: "off",
														className:
															"signup-form-control form-control",
														placeholder:
															"**********",
													}}
													style={{ borderRadius: 0 }}
													readOnly={
														this.state
															.regStepZeroPointFiveCompleted
													}
												/>
												<span
													id="error_password"
													style={{ color: "#a94442" }}
												>
													{" "}
												</span>
											</div>
										</div>
										<div className="col-xs-5">
											<p className="ps-description">
												Password should be at least 8
												characters,
												<br /> contain an uppercase
												character, a<br /> lowercase
												character and a symbol.
											</p>
										</div>
									</div>

									<div className="row">
										<div
											className="col-xs-12"
											style={{ width: "100%" }}
										>
											<div className="form-group">
												<span
													id="tc1_error"
													ref="tc1_error"
													style={{ color: "#ff0000" }}
												>
													{" "}
												</span>
												<div
													className="check_tc"
													style={{ marginTop: 10 }}
												>
													<label className="custom-container">
														I have reviewed and
														agree to the{" "}
														<a
															href="https://www.energyfunders.com/terms-of-service/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Terms of Service
														</a>
														,{" "}
														<a
															href="https://www.energyfunders.com/privacy-policy/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Privacy Policy
														</a>
														,{" "}
														<a
															href="https://www.energyfunders.com/investor-agreement/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Investor Agreement
														</a>
														, and the{" "}
														<a
															href="https://www.energyfunders.com/electronic-consent/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Electronic Consent
															&amp; Delivery
															Agreement
														</a>
														.
														<input
															type="checkbox"
															required
															ref="termsCB1"
															name="termsCB1"
															id="termsCB1"
															onChange={() =>
																this.clearFieldError(
																	"termsCB1"
																)
															}
															disabled={
																this.state
																	.regStepZeroPointFiveCompleted
															}
														/>
														<span className="checkmark" />
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div
											className="col-xs-12"
											style={{ width: "100%" }}
										>
											<div className="form-group">
												<div
													className="check_tc"
													style={{ marginTop: -10 }}
												>
													<label className="custom-container">
														Send me occasional email
														updates
														<input
															type="checkbox"
															ref="ContactPrefEmail"
															name="ContactPrefEmail"
															id="ContactPrefEmail"
															disabled={
																this.state
																	.regStepZeroPointFiveCompleted
															}
															defaultChecked={
																true
															}
														/>
														<span className="checkmark" />
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div
											className="col-xs-12"
											style={{ width: "100%" }}
										>
											<div className="form-group">
												<div
													className="check_tc"
													style={{ marginTop: -10 }}
												>
													<label className="custom-container">
														Send me occasional text
														messages. (This
														agreement isn’t a
														condition of any
														purchase. By checking
														this box, you agree to
														receive promotional
														messages via an
														autodialer. Msg &amp;
														Data rates may apply.
														Reply STOP to
														unsubscribe. See <a
															href="https://www.energyfunders.com/terms-of-service/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Terms of Service
														</a>
														{" "}and{" "}
														<a
															href="https://www.energyfunders.com/privacy-policy/"
															target="_blank"
															rel="noopener noreferrer"
														>
															Privacy Policy
														</a>.)
														<input
															type="checkbox"
															ref="ContactPrefSMS"
															name="ContactPrefSMS"
															id="ContactPrefSMS"
															disabled={
																this.state
																	.regStepZeroPointFiveCompleted
															}
															defaultChecked={
																true
															}
														/>
														<span className="checkmark" />
													</label>
												</div>
											</div>
										</div>
									</div>
									{/* <div className=' mb-5'><p className="text-black" style={{fontStyle:'italic', fontWeight:'bold'}}><b style={{textAlign:'center'}}>IMPORTANT NOTICE:</b> Due to payment processing lag times, if you would like your investment to count toward the 2022 tax year, please WIRE YOUR INVESTMENT to us. If you invest via our online portal at this time, the funds may not reach us by year end. Please call us at <a href="tel:7138633297" className='text-green text-decoration-underline'>713-863-3297</a> or email at <a href="mailto: info@energyfunders.com" className='text-green text-decoration-underline'>info@energyfunders.com</a> for details on how to wire your investment.</p></div> */}
									<div className="row">
										<div className="col-xs-4">
											<div className="form-group">
												<input
													id="homephoneHP00"
													ref="homephone"
													type="text"
													className="signup-form-control form-control"
													placeholder="Home Phone"
													style={{ display: "none" }}
												/>
												<button
													id="submit1"
													ref="submit1"
													onClick={this.submitStep05}
													value="submit"
													className="btn semi-bold"
													style={{
														height: 50,
														width: 250,
														backgroundColor:
															"#5bb462",
														color: "#fff",
													}}
												>
													Continue
												</button>
											</div>
										</div>
									</div>
									<div className="row margin-top-10px">
										<div className="col-xs-12">
											<div className="">
												<div><p className="">Already have an account? <a href="/login" rel="noopener noreferrer" style={{fontWeight:600}} >Click here to login.</a></p>
												</div>
											</div>
										</div>
									</div>
									{/* <div className="row margin-top-10px">
										<div className="col-xs-12">
											<div className="form-group">
												<div>
													{(() => {
														if (this.props.isMarketplace) {
															return(<a href="/marketplace/login" rel="noopener noreferrer"><p className="">Already have an account? Click here to login.</p></a>);
														}
														else {
															return(<a href="/login" rel="noopener noreferrer"><p className="">Already have an account? Click here to login.</p></a>);
														}
													})()}
												</div>
											</div>
										</div>
									</div> */}
								</form>
							</div>
						</div>
					</div>
					<div
						className="col-md-4 nopadding imghide"
						style={{ paddingLeft: 0 }}
					></div>
				</div>

			</div>
		);
	}

	renderRegistrationStep05() {
		return (
			<div
				hidden={
					!this.state.RegistrationStep === 0.5 ||
					this.state.RegistrationStep === 0 ||
					this.state.RegistrationStep === 1 ||
					this.state.RegistrationStep === 2
				}
			>

				<h1>Verify your Phone Number</h1>
				<p>Enter the verification code sent to your phone number. If you did not receive the code or you entered a land line phone number, your account requires further assistance. Please call customer support at 425-301-4053</p>
				<div style={{ overflow: "hidden" }}>

					<div className="step0" hidden={this.state.userType === 0}>
						<div
							className="panel panel-primary"
							style={{
								border: 0,
								borderRadius: 0,
								boxShadow: "none",
							}}
						>
							<div className="panel-body">
								<form
									name="form1"
									onsubmit="return checkForm(this);"
								>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div hidden={true} className="form-group">
												<input type="text" name="ft_channel" ref="ft_channel" id="ft_channel" />
												<input type="text" name="lt_channel" ref="lt_channel" id="lt_channel" />
												<input type="text" name="ft_source" ref="ft_source" id="ft_source" />
												<input type="text" name="lt_source" ref="lt_source" id="lt_source" />
												<input type="text" name="ft_keyword" ref="ft_keyword" id="ft_keyword" />
												<input type="text" name="lt_keyword" ref="lt_keyword" id="lt_keyword" />
												<input type="text" name="ft_medium" ref="ft_medium" id="ft_medium" />
												<input type="text" name="lt_medium" ref="lt_medium" id="lt_medium" />
												<input type="text" name="ft_audience" ref="ft_audience" id="ft_audience" />
												<input type="text" name="lt_audience" ref="lt_audience" id="lt_audience" />
												<input type="text" name="ft_device" ref="ft_device" id="ft_device" />
												<input type="text" name="lt_device" ref="lt_device" id="lt_device" />
												<input type="text" name="ft_campaign" ref="ft_campaign" id="ft_campaign" />
												<input type="text" name="lt_campaign" ref="lt_campaign" id="lt_campaign" />
												<input type="text" name="ft_creative" ref="ft_creative" id="ft_creative" />
												<input type="text" name="lt_creative" ref="lt_creative" id="lt_creative" />
												<input type="text" name="ga_id" ref="ga_id" id="ga_id" />
												<input type="text" name="gclid" ref="gclid" id="gclid" />

											</div>
											<div className="form-group">
												{/* <label htmlFor="fname">First Name</label> */}

												<input
													id="verificationcode"
													ref="verificationcode"
													className="signup-form-control form-control"
													type="text"
													data-validation="required"
													placeholder="Verification Code"
													onChange={() =>
														this.clearFieldError(
															"verificationcode"
														)
													}
													readOnly={
														this.state
															.regStepOneCompleted
													}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-4">
											<div className="form-group">
												<input
													id="homephoneHP00"
													ref="homephone"
													type="text"
													className="signup-form-control form-control"
													placeholder="Home Phone"
													style={{ display: "none" }}
												/>
												<button
													id="submit1"
													ref="submit1"
													onClick={this.submitStep1}
													value="submit"
													className="btn semi-bold"
													style={{
														height: 50,
														width: 250,
														backgroundColor:
															"#5bb462",
														color: "#fff",
													}}
												>
													Continue
												</button>
											</div>
											<div className="form-group" style={{height: "100vh"}}>
														<button
															onClick={() => {
																this.setState({
																	RegistrationStep: 0,
																});
																this.props.history.push(
																	"/register"
																);
																document
																	.getElementById(
																		"password_input"
																	)
																	.setAttribute(
																		"disabled",
																		"disabled"
																	);
															}}
															type="button"
															className="btn center"
															style={{
																height: 50,
																width: "auto",
																backgroundColor:
																	"#fff",
																color: "#182F3B9A",
															}}
														>
															Back
														</button>
													</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div
						className="col-md-4 nopadding imghide"
						style={{ paddingLeft: 0 }}
					></div>
				</div>

			</div>
		);
	}

	renderRegistrationStep1() {
		if (this.state.NeedAcc) {
			swal.fire({
				title: "Welcome Back!",
				html: `<span style="font-size: 1.4em">We need to update your ${currentYear()} accreditation information. Please complete the information on this page. Thank you.</span>`,
				type: "info",
				width: "50rem",
				confirmButtonColor: "#5bb462",
				confirmButtonText: "I Understand",
			});
		}

		return (
			<div
				hidden={
					!this.state.RegistrationStep === 1 ||
					this.state.RegistrationStep === 0 ||
					this.state.RegistrationStep === 0.5 ||
					this.state.RegistrationStep === 2
				}
			>
				{/* <NavBar isMarketplace={this.props.isMarketplace} /> */}
				<h1>Create Your Investment Profile</h1>
				<p>
					Securities regulations require that we learn more about your
					background before giving you full access to investment
					opportunities.
				</p>
				<div style={{ overflow: "hidden" }}>
					{/* <div className="col-xs-12 padding-20px paddingLeft-30px paddingRight-30px imghide">
						<div className="Mainstep">
							<div className="container">
								<div className="row" style={{ margin: 0 }}>
									<ul className="breadcrumb">
										<li className="completed1"><a href="javascript:" className="cursorDefault">Create Account</a></li>
										<li className="completed1"><a href="javascript:" className="cursorDefault">Investment Profile &nbsp;&nbsp;</a></li>
										<li><a href="javascript:" className="cursorDefault">Terms and Verification</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div> */}

					<div className="">
						<div
							className="panel panel-primary"
							style={{
								border: 0,
								borderRadius: 0,
								boxShadow: "none",
							}}
						>
							{/* <div>
								<p className="" style={{ fontSize: 24, textAlign: 'left' }}>Tell Us A Little More About Yourself.</p>
							</div> */}

							<div className="panel-body">
								<form name="form2">
									<div className="row">
										<div className="col-md-12">
											{/* <p style={{ fontSize: '1.2em' }}>Securities regulation require that we learn more about your investment background before giving you full access to investment opportunities.</p>
											<br/> */}
											{this.state.userType === 1 && (
												<div>
													<div className="row">
														<div
															className="col-xs-12"
															style={{
																width: "100%",
															}}
														>
															<div className="form-group">
																<div className="check_tc">
																	<h4>
																		Accreditation
																	</h4>
																	<p>
																		Please
																		share
																		your
																		expected
																		income
																		for this
																		year, as
																		well as
																		your
																		income
																		from the
																		previous
																		two
																		years.
																	</p>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div
															className="col-xs-12"
															style={{
																width: "100%",
															}}
														>
															<div className="form-group">
																<div
																	className="check_tc"
																	style={{
																		marginBottom: 30,
																	}}
																>
																	<div
																		className="col-xs-6"
																		style={{
																			width: 120,
																		}}
																	>
																		<label
																			className="custom-container font-normal"
																			style={{
																				float: "left",
																			}}
																		>
																			Individual
																			<input
																				type="radio"
																				ref="maritalStatus1"
																				name="maritalStatusRadio"
																				id="maritalStatus1"
																				onChange={() =>
																					this.clearFieldError(
																						"maritalStatus"
																					)
																				}
																				disabled={
																					this
																						.state
																						.regStepTwoCompleted
																				}
																			/>
																			<span className="checkmark checkmark-radio" />
																		</label>
																	</div>
																	<div
																		className="col-xs-6"
																		style={{
																			width: 150,
																		}}
																	>
																		<label
																			className="custom-container font-normal paddingLeft-30px"
																			style={{
																				float: "left",
																			}}
																		>
																			Joint
																			(Married)
																			<input
																				type="radio"
																				ref="maritalStatus2"
																				name="maritalStatusRadio"
																				id="maritalStatus2"
																				onChange={() =>
																					this.clearFieldError(
																						"maritalStatus"
																					)
																				}
																				disabled={
																					this
																						.state
																						.regStepTwoCompleted
																				}
																			/>
																			<span className="checkmark checkmark-radio" />
																		</label>
																	</div>
																	<span
																		id="error_maritalStatus"
																		ref="error_maritalStatus"
																		style={{
																			color: "#ff0000",
																			float: "left",
																		}}
																	>
																		{" "}
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-xs-12 col-sm-6 col-md-6">
															<label className="font-normal d-block">
																<select
																	ref="incomeThisYear"
																	id="incomeThisYear"
																	className="verify-form-control form-control"
																	onChange={() =>
																		this.clearFieldError(
																			"incomeThisYear"
																		)
																	}
																	style={{
																		width: "100%",
																	}}
																	disabled={
																		this
																			.state
																			.regStepTwoCompleted
																	}
																>
																	<option value="0">
																		Select{" "}
																		{currentYear()}{" "}
																		Expected
																		Income
																	</option>
																	<option value="Less than $100,000">
																		Less
																		than
																		$100,000
																	</option>
																	<option value="$100,000 - $200,000">
																		$100,000
																		-
																		$200,000
																	</option>
																	<option value="$200,000 - $300,000">
																		$200,000
																		-
																		$300,000
																	</option>
																	<option value="$300,000 - $500,000">
																		$300,000
																		-
																		$500,000
																	</option>
																	<option value="$500,000 - $1 Million">
																		$500,000
																		- $1
																		Million
																	</option>
																	<option value="$1 Million +">
																		$1
																		Million
																		+
																	</option>
																</select>
															</label>
														</div>
														<div className="col-xs-12 col-md-6"></div>
													</div>{" "}
													<br />
													<div
														className="row"
														style={{
															display: "flex",
														}}
													>
														<div className="col-xs-12 col-sm-6 col-md-6">
															<label className="font-normal d-block">
																<select
																	ref="incomeLastYear"
																	id="incomeLastYear"
																	className="verify-form-control form-control"
																	onChange={() =>
																		this.clearFieldError(
																			"incomeLastYear"
																		)
																	}
																	style={{
																		width: "100%",
																	}}
																	disabled={
																		this
																			.state
																			.regStepTwoCompleted
																	}
																>
																	<option value="0">
																		Select{" "}
																		{currentYear() -
																			1}{" "}
																		Income
																	</option>
																	<option value="Less than $100,000">
																		Less
																		than
																		$100,000
																	</option>
																	<option value="$100,000 - $200,000">
																		$100,000
																		-
																		$200,000
																	</option>
																	<option value="$200,000 - $300,000">
																		$200,000
																		-
																		$300,000
																	</option>
																	<option value="$300,000 - $500,000">
																		$300,000
																		-
																		$500,000
																	</option>
																	<option value="$500,000 - $1 Million">
																		$500,000
																		- $1
																		Million
																	</option>
																	<option value="$1 Million +">
																		$1
																		Million
																		+
																	</option>
																</select>
															</label>
														</div>
														<div className="col-xs-12 col-sm-6 col-md-6">
															<label className="font-normal d-block">
																<select
																	ref="incomeTwoYearsBack"
																	id="incomeTwoYearsBack"
																	className="verify-form-control form-control"
																	onChange={() =>
																		this.clearFieldError(
																			"incomeTwoYearsBack"
																		)
																	}
																	style={{
																		width: "100%",
																	}}
																	disabled={
																		this
																			.state
																			.regStepTwoCompleted
																	}
																>
																	<option value="0">
																		Select{" "}
																		{currentYear() -
																			2}{" "}
																		Income
																	</option>
																	<option value="Less than $100,000">
																		Less
																		than
																		$100,000
																	</option>
																	<option value="$100,000 - $200,000">
																		$100,000
																		-
																		$200,000
																	</option>
																	<option value="$200,000 - $300,000">
																		$200,000
																		-
																		$300,000
																	</option>
																	<option value="$300,000 - $500,000">
																		$300,000
																		-
																		$500,000
																	</option>
																	<option value="$500,000 - $1 Million">
																		$500,000
																		- $1
																		Million
																	</option>
																	<option value="$1 Million +">
																		$1
																		Million
																		+
																	</option>
																</select>
															</label>
														</div>
													</div>
													<div
														id="error_income"
														ref="error_income"
														className="text-danger"
													/>
													<br />
													<div className="row">
														<div className="col-xs-12 col-sm-6 col-md-6">
															<label className="d-block">
																<h4>
																	Net Worth
																</h4>
																<select
																	ref="netWorth"
																	id="netWorth"
																	className="verify-form-control form-control font-normal"
																	onChange={() =>
																		this.clearFieldError(
																			"netWorth"
																		)
																	}
																	style={{
																		width: "100%",
																	}}
																	disabled={
																		this
																			.state
																			.regStepTwoCompleted
																	}
																>
																	<option value="0">
																		Select
																		One
																	</option>
																	<option value="Less than $200,000">
																		Less
																		than
																		$200,000
																	</option>
																	<option value="$200,000 - $1 Million">
																		$200,000
																		- $1
																		Million
																	</option>
																	<option value="$1 Million - $3 Million">
																		$1
																		Million
																		- $3
																		Million
																	</option>
																	<option value="$3 Million - $5 Million">
																		$3
																		Million
																		- $5
																		Million
																	</option>
																	<option value="$5 Million - $10 Million">
																		$5
																		Million
																		- $10
																		Million
																	</option>
																	<option value="$10 Million +">
																		$10
																		Million
																		+
																	</option>
																</select>
															</label>
														</div>
													</div>
													<div
														id="error_netWorth"
														ref="error_netWorth"
														className="text-danger"
													/>
													<br />
													<div className="form-group">
														<label htmlFor="lname">
															Do you or your
															"spousal equivalent"
															hold Series 7,
															Series 65, or Series
															82 licenses, or
															similar professional
															certifications,
															designations or
															credentials?
														</label>
														<div className="form-group">
															<div
																className="check_tc"
																style={{
																	marginBottom: 30,
																}}
															>
																<div
																	className="col-xs-6"
																	style={{
																		width: 120,
																	}}
																>
																	<label
																		className="custom-container font-normal"
																		style={{
																			float: "left",
																		}}
																	>
																		Yes
																		<input
																			type="radio"
																			ref="profCert1"
																			name="profCert"
																			id="profCert1"
																			onChange={() =>
																				this.clearFieldError(
																					"profCert"
																				)
																			}
																			disabled={
																				this
																					.state
																					.regStepTwoCompleted
																			}
																		/>
																		<span className="checkmark checkmark-radio" />
																	</label>
																</div>
																<div
																	className="col-xs-6"
																	style={{
																		width: 150,
																	}}
																>
																	<label
																		className="custom-container paddingLeft-30px font-normal"
																		style={{
																			float: "left",
																		}}
																	>
																		No
																		<input
																			type="radio"
																			ref="profCert2"
																			name="profCert"
																			id="profCert2"
																			onChange={() =>
																				this.clearFieldError(
																					"profCert"
																				)
																			}
																			disabled={
																				this
																					.state
																					.regStepTwoCompleted
																			}
																		/>
																		<span className="checkmark checkmark-radio" />
																	</label>
																</div>
																<span
																	id="error_profCert"
																	ref="error_profCert"
																	style={{
																		color: "#ff0000",
																		float: "left",
																	}}
																>
																	{" "}
																</span>
															</div>
														</div>
													</div>
													<br />
													{/* <div className="form-group">
													<label htmlFor="lname">Are you currently invested in direct Oil and Gas assets?</label>
													<div className="form-group">
														<div className="check_tc" style={{marginBottom: 30}}>
															<div className="col-xs-6" style={{ width: 120 }}>
																<label className="custom-container" style={{ float: 'left' }}>Yes
																	<input type="radio" ref="currInvest1" name="currInvest" id="currInvest1"
																		   onChange={() => this.clearFieldError('currInvest')}
																	/>
																	<span className="checkmark checkmark-radio" />
																</label>
															</div>
															<div className="col-xs-6" style={{ width: 150 }}>
																<label className="custom-container paddingLeft-30px" style={{ float: 'left' }}>No
																	<input type="radio" ref="currInvest2" name="currInvest" id="currInvest2"
																		   onChange={() => this.clearFieldError('currInvest')}
																	/>
																	<span className="checkmark checkmark-radio" />
																</label>
															</div>
															<span id="error_currInvest" ref="error_currInvest" style={{ color: '#ff0000', float: 'left' }}> </span>
														</div>
														<div ref="investTypesDiv" id="investTypesDiv" className="check_tc" style={{display: 'none'}}>
															<label>
																What types of investments?<br/>
																<select ref="investTypes" id="investTypes" className="verify-form-control form-control" onChange={() => this.clearFieldError('investTypes')}
																		style={{width: 250}}
																>
																	<option value="0">Select One</option>
																	<option value="Minerals">Minerals</option>
																	<option value="Working Interest">Working Interest</option>
																	<option value="Other">Other</option>
																</select>
															</label>
														</div>
													</div>
													<span id="error_investTypes" className="text-danger" />
												</div> */}
													<div className="form-group">
														<button
															id="submit2"
															ref="submit2"
															onClick={
																this.submitStep2
															}
															value="submit"
															className="btn semi-bold"
															style={{
																height: 50,
																width: 250,
																backgroundColor:
																	"#5bb462",
																color: "#fff",
															}}
														>
															Continue
														</button>
													</div>
													<div className="form-group">
														<button
															onClick={() => {
																this.setState({
																	RegistrationStep: 0.5,
																});
																this.props.history.push(
																	"/register/step1"
																);
															}}
															type="button"
															className="btn center"
															style={{
																height: 50,
																width: "auto",
																backgroundColor:
																	"#fff",
																color: "#182F3B9A",
															}}
														>
															Back
														</button>
													</div>
												</div>
											)}

											{this.state.userType === 2 && (
												<div>
													<div className="form-group">
														<div className="check_tc">
															<label>
																Type of Firm
																<br />
																<select
																	ref="firmType"
																	id="firmType"
																	className="verify-form-control form-control"
																	onChange={() =>
																		this.clearFieldError(
																			"firmType"
																		)
																	}
																	style={{
																		width: 240,
																	}}
																>
																	<option value="0">
																		Select
																		One
																	</option>
																	<option value="RIA">
																		RIA
																	</option>
																	<option value="CPA">
																		CPA
																	</option>
																	<option value="Hedge Fund">
																		Hedge
																		Fund
																	</option>
																	<option value="Private Equity">
																		Private
																		Equity
																	</option>
																	<option value="Other">
																		Other
																	</option>
																</select>
															</label>
															<div
																id="error_firmType"
																ref="error_firmType"
																className="text-danger"
															/>
															<br />
														</div>
													</div>

													<div
														className="form-group"
														hidden={
															this.state
																.firmType ===
															"" ||
															this.state
																.firmType ===
															"CPA"
														}
													>
														<div className="check_tc">
															<label>
																Assets Under
																Management
																<br />
																<select
																	ref="assetsUnderManagement"
																	id="assetsUnderManagement"
																	className="verify-form-control form-control"
																	onChange={() =>
																		this.clearFieldError(
																			"assetsUnderManagement"
																		)
																	}
																	style={{
																		width: 240,
																	}}
																>
																	<option value="0">
																		Select
																		One
																	</option>
																	<option value="$0 to $50M">
																		$0 to
																		$50M
																	</option>
																	<option value="$50M to $100M">
																		$50M to
																		$100M
																	</option>
																	<option value="$100M to $200M">
																		$100M to
																		$200M
																	</option>
																	<option value="$200M to $500M">
																		$200M to
																		$500M
																	</option>
																	<option value="$500M to $1B">
																		$500M to
																		$1B
																	</option>
																	<option value="$1B+">
																		$1B+
																	</option>
																</select>
															</label>
															<div
																id="error_assetsUnderManagement"
																ref="error_assetsUnderManagement"
																className="text-danger"
															/>
															<br />
														</div>
													</div>

													<div className="form-group">
														<button
															id="submit2"
															ref="submit2"
															onClick={
																this.submitStep2
															}
															value="submit"
															className="btn semi-bold"
															style={{
																height: 50,
																width: 250,
																backgroundColor:
																	"#5bb462",
																color: "#fff",
															}}
														>
															Continue
														</button>
													</div>
												</div>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div
						className="col-md-4 nopadding imghide"
						style={{ paddingLeft: 0 }}
					></div>
				</div>
				{/* <FooterFirst isMarketplace={this.props.isMarketplace} /> */}
			</div>
		);
	}

	renderRegistrationStep2() {
		if (this.state.NeedTOS) {
			swal("Welcome Back!", "", "info");
			swal.fire({
				title: "Welcome Back!",
				html: '<span style="font-size: 1.4em">We ask that you please read and agree to the Terms & Conditions on this page before proceeding. Thank you.</span>',
				type: "info",
				width: "50rem",
				confirmButtonColor: "#5bb462",
				confirmButtonText: "I Understand",
			});
		}

		return (
			<div
				hidden={
					!this.state.RegistrationStep === 2 ||
					this.state.RegistrationStep === 0 ||
					this.state.RegistrationStep === 0.5 ||
					this.state.RegistrationStep === 1
				}
			>
				<div style={{ overflow: "hidden" }}>
					{/* <div className="col-xs-12 padding-20px paddingLeft-30px paddingRight-30px imghide">
						<div className="Mainstep">
							<div className="container">
								<div className="row" style={{ margin: 0 }}>
									<ul className="breadcrumb">
										<li className="completed1"><a href="javascript:" className="cursorDefault">Create Account</a></li>
										<li className="completed1"><a href="javascript:" className="cursorDefault">Investment Profile &nbsp;&nbsp;</a></li>
										<li className="completed1"><a href="javascript:" className="cursorDefault">Terms and Verification</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div> */}

					<div className="step2">
						<h1>Terms &amp; Conditions</h1>
						<p>Please agree to the following terms.</p>

						<div
							className="panel panel-primary"
							style={{
								border: 0,
								borderRadius: 0,
								boxShadow: "none",
							}}
						>
							<div className="panel-body">
								<div className="row">
									<div className="col-md-12">
										<div className="row">
											<div
												className="col-xs-12"
												style={{ width: "100%" }}
											>
												<div className="form-group">
													<div
														className="check_tc"
														style={{
															marginTop: 10,
														}}
													>
														<label className="custom-container">
															I understand
															EnergyFunders does
															not offer investment
															advice. I have the
															sophistication to
															evaluate investments
															on my own.
															<input
																type="checkbox"
																ref="termsCB2"
																name="termsCB2"
																id="termsCB2"
															/>
															<span className="checkmark" />
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div
												className="col-xs-12"
												style={{ width: "100%" }}
											>
												<div className="form-group">
													<div
														className="check_tc"
														style={{
															marginTop: 10,
														}}
													>
														<label className="custom-container font-bold">
															I understand
															securities on
															EnergyFunders are
															not easily re-sold.
															EnergyFunders is
															currently pursuing
															an agreement with
															tZERO to list our
															securities on a
															secondary market,
															but there can be no
															guarantee of
															liquidity if/when
															the agreement is
															finalized. I can
															wait years for a
															return.
															<input
																type="checkbox"
																ref="termsCB3"
																name="termsCB3"
																id="termsCB3"
															/>
															<span className="checkmark" />
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div
												className="col-xs-12"
												style={{ width: "100%" }}
											>
												<div className="form-group">
													<div
														className="check_tc"
														style={{
															marginTop: 10,
														}}
													>
														<label className="custom-container">
															I understand: (a)
															all of the risks of
															investing, including
															the risk that I
															could lose all of
															the money I invest,
															(b) that I am
															advised to review
															all of the
															information in an
															offering's
															Disclosure Document
															prior to investing
															in such offering,
															and (c) that the
															financial outcomes
															of investments
															offered on the
															EnergyFunders
															platform are not
															guaranteed.
															<input
																type="checkbox"
																ref="termsCB4"
																name="termsCB4"
																id="termsCB4"
															/>
															<span className="checkmark" />
														</label>
													</div>
												</div>
											</div>
										</div>
										<span
											id="tc_error"
											ref="tc_error"
											style={{ color: "#a94442" }}
										>
											{" "}
										</span>
									</div>
								</div>

								<div className="row">
									<div
										className="col-md-12"
										style={{ width: "100%" }}
									>
										<div className="form-group">
											{(() => {
												if (
													this.state.EmailVerified ===
													1
												) {
													return (
														<button
															id="submit3"
															ref="submit3"
															onClick={
																this.submitStep3
															}
															value="submit"
															className="btn semi-bold"
															style={{
																height: 50,
																width: 250,
																backgroundColor:
																	"#5bb462",
																color: "#fff",
															}}
														>
															Continue to Login
														</button>
													);
												} else {
													return (
														<button
															id="submit3"
															ref="submit3"
															onClick={
																this.submitStep3
															}
															value="submit"
															className="btn semi-bold"
															style={{
																height: 50,
																width: 250,
																backgroundColor:
																	"#5bb462",
																color: "#fff",
															}}
														>
															Continue
														</button>
													);
												}
											})()}
										</div>
										<div className="form-group">
											<button
												onClick={() => {
													this.setState({
														RegistrationStep: 1,
													});
													this.props.history.push(
														"/register/step2"
													);
												}}
												type="button"
												className="btn center"
												style={{
													height: 50,
													width: "auto",
													backgroundColor: "#fff",
													color: "#182F3B9A",
												}}
											>
												Back
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="col-md-2 nopadding imghide"
						style={{ paddingLeft: 0 }}
					></div>
				</div>
			</div>
		);
	}

	render() {
		const { RegistrationStep, steps, currentStep } = this.state;
		// if (this.state.RegistrationStep === 0) {
		// 	return this.renderRegistrationStep0();
		// }
		// else if (this.state.RegistrationStep === 1) {
		// 	return this.renderRegistrationStep1();
		// }
		// else if (this.state.RegistrationStep === 2) {
		// 	return this.renderRegistrationStep2();
		// }
		// else {
		// 	return(
		// 		<div>No Data.</div>
		// 	)
		// }
		return (
			<div>
				<NavSignUp />
				<div className="new-user-signup">
					<div
						style={{
							backgroundColor: "#092736",
							paddingBottom: 20,
						}}
					>
						<Stepper
							steps={steps}
							activeStep={currentStep}
							defaultColor="#fff"
							activeColor="#5bb462"
							circleFontColor="#000"
							circleFontSize={14}
							defaultTitleColor="#fff"
							activeTitleColor="#5bb462"
							completeColor="#5bb462"
							completeTitleColor="#5bb462"
							completeBarColor="#fff"
						/>
					</div>
					<div className="container-fluid position-relative">
						<div className="linear-gradient"></div>
						<div className="container">
							<div className="row  align-items-center">
								<div className="col-md-7 col-lg-7  justify-content-center position-relative lt-sec">
									{this.renderRegistrationStep0()}
									{this.renderRegistrationStep05()}
									{this.renderRegistrationStep1()}
									{this.renderRegistrationStep2()}
								</div>
								{RegistrationStep === 0 && (
									<div className="col-md-5 col-lg-5 d-flex flex-column rt-sec">
										<h1>Investor Benefits</h1>
										<ul>
											<li>
												24/7 Access to Private, Curated
												Energy Deals
											</li>
											<li>
												Targeting High Returns from
												Energy
												<br />
												Investing, Without Excessive
												Risk
											</li>
											<li>
												Follow Your Investment Via Our
												Investor
												<br />
												Platform on Desktop or Mobile
												App
											</li>
										</ul>
										<hr />
										<h1>Investor Experience</h1>
										<p>
											&ldquo;Oil and Gas investing can be
											complicated, but EnergyFunders
											provides great communication all the
											way through the investment process…
											I’m glad I found a group that I can
											trust when it comes to this
											industry.&rdquo;
											<span>
												Garrett C. , Long Time Investor
											</span>
										</p>
									</div>
								)}
								{RegistrationStep === 1 && (
									<div className="col-md-5 col-lg-5   d-flex flex-column rt-sec">
										<h1>Advantages of Our Fund</h1>
										<ul>
											<li>
												Above-Market Returns: We Target
												IRRs of 30-100%
											</li>
											<li>
												Quick Payout and Consistent Cash
												Flow: Earn Distributions Within
												Six Months
											</li>
											<li>Protection From Inflation</li>
											<li>Tax Advantages</li>
										</ul>
										<hr style={{ marginTop: 257 }} />
										<h3>Questions?</h3>
										<p>
											You’ll always have direct access to
											our team of experts via phone or
											email.
										</p>
									</div>
								)}
								{RegistrationStep === 2 && (
									<div className="col-md-5 col-lg-5   d-flex flex-column rt-sec">
										<h1>Follow Your Investment Anytime</h1>
										<ul>
											<li>
												Use Our Proprietary Investor
												Platform To See Your Earnings,
												Documents, Transactions, And
												Messages
											</li>
											<li>
												See Oil And Gas Project Updates,
												Videos, And Photos, Directly
												From The Wellhead
											</li>
											<li>
												Access Info Via Our Mobile App
												Or Desktop Platform
											</li>
										</ul>
										<hr />
										<h3>Investor Experience</h3>
										<p>
											&ldquo;A great way to get into oil
											and gas investing without breaking
											the bank. Also easy to diversify
											between projects. Nothing else like
											it out there.&rdquo;
											<span>
												David N. , Long Time Investor
											</span>
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(User1);

import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Innovation_Fund_1 } from '../../utils/General';
import { FaHandPointUp, FaLongArrowAltRight } from "react-icons/fa";
import {TiMessages} from 'react-icons/ti';
import {FiSettings} from 'react-icons/fi';
import {MdGroup} from "react-icons/md";
import {GrUserWorker} from "react-icons/gr";
import {CgMaximizeAlt} from "react-icons/cg";
import {GiNetworkBars} from "react-icons/gi";
class InnovationFund1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			selectedProjectCategory: "all",
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0,0);
	}

	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
	const documentIcon = "/img/document.svg";
		const  ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		// const wildcatFundOverview = "/img/investmentopportunities/wildcat-fund-overview.png";
		// const wildcatFundSummary = "/img/investmentopportunities/wildcat-fund-summary.png";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>Innovation Fund I</h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
								<p className="text-justify">
								The mission of the new Innovation Fund model is to find and fund startups with innovative technologies that shake up the status quo and solve real-world problems. As an energy industry disrupter with an established digital platform, EnergyFunders is inviting its investors to be a part of the revolutionization of the car care industry through its first Innovation Fund offering. 
									<br />
									<p className="text-justify">Paleo Car Care is a digital platform that connects automotive technicians directly with customers. This novel approach to car care lowers costs by cutting out unnecessary middlemen and aligns the incentives between customers and repair technicians. </p>
									<p className="text-justify">The Paleo model provides customers with the highest quality parts and service at the lowest cost, with greater convenience. Technicians win by working on their own terms, earning more money with no boss. Car owners win with a mobile-first, customer-first business model.</p>
									<p className="text-justify">As an Innovation Fund I shareholder, you will be able to diversify your portfolio by investing in innovative energy technologies, like Paleo Car Care, that have established revenue and a defined roadmap for growth.</p>
									{/* <p>Access to:
										<br />
										Investments in carefully vetted energy technologies with proven success and well defined plans for growth.
									</p> */}

								</p>
								{/* <h2>PALEO CAR CARE</h2>
								<h3>The Evolution of Car Care</h3>
								<div className="mt-5 col-md-12">

								<div className="col-md-4" ><img src="/img/paleogreen.jpg" width="100%" id="desktopImg" className="mt-2 center-margin " /></div>
								<div className="col-md-8">
									<p className="text-justify"><b>Paleo Car Care</b> is a digital platform that connects
automotive technicians directly with customers.
This novel approach to car care lowers costs by cutting
out unnecessary middlemen, and aligning the incentives
between customers and repair technicians.</p>
<p className="text-justify">The Paleo model provides customers with the <b>highest
quality parts and service at the lowest cost</b>, with
greater convenience. Technicians win by working on their
own terms, earning more money with no boss.</p>
<h2 className="text-green mb-1 text-justify">$63 BILLION</h2>
The market size of this industry that is
ripe for disruption for the right investors.
<h2 className="text-green mb-1 text-justify">160,000</h2>
Independent autoshops in the US alone.
Globally it's 10x that.
</div>
								</div>
							<div className="mt-5 col-md-12">
								<h3>The Problem: A Crisis in Customer Experience</h3>
								<div className="col-md-8">
									<p className="text-justify"><i>"Show me the incentive, and I'll show you
the outcome" - Charlie Munger</i>	</p>
<p className="text-justify">The problems plaguing modern car care distill down to
flawed incentive structures between customers and
repair shops. The traditional car care business model
maximizes profit margins by using the cheapest,
lowest-quality parts while charging the highest fees -
often for unnecessary parts and service.</p>
<p className="text-justify">As a result, both customers and technicians resent
traditional repair shops because of predatory practices
in pricing and perverse incentive structures.
This shows up in the rock bottom "net promoter score"
(NPS) - the industry standard metric for the quality of
the customer experience.</p>
<p className="text-justify">Meanwhile, customers pay $100-$215 per hour for labor,
while the technician performing the work typically
earns about $18-$40 per hour.</p>
								</div>
								<div className="col-md-4">
								<p>
	<h3 className="text-green">Market Landscape
</h3>
<p className="text-justify">While big, the car service industry is loathed, with a NPS of -13!</p>
</p>
									<p className="text-justify"><FaLongArrowAltRight color="#7AC968"  className="mr-3 " />The bottom line: more than 80% margin
stays with the middleman.</p>
<p className="text-justify"><i>"Your margin is my opportunity" - Jeff Bezos</i></p>

								</div>
							</div> */}
							<div className="mt-5 col-md-12">
								<div className="col-md-8">
									{/* <h4><b>The Opportunity:</b> A $650 Billion Market
Ripe for Disruption</h4>
<p className="text-justify"><b>Paleo Car Care solves these problems by closing the gap
between customers and repair technicians.</b>
</p> */}
<div className="mt-2 col-md-12 pl-0 pr-0 pt-4"><div className="col-md-1"><TiMessages size={27} color="#7AC968" className="mr-3" />
	</div> <div className="col-md-11 text-justify">Through a first of its kind digital platform, Paleo
Car Care creates a direct feedback loop between
consumers and technicians. This digital network
pairs customer ratings with a competitive bidding
system, ensuring customers get the highest
quality service at the lowest cost. </div></div>

<div className="mt-2 col-md-12 pl-0 pr-0 pt-4"><div className="col-md-1"><FaHandPointUp size={27} color="#7AC968" className="mr-3" />
	</div> <div className="col-md-11 text-justify">The easy-to-use platform also unlocks greater
convenience for consumers, who can select
their preferred technicians based on location,
previous ratings, and specialization. </div></div>
<div className="mt-2 col-md-12 pl-0 pr-0 pt-4"><div className="col-md-1"><FiSettings size={27} color="#7AC968" className="mr-3" />
	</div> <div className="col-md-11 text-justify">Paleo’s parts distribution and equipment centers
are designed to increase economies of scale and
further drive down costs. When customers order
the service, parts are delivered to the technician
automatically. We give technicians the flexibility
and independence they want, with access to 24/7
on-demand lifts and other repair infrastructure. </div></div>
								</div>
								<div className="col-md-4">
									<img src="/img/settingspaleogreen.jpg" width="100%" className="mt-2 center-margin " id="desktopImg" />
								</div>
							</div>
							<div className="col-md-12 mt-5"> <h3 className="text-center">Bringing Customers and Technicians Together</h3>
							<div className="col-md-12 mt-5 text-center">
							<div className="col-md-4"> <div> <MdGroup size={27} className="mr-3" /> </div> <div>Customers get mobile
first, less expensive,
convenient car care. 
</div></div>
							<div className="col-md-4"><img src="https://www.paleocarcare.com/assets/images/logos/paleo-logo.svg"id="desktopImg"  width="50%" /></div>
							<div className="col-md-4"> <div> <GrUserWorker size={27} className="mr-3" /> </div> <div>Technicians get to work on
their terms, earning more
money with no boss.</div> </div>
</div>
							 </div>
							 <div className="col-md-12 mt-5"> <h3 className="text-center">Financial Projections</h3>
							 <div className="col-md-12 mt-5 text-center">
								<div className="col-md-6 display-inline-flex"><GiNetworkBars size={40} color="#7AC968" style={{marginBottom:'-23px'}}  /> <h3 className="text-left pl-3">  $1.7M IN REVENUE <br />
TO DATE</h3> </div>
								<div className="col-md-6 display-inline-flex"><CgMaximizeAlt size={75} color="#7AC968" style={{marginBottom:'-18px', marginTop:"-12px"}}  /> <h3 className="text-left pl-3"> EXPANSION INTO 2% OF THE
TOP 10 TARGET MARKETS
 </h3> </div>
 
							 </div>
							 <div className="col-md-12 mt-5">
								<div className="col-md-6"> <p className="text-justify"> <b>Paleo Car Care has generated $1.7M in revenue
to date. </b>  The business enjoys compelling unit economics, generating $512 in average sales per customer versus a customer acquisition cost of only $25 </p> </div>
<div className="col-md-6"> <p className="text-justify"> The upside opportunity will come from expansion
into 2% of the top 10 target markets, <b>generating
$178M in annual revenue and $28M in annual
operating profit. </b> </p> </div>
 
							 </div>
							 <div className="col-md-12 mt-5 text-center"> <i className="text-center">Paleo Car Care is owned and managed by the same principal owners of EnergyFunders.</i></div>
							 </div>
							</div>
							<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-left py-3 px-3">
											<ReactSVG src="/img/investmentopportunities/oil.svg"  />
												<span className="pl-4">
												Energy Tech
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={ () =>   this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Innovation_Fund_1,
													
												}
											})
												
											}
										>
											Start Investing Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>3-5 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>IPO or Flip</span> Potential Trajectory
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>Higher</span> Risk Level
								</li>
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$20,000</span> Minimum Investment
								</li>
<br/>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li> */}
								
							</ul>
							{/* <ul className="highlights list-unstyled d-flex">
							<li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/exclusive.svg" />
									<span>Exclusive Access</span> Private Market
									Opportunities
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/taxes.svg" />
									<span>Tax Savings</span> Potential
									Deductions Against Active Income
								</li>
							</ul> */}
						</div>
						{/* <div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							<div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												5.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												3.25% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $500,000 but less than
												$1,000,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$1,000,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-5">
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								<h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 15%. And second, 80% to Investors
									and 20% to the Manager.
								</p>
							</div>
						</div> */}
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4"><a href="/documents/innovationfund/LLC_Agreement_Energy_Innovation_Fund_I_LLC_Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4"><a href="/documents/innovationfund/Disclosure_Document_Energy_Innovation_Fund_I_LLC_Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4"><a href="/documents/innovationfund/Investment_Agreement_Energy_Innovation_Fund_I_LLC_Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								{/* <li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${wildcatFundSummary})`}}></div>
									<h3>Drilling Fund I LLC Two-page Summary</h3>
									<div className="mt-4"><a href="/documents/drillingfund/drillingfund-2-pager.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li> */}
							</ul>
						</div>
					{/* <OurTeam/> */}
					<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
<div className="pull-left d-flex align-items-center left-sec"><ReactSVG src={ProjectUpdatesIcon} /> <span>Innovation Fund I </span></div>
<div className="pull-right rt-sec"><button className="btn btn-primary  px-5 py-4 ml-3 font-bold" onClick={ () =>   this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Innovation_Fund_1,
													
												}
											})
												
											}>
									Start Investing Today
										</button></div>
					</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

InnovationFund1.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InnovationFund1);

export default withRouter(connectedComponent);

import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Opportunity_Fund_1_ID } from '../../utils/General';
import {IoMdLock} from 'react-icons/io';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import RequestService from "../../api/RequestService";
import HubspotFormComponent from "./hubspotFormComponent";


class OpportunityFund1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			enterCodeModal: false,
			OpportunityFundCodeVerified: false,
			selectedProjectCategory: "all",
			inviteCode: '',
			loading: false,
			codeError: false,
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0, 0);
		this.getOpportunityFundCode();
	}
	async getOpportunityFundCode(){
        const {data}  = await RequestService.get(`/api/getOpportunityFundCode`);
            let result = await data.result;
            console.log(result);
            let value = result?.find(i => i?.code == 'efoppy1'?.trim()?.toLowerCase());
            this.setState({OpportunityFundCodeVerified: !!value});
    }
	async checkInviteCode(){
		this.setState({loading: true});
		const { data } = await RequestService.post({
			url: `/api/checkAndAddOpportunityFundCode/${this.state.inviteCode}`,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
		});
		console.log(data);
		if(data?.result?.valid){
			this.setState({OpportunityFundCodeVerified: true,enterCodeModal:false, loading:false });

		}else{
			this.setState({codeError: true});
			this.setState({loading: false});
		}

	}
	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
		const documentIcon = "/img/document.svg";
		const ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		// const wildcatFundOverview = "/img/investmentopportunities/wildcat-fund-overview.png";
		// const wildcatFundSummary = "/img/investmentopportunities/wildcat-fund-summary.png";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>Opportunity Fund I</h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
								{this.state.OpportunityFundCodeVerified ? <p>
									<p>The EnergyFunders Opportunity Fund offers venture capital-like upside within an investment vehicle that aims to minimize downside risk. This unique risk/reward proposition comes from a three-tiered target capital allocation approach:
									</p>
									<p>We expect to allocate approximately 20% of the capital into higher-upside, higher-risk opportunities, targeting 10 - 50x returns. We aim to allocate half of that (~10%) toward wildcat wells (higher risk and far from known production) and half in Paleo Car Care convertible notes.
									</p>
									<p>
										A larger portion of the capital (roughly 60%) will be allocated to moderate-risk opportunities, targeting 3 - 5x returns. These wells will be in proven (lower risk) offset drilling locations, which are supported by nearby producing wells and subsurface data. These to-be-drilled wells carry moderate risk.
									</p>
									<p>Approximately 20% of the capital will be allocated to lower risk income-generating assets from investments in wells that are already drilled and producing, targeting 10-15% returns.</p>
									<p>The time is right to take advantage of rising global demand coupled with global oil inventories near historic lows. These two factors  have supported bullish energy price forecasts for 2023.  Historically, investing in oil and gas has been an excellent inflation hedge, as the commodity cycles tend to be opposite the inflation trends.
										Eliminate stock market volatility with a private market approach to oil and gas investing—a falling stock market won’t stop the oil and gas income streams from flowing through your successful projects.
									</p>
									<p>The IRS tax code is extremely favorable to oil and gas: Get exposure to some of the best breaks in the IRS tax code, including intangible drilling cost deductions for eligible investors.  Direct wellbore investment in new drills will maximize potential tax deductions available to investors. Learn more about the tax advantages <a className="text-underline bold-text" target={'_blank'} href="https://www.energyfunders.com/images/documents/EFEbook-Tax-Benefitsof-Oil-and-Gas-Investing.pdf">here.</a></p>
									<p>In addition to the energy sector, we plan to provide investors with
										exposure to Paleo Car Care (“Paleo”)—a digital technology platform that
										enables customers the convenience to order fuel from their smartphone app.
										Paleo also connects automotive technicians directly with customers,
										cutting out costly middlemen. Paleo has generated $1.7M
										in revenue to date while demonstrating compelling unit
										economics. We expect the upside opportunity will come from
										expansion into 2% of the top 10 target markets, potentially
										generating $178M in annual revenue and $28M in annual operating
										profit. Paleo is owned and managed by the principal
										owners of EnergyFunders.
									</p>
									<p>Opportunities are evaluated and capital is managed by a experienced team with a proven track record.
										The EnergyFunders team includes oil and gas professionals with deep technical expertise spanning geology, engineering, legal and finance. They have worked in leadership roles as CEOs and founders of independent oil and gas companies, bringing to EnergyFunders their experience working with some of the largest oil and gas companies in the world. </p>
								</p> : <p>
								<div className={`flex h-full w-full flex-col rounded bg-warning p-4 `} >
								<div className=" d-flex ">
        

        <div
          className="lockIcon"
        >
            <IoMdLock size={40} color={"#5bb462"}/>
        </div>
        <div className="pt-2" style={{paddingLeft:'2rem'}}>
          <h3>This Fund is  <b>by invitation only</b></h3>
          <p>If you already have a code, click <a className="text-green pointer" onClick={()=>this.setState({enterCodeModal: true}) }>here</a> to enter it</p>
        </div>
      </div></div>
	  <div className=" col-md-10 text-start mt-5">
            <h3 className="mt-1 pb-1">Request to Join The Fund</h3>
            <p className="mb-4" style={{fontSize:'larger'}}>If you are interested in participating in a similar fund, please fill out the form below</p>
            
            <div className="mt-5">
				<HubspotFormComponent form_id="bfb1095e-d806-468f-b324-687c78b8a171" />
			</div>
          </div>
									</p>}
							</div>
							{this.state.OpportunityFundCodeVerified &&<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-center py-3 px-3">
												{/* <ReactSVG src="/img/investmentopportunities/oil.svg" /> */}
												<span className="">
												Oil &amp; Gas and Energy Tech
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={() => this.props.history.push({
												pathname: '/app/opportunityFundContact'
											})

											}
										>
											Contact Us
										</button>
									</div>
								</div>
							</div>}
						</div>
						{this.state.OpportunityFundCodeVerified && <div>
							
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>3-5 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>Periodic</span> Distributions
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>Balanced</span> Risk Level
								</li>
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$100K</span> Minimum Investment
								</li>
								<br />
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li> */}

							</ul>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/exclusive.svg" />
									<span>Exclusive Access</span> Private Market
									Opportunities
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/taxes.svg" />
									<span>Tax Savings</span> Potential
									Deductions Against Active Income
								</li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							<div className="col-md-7">
								<h4>2% Fee on assets under management and a 20% promote to the Fund Manager after investors have achieved these IRRs: </h4>
								<h4>
									<ul>
								<li className="mb-2">Class A Shares ($500k Minimum Investment) – 14% IRR </li>
								<li className="mb-2">Class B Shares ($250k Minimum Investment) – 12% IRR </li>
								<li className="mb-2">Class C Shares ($100k Minimum Investment) – 10% IRR </li>

									</ul>
								</h4>
							</div>
							{/* <div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												5.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												3.25% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $500,000 but less than
												$1,000,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$1,000,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div> */}
							{/* <div className="col-md-5">
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								<h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 15%. And second, 80% to Investors
									and 20% to the Manager.
								</p>
							</div> */}
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4"><a href="/documents/opportunityfund1/LLC_Agreement_EnergyFunders_Opportunity_Fund_I_LLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4"><a href="/documents/opportunityfund1/Disclosure_Document_EnergyFunders_Opportunity_Fund_I_LLC_-_Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4"><a href="/documents/opportunityfund1/Investment_Agreement_EnergyFunders_Opportunity_Fund_I_LLC_-_Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								{/* <li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${wildcatFundSummary})`}}></div>
									<h3>Drilling Fund I LLC Two-page Summary</h3>
									<div className="mt-4"><a href="/documents/drillingfund/drillingfund-2-pager.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li> */}
							</ul>
						</div>
						</div>}
						{/* <OurTeam /> */}
						<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
							<div className="pull-left d-flex align-items-center left-sec"><ReactSVG src={ProjectUpdatesIcon} /> <span>Opportunity Fund I</span></div>
							{this.state.OpportunityFundCodeVerified && <div className="pull-right rt-sec"><button className="btn btn-primary  px-5 py-4 ml-3 font-bold" onClick={() => this.props.history.push({
								pathname: '/app/opportunityFundContact'
							})

							}>
								Contact Us
							</button></div>}
						</div>
					</div>
				</div>
				<Modal
            onHide={()=> this.setState({enterCodeModal:false})}
                    show={this.state.enterCodeModal}
                    style={{ width: '40%', margin: '0 auto', top:"25%" }} className="gplp-voting-modal"
                >

                        <Modal.Body className="text-center">
						<h3>Please Enter Your Invite Code:</h3>
         <input type="text" style={{borderRadius:'5px', borderColor: this.state.codeError ? 'red' : 'limegreen'}} value={this.state.inviteCode} onChange={(e)=> this.setState({inviteCode: e.target.value})} name="inviteCode" />
         <br />
         {this.state.codeError && <p className="text-danger">Invalid Code</p>}
      <button className="btn  green-bg hover-light text-white rounded-0 mt-2 py-2 px-4"  onClick={()=>{console.log("Invite");console.log("e.target", this.state.inviteCode);this.checkInviteCode();}}>{this.state.loading ?' Please Wait...' : 'Verify'}</button>
						</Modal.Body>
						</Modal>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

OpportunityFund1.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(OpportunityFund1);

export default withRouter(connectedComponent);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import InvestorFilesUploader from './component/InvestorFilesUploader/InvestorFilesUploader';
import * as Actions from './actions/actions';
import InvestorFileViewerComponent from '../InvestorFIleViewerComponent/InvestorFileViewerComponent';

import { getHash } from '../../../utils/General';

import '../../investor/css/documents.css'
import RequestService from '../../../api/RequestService';
import { getImpersonateID } from "../../../utils/General";
class InvestorFilesContainerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAdmin: false,
            filteredFileList: [],
            investorInvestments: [],
            filter: {
                filterKey: '',
                filterParam: '',
                toggleFilterVisibility: false,
            },
            allProjectFilesCollection: [],
        };
    }

    componentDidMount() {
        const {
            getAllFilesForUser, selectedInvestorId, getAllInvestorFileTypes,
            getAllProjectsInServer, getContractFilesForUser,
        } = this.props;

        const isAdmin = !!selectedInvestorId;
        console.log('isAdmin', selectedInvestorId);
        getAllFilesForUser(selectedInvestorId, isAdmin);
        getAllInvestorFileTypes(selectedInvestorId, isAdmin);
        getContractFilesForUser(selectedInvestorId,isAdmin);

        if (isAdmin) {
            getAllProjectsInServer();
        }

        this.setState({ isAdmin });
        this.onClearFilter();
        this.getMyInvestments();
        this.getInvestorInvestments();
    }

    componentDidUpdate(prevProps) {
        const { investorFilesList } = this.props;
        const { investorFilesList: _investorFilesList } = prevProps;

        if (getHash(investorFilesList) !== getHash(_investorFilesList)) {
            this.onPerformFilter();
        }
    }

    onPerformFilter = (event = { preventDefault: () => null }) => {
        event.preventDefault();
        const { filter } = this.state;
        const { investorFilesList } = this.props;
        const { filterKey, filterParam } = filter;

        let filteredFileList = [];

        if (this.isNotValidFilterValue(filterKey) || this.isNotValidFilterValue(filterParam)
            || filterKey.toLowerCase() === 'none') {
            filteredFileList = [...investorFilesList];
        } else {
            filteredFileList = investorFilesList
                .filter(item => item[filterKey].toString()
                    .toLowerCase().indexOf(filterParam.toLowerCase()) !== -1);
        }

        this.setState({ filteredFileList });
    };

    isNotValidFilterValue = filterParam => (!filterParam || filterParam === '');

    onFilterInputChange = ({ target }) => {
        const { name, value } = target;
        const { filter } = this.state;
        const copy = { ...filter };

        if (name === 'filterKey') {
            copy.filterParam = '';
        }

        copy[name] = value;
        this.setState({ filter: copy });
    };

    onClearFilter = () => {
        const { filter } = this.state;
        const copy = { ...filter };

        copy.filterKey = '';
        copy.filterParam = '';

        this.setState({ filter: copy }, this.onPerformFilter);
    };

    onUploadFilesToS3 = (data, clean) => {
        const { uploadFilesToS3, selectedInvestorId } = this.props;
        const { isAdmin } = this.state;

        uploadFilesToS3(selectedInvestorId, data, isAdmin, clean);
    };

    onFileDownload = (fileId) => {
        const { downloadFileFromServer, selectedInvestorId } = this.props;
        const { isAdmin } = this.state;

        downloadFileFromServer(selectedInvestorId, fileId, isAdmin);
    };

    onMarkFileAsDeleted = async (fileId) => {
        const { markFileAsDeleted, selectedInvestorId } = this.props;
        const { isAdmin } = this.state;

        const { value } = await Swal.fire({
            title: 'Are you sure you want to delete this file?',
            text: 'You would have to contact the sys admin to recover the file',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#5bb462',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete File!',
        });

        if (!value) return;

        markFileAsDeleted(selectedInvestorId, fileId, isAdmin);
    };

    onUpdateStoredFile = (fileId, data) => {
        const { selectedInvestorId, updateInvestorFile } = this.props;
        const { isAdmin } = this.state;

        updateInvestorFile(selectedInvestorId, fileId, data, isAdmin);
    };


    getAllDocuements = async (item) => {

        const projectType = "PROJECT_REG_D";
        const URL = `/api/project/${item?.ProjectID}/details/documents/${projectType}`;
        const { data, status } = await RequestService.get(URL);

        const { projectFiles } = data;
        console.log(projectFiles);
        return projectFiles;


    }

    getInvestorInvestments = async () => {
        const { user, selectedInvestorId } = this.props;
        const impersonateID = await getImpersonateID();
        const userID = selectedInvestorId || impersonateID || user?.id;

        if (userID) {
            try {
                const { data } = await RequestService.get(`/api/myInvestments/${userID}`);

                if (data.code === 200) {
                    const userinvestments = data?.reg_d;
                    const dataCollection = [];
                    userinvestments.forEach(async (item, index) => {
                        let data = await this.getAllDocuements(item);
                        dataCollection.push(data);

                    });

                    this.setState({ allProjectFilesCollection: dataCollection })
                }

            } catch (err) {
                console.log(err);
            }
        }
    };

    async getMyInvestments() {


        const investorId = this.props?.selectedInvestorId;
        const { getAllProjectFile } = this.props;
        const impersonateID = await getImpersonateID();

    }

    render = () => {
        const { isAdmin, filter, filteredFileList, allProjectFilesCollection } = this.state;
        const {
            investorFilesList, mappedFileTypes,
            fileTypeList, allProjects, allProjectFiles, investorContractFilesList,
        } = this.props;
        const topMargin = { marginTop: 15 };
        const style = isAdmin ? null : topMargin;


        return (
            <div className="container  documents">
                <div className="col-xs-12 col-sm-12 col-md-12 p-0 ">
                    <InvestorFileViewerComponent
                        contractFilesList={investorContractFilesList}
                        projectFileList={filteredFileList}
                        onFileDownload={this.onFileDownload}
                        onDeleteFile={this.onMarkFileAsDeleted}
                        allProjects={allProjects}
                        fileTypeList={fileTypeList}
                        updateInvestorFile={this.onUpdateStoredFile}
                        isAdmin={isAdmin} allProjectFiles={allProjectFilesCollection}
                    />
                </div>
                <div className="col-xs-12  col-sm-6 col-md-6 p-0 mb-4 right-sec">
                    <div>
                        {/* <div className="col-xs-12 col-sm-12 col-md-12 p-0"><InvestorFilesFilter
                        {...filter}
                        onClearFilter={this.onClearFilter}
                        investorFilesList={investorFilesList}
                        onPerformFilter={this.onPerformFilter}
                        onInputChange={this.onFilterInputChange}
                    /></div> */}
                        <div className="col-xs-12 col-sm-12 col-md-12 "><InvestorFilesUploader
                            onUploadFile={this.onUploadFilesToS3}
                            mappedFileTypes={mappedFileTypes}
                            fileTypeList={fileTypeList}
                            allProjects={allProjects}
                        /></div></div>


                </div>


            </div>
        );
    };
}

InvestorFilesContainerComponent.propTypes = {
    allProjects: PropTypes.object.isRequired,
    downloadFileFromServer: PropTypes.func.isRequired,
    fileTypeList: PropTypes.array.isRequired,
    getAllFilesForUser: PropTypes.func.isRequired,
    getContractFilesForUser: PropTypes.func.isRequired,
    getAllInvestorFileTypes: PropTypes.func.isRequired,
    getAllProjectsInServer: PropTypes.func.isRequired,
    investorFilesList: PropTypes.array.isRequired,
    contractFilesList: PropTypes.array.isRequired,
    mappedFileTypes: PropTypes.object.isRequired,
    markFileAsDeleted: PropTypes.func.isRequired,
    selectedInvestorId: PropTypes.number.isRequired,
    uploadFilesToS3: PropTypes.func.isRequired,
    updateInvestorFile: PropTypes.func.isRequired,
};

InvestorFilesContainerComponent.defaultProps = {
    allProjects: {},
    downloadFileFromServer: () => null,
    fileTypeList: [],
    getAllFilesForUser: () => null,
    getContractFilesForUser: () => null,
    getAllInvestorFileTypes: () => null,
    getAllProjectsInServer: () => null,
    investorFilesList: [],
    investorContractFilesList: [],
    mappedFileTypes: {},
    markFileAsDeleted: () => null,
    selectedInvestorId: null,
    uploadFilesToS3: () => null,
    updateInvestorFile: () => null,
};

const mapStateToProps = _state => ({ ..._state.session, ..._state.investorFiles });

const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestorFilesContainerComponent);

export default withRouter(connectedComponent);

// admin
import { sessionService } from 'redux-react-session';
import IdleTimer from 'react-idle-timer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NestedRoute, SubRoute } from 'react-nested-route';
import * as $ from 'jquery';
import store from './index';
import FunderProfile from './FunderProfile';
import SidebarMenu from './components/shared/NavigationManager/SidebarMenu/SidebarContainer';
import EmailVerified from './EmailVerified';
import Fundraiserdealcard from './components/fundraiser/Fundraiserdealcard';
import Invest from './components/investor/InvestComponent/Invest';
import Invest2 from './components/investor/InvestComponent/Invest2';
import Postinvestments from './components/investor/Postinvestments';
import Invest3 from './components/investor/InvestComponent/Invest3';
import Invest4 from './components/investor/InvestComponent/Invest4';
import InvContent from './components/investor/InvestorDashboardContainerComponent/InvestorDashboardContainerComponent';
import Adminnewheader from './components/shared/AdminNewHeader/Adminnewheader';
import Adminuserinvestments from './components/admin/Adminuserinvestments';
import Userinvestments from './components/fundraiser/Userinvestments';
import Companyearnings from './components/admin/Companyearnings';
import Admincommunication from './components/admin/Admincommunication';
import AddRole from './AddRole';
import EducationalPage from './EducationalPage';
import MyBankAccounts from './components/investor/MyBankAccounts';
import ApproveProjects from './components/admin/ApproveProjects';
import Setupprofile4 from './Setupprofile4';
import MicDeposits from './MicDeposits';
import Dwolapayment from './Dwolapayment';
import EntityAcc from './EntityAcc';
import Earnings2 from './components/investor/Earnings2';
import InvestmentOpportunities from './components/investor/InvestmentOpportunities';
import Login from './Login';
import Entity from './Entity';
import SelectedDeal from './components/shared/SelectedDealComponent/SelectedDeal';
import SelectedDeal2 from './components/shared/SelectedDealComponent/SelectedDeal';
import SelectedDealCF from './SelectedDealCF';
import Footer from './components/shared/NavigationManager/Footer/Footer';
import Content from './components/admin/AdminDashboardComponent/Content';
import Watchlist from './Watchlist';
import Profile from './components/investor/Profile';
import Notifications from './Notifications';
import Investments from './components/investor/Investments';
import PreviewProjects from './components/admin/PreviewProjects';
import InvestmentDetails from './components/investor/InvestmentDetails';
import Entityprofile from './Entityprofile';
import ViewProfile from './ViewProfile';
import PrivateRoute from './PrivateRoute';
import UploadDwollaDoc from './UploadDwollaDoc';
import View_regcf_education from './View_regcf_education';
// import ApproveProjectD from './ApproveProjectD';
import ApproveProjectCF from './ApproveProjectCF';
import SelectedProject from './SelectedProject';
import Distribution from './components/admin/Distribution';
import InvestorContent from './components/admin/InvestorContainer/InvestorContent';
import OperaterContent from './components/admin/OperaterContent';
import ResetPassword from './ResetPassword';
import User1 from './User1';
import User2 from './User2';
import RegVerified from './RegVerified';
import Adminaddproject_cf from './components/admin/Adminaddproject_cf';
import Adminaddproject_d from './components/admin/Adminaddproject_d';
import Admindealcard1 from './components/admin/Admindealcard1';
import ProjectNotification from './ProjectNotification';
import ProjectNotificationCF from './ProjectNotificationCF';
import InvestorNotifications from './components/investor/InvestorNotifications';
import RegCFProject from './RegCFProject';
import VerifyDwolla from './VerifyDwolla';
import CreateNewUserEntity from './CreateNewUserEntity';
import ApproveComments from './components/admin/ApproveComments';
import Refund from './components/admin/Refund';
import EditProjects from './components/admin/EditProjects';
import EditProjectD from './EditProjectD';
import EditProjectCF from './EditProjectCF';
import SupportPage from './SupportPage';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

// new components
import Icon from './components/shared/IconComponent/IconComponent';
import ProjectUpdate from './components/admin/ProjectUpdate/ProjectUpdateContainer';
import ProjectEditor from './components/admin/ProjectUpdate/ProjectUpdateEditorContainer';
import AllInvestmentsContainer from './components/shared/AllInvestmentsContainerComponent/AllInvestmentsContainer';
// css files
import './styles/App.css';
import './styles/CustomBootstrap3Headers.css';
import './styles/ApplicationMainNavigation.css';
import DistributeReturnOfCapitalContainer
    from './components/admin/RecordReturnOfCapital/DistributeReturnOfCapitalContainer';
import ProjectDetailsContainerComponent
    from './components/shared/ProjectDetailsContainerComponent/ProjectDetailsContainerComponent';
import PublicProjectDetailsComponent
    from './components/shared/ProjectDetailsContainerComponent/PublicProjectDetailsComponent';
import ProjectEditorContainerComponent
    from './components/shared/ProjectEditorContainerComponent/ProjectEditorContainerComponent';
import ProjectStatusContainerComponent
    from './components/admin/ProjectStatusContainerComponent/ProjectStatusContainerComponent';
import InvestorFilesContainerComponent
    from './components/shared/InvestorFilesContainerComponent/InvestorFilesContainerComponent';
import AllInvestorFilesContainerComponent
    from './components/admin/AllInvestorFilesContainerComponent.js/AllInvestorFilesContainerComponent';
import EmailHistoryContainerComponent
    from './components/shared/EmailHistoryContainerComponent/EmailHistoryContainerComponent';
import TabsComponent from './components/shared/TabsComponent';
import InvestorTrackingContainerComponent
    from './components/shared/InvestorTrackingContainerComponent/InvestorTrackingContainerComponent';
import { PROJECT_REG_CF, PROJECT_REG_D, TRACKING_BY_PROJECT_TYPE } from './utils/ApplicationConstants';
// import { getUUID } from './utils/General';
import HubSpotEmailImportComponent from './components/admin/HubSpotEmailImportComponent/HubSpotEmailImportComponent';
import AdminTransferFunds from './components/superadmin/TransferFunds';

import ShowProductionData from './components/shared/ShowProductionData';

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FiSettings } from "react-icons/fi";
import InvestorEarningsContainer from './components/shared/AllInvestmentsContainerComponent/InvestorEarningsContainer';
import InvestorEarnings from './components/investor/InvestorDashboardContainerComponent/InvestorEarnings';

import Messages from './components/investor/Messages';
import NewUsersDashboard from './components/investor/InvestorDashboardContainerComponent/NewUsersDashboard';
import WildcatPioneerFund from './components/investor/DrillingFund';
import BitcoinDiscoveryFund from './components/investor/BitcoinDiscoveryFund';

import { loadParallel } from '@parallelmarkets/vanilla';
import { ParallelProvider } from '@parallelmarkets/react';
import YieldFund2 from './components/investor/YieldFund2';
import AmericaFirstYieldFund1 from './components/investor/AmericaFirstEnergyFund1';
import InnovationFund1 from './components/investor/InnovationFund1';
import OpportunityFund1 from './components/investor/OpportunityFund1';
import OpportunityFundContactUs from './components/investor/OpportunityContactUs';


class TrackingByProjectType extends React.Component {
    TAB_LIST = [
        {
            number: 0,
            title: 'EnergyFunders Black (Reg D)',
        },
        {
            number: 1,
            title: 'EnergyFunders Marketplace (Reg CF)',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            defaultSelection: 0,
            selectedTab: 0,
        };
    }

    setSelectedTab = (selectedTab) => {
        this.setState({ selectedTab });
    };

    // RENDER MANAGER
    renderAllRegDTracking = () => {
        const { selectedTab } = this.state;

        if (selectedTab !== 0) {
            return null;
        }

        return (
            <InvestorTrackingContainerComponent
                getTrackingBy={TRACKING_BY_PROJECT_TYPE}
                projectType={PROJECT_REG_D}
                noModal
            />
        );
    };

    renderAllRegCFTracking = () => {
        const { selectedTab } = this.state;

        if (selectedTab !== 1) {
            return null;
        }

        return (
            <InvestorTrackingContainerComponent
                getTrackingBy={TRACKING_BY_PROJECT_TYPE}
                projectType={PROJECT_REG_CF}
                noModal
            />
        );
    };

    render = () => {
        const { defaultSelection } = this.state;

        return (
            <div className="col-xs-12 margin-top-30px">
                <TabsComponent
                    defaultSelection={defaultSelection}
                    setSelected={this.setSelectedTab}
                    tabList={this.TAB_LIST}
                />
                <div className="col-xs-12">
                    {this.renderAllRegDTracking()}
                    {this.renderAllRegCFTracking()}
                </div>
            </div>
        );
    };
}


const LandingPage = ({ match }) => (
    <div>
        <p style={{ 'font-size': '24px' }}>Loading, please wait ...</p>
        <a id="investorDashboardLink" href="/app/UserNotifications">
            <p style={{ 'font-size': '18px' }}>
Click Here to go
            to
            Investor Dashboard, if you are not redirected automatically.
            </p>
        </a>
        {(() => {
            window.location = '/app/InvestmentOpportunities';
        })()}
    </div>
);


const ProjectCF = ({ match }) => (
    <div>
        <RegCFProject id={match.params.id} />
    </div>
);








class newhome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Role: [],
            MainRole: 'Investor',
            userid: '',
            sideAction: true,
       
           
            
        };

        this.Login = this.Login.bind(this);
        this.onTimeout = this.onTimeout.bind(this);
        this.sidebarAction = this.sidebarAction.bind(this);
       
    }

    Login() {
        window.location = '/login';
    }

    sidebarAction() {
        this.setState({
            sideAction: !this.state.sideAction,
        }, () => {
            this.state.sideAction
                ? $('#app-body')
                    .removeClass('nav-sm')
                    .addClass('nav-md')
                : $('#app-body')
                    .removeClass('nav-md')
                    .addClass('nav-sm');
        });
    }

    async componentDidMount() {
     window.scrollTo(0, 0);
        await store.subscribe(() => {
            const currentState = store.getState();

            this.setState({
                userid: currentState.session.user.id,
               
            });
        });
        if(this.state.userid == ''){
        this.autoLogin();
        }
        
    //  let params = new URLSearchParams(this.props.location.search);
    //  console.log("in app.js to save outside condition", params?.get("invitecode"));
    //  if(!!params?.get('invitecode') && params?.get('invitecode') != ""){
    //     localStorage.setItem("invitecode", params?.get('invitecode'));
    //     console.log("invitecode",localStorage.getItem('invitecode'));
    //  }
      this.searchParams();
      this.redirectToInvestmentOpportunities();
 }

 autoLogin(){
     let params = new URLSearchParams(window.location.search);
    if(params.get('userID') && localStorage.getItem('autoredirect') == null ){
    window.location = `/login${window.location.search}`;
     this.setState({
        userid: params.get('userID'),
       
    });
    }
 }
	searchParams(){
  
   	const searchParams = new URLSearchParams(this.props.location.search);
		const utm_source = searchParams?.get("utm_source");
		const utm_medium = searchParams?.get("utm_medium");
		const utm_campaign = searchParams?.get("utm_campaign");
		const utm_term = searchParams?.get("utm_term");
		const utm_content = searchParams?.get("utm_content");

  const data = {
                utm_source:utm_source,
                utm_medium:utm_medium,
                utm_campaign:utm_campaign,
                utm_term:utm_term,
                utm_content:utm_content,
              }
              let utm_values = Object.values(data);
      
              if(utm_values.some(item => item!== null && item !== '')){
                sessionStorage.setItem('utm_codes',JSON.stringify(data));
              }
            
        
    }

    async onTimeout() {
        fetch(`/api/logout/${this.state.userid}`, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(() => {
                localStorage.clear();
                sessionService.deleteSession();
                sessionService.deleteUser()
                    .then(this.props.history.push('/login'))
                    .then(() => {
                        localStorage.clear();
                        sessionService.deleteSession();
                        sessionService.deleteUser();
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        console.log('timed out');
    }

        redirectToInvestmentOpportunities = ()=>{
            const pathname = window.location.pathname.toLowerCase();
            if(pathname?.match("/app/allinvestments")){

                window.location.href = "/app/InvestmentOpportunities"
            }
        }

    render() {
     const pathname = window.location.pathname.toLowerCase();
        return (
            <IdleTimer
                activeAction=""
                idleAction={this.onTimeout}
                timeout={1000 * 60 * 60}
                startOnLoad
                format="MM-DD-YYYY HH:MM:ss.SSS"
            >
                {(() => {
                    if (this.state.userid) {
                        return (
                            <>{pathname?.match("/app/regverified") || pathname?.match("app/verifydwolla") || pathname?.match("app/setupprofile3")  || pathname?.match("app/setupprofile4") ? <div className="d-flex flex-column ht-100">
                            {this.props.children}
                             </div> : <div className="app-main--container">
                                <div className="app-main--header">
                                <Adminnewheader sidebarActionHandler ={this.sidebarAction} sideAction ={this.state.sideAction}
                                        id={this.state.userid}
                                    /> 
                                </div>
                              <aside className="app-main--side-menu">
                                   <SidebarMenu
                                        role={this.state.MainRole}
                                        id={this.state.userid}
                                        sidebarAction={this.sidebarAction}
                                        showIcon={this.state.sideAction}
                                    />
                                </aside> 
                                <main className="app-main--content">
                                {/* <div
                                        className="collapse-sidebar-big-screen pointer"
                                        onClick={this.sidebarAction}
                                    >
                                        <Icon
                                            iconName={this.state.sideAction ? 'chevron-left' : 'chevron-right'}
                                            extraClasses="white-text"
                                        />
                                    </div> */}
                              <div
					className="collapse-sidebar-small-screen pointer"
					onClick={this.sidebarAction}
				>
				   {this.state.sideAction ? <IoIosArrowBack size={18} color="#ababab" /> :<IoIosArrowForward size={18} color="#ababab" />}	
                    
				</div>
                                 {this.props.children}
                                </main>
                                <Footer />
                            </div>}</>
                          
                   
                        );
                    }
        
                    return (
                        <div className="App">
                            <h2 style={{ marginTop: '20%' }}>
Your session has timed out.
                                <br />
                                <a onClick={this.Login} style={{ cursor: 'pointer' }}>Please login again.</a>
                            </h2>
                        </div>
                    );
                })()}
            </IdleTimer>
        );
    }
}

const EmailHistoryComponent = props => (
    <EmailHistoryContainerComponent
        showFilterOptions
        showInvestorData
        adminTitle="All Email History"
        investorId="all"
        {...props}
        isAdmin
    />
);

const parallel = loadParallel({ client_id: process.env.REACT_APP_PARALLEL_MICROPAYMENT_CLIENT_ID})
console.log("Parallel App.js: ", parallel);


const App = ({ session, authenticated, checked }) => (
    <ParallelProvider parallel={parallel}>
        <HubspotProvider>
    <Router>
        {checked
        && (
            <div>
                <Route path="/login" render={(props) => <Login {...props} isMarketplace={false} />} />
                <Route path="/register" render={(props) => <User1 {...props} isMarketplace={false} />} />
                 <Route path="/resendmail" render={(props) => <User2 {...props} isMarketplace={false} />} />
                <Route path="/emailverified/:id" render={(props) => <EmailVerified {...props} isMarketplace={false} isToken={true} />} />
                <Route path="/emailvalidate/:id" render={(props) => <EmailVerified {...props} isMarketplace={false} isToken={false} />} />

                <Route path="/marketplace/login" render={(props) => <Login {...props} isMarketplace={true} />} />
                <Route path="/marketplace/register" render={(props) => <User1 {...props} isMarketplace={true} />} />
                <Route path="/marketplace/resendmail" render={(props) => <User2 {...props} isMarketplace={true} />} />
                <Route path="/marketplace/emailverified/:id" render={(props) => <EmailVerified {...props} isMarketplace={true} isToken={true} />} />
                <Route path="/marketplace/emailvalidate/:id" render={(props) => <EmailVerified {...props} isMarketplace={false} isToken={false} />} />

                <Route path="/resetpassword/:id" render={(props) => <ResetPassword {...props} isMarketplace={false} />} />
                <Route path="/marketplace/resetpassword/:id" render={(props) => <ResetPassword {...props} isMarketplace={true} />} />
                {/* <Route path="/regverified" component={RegVerified} /> */}
                <Route path="/ProjectCF/:id" component={ProjectCF} />
                <Route path="/project/:projectId/:projectType" component={PublicProjectDetailsComponent} />
                <PrivateRoute authenticated={authenticated} exact path="/" component={LandingPage} />
             
                <Switch>
                    <NestedRoute path="/app" component={newhome}>
                        <SubRoute path="/Pastinvestments" component={Postinvestments} />
                        <SubRoute path="/MyBankAccounts" component={MyBankAccounts} />
                        <SubRoute path="/Messages" component={Messages} />
                        <SubRoute path="/educationpage" component={View_regcf_education} />
                        <SubRoute path="/admindashboard" component={Content} />
                        <SubRoute path="/sub3" component={EducationalPage} />
                        <SubRoute path="/entity" component={Entity} />
                        <SubRoute path="/deal2" component={SelectedDeal2} />
                        <SubRoute path="/deal" component={SelectedDeal} />
                        <SubRoute path="/project/details" component={ProjectDetailsContainerComponent} />
                        <SubRoute path="/dealCF" component={SelectedDealCF} />
                        <SubRoute path="/Investments" component={Investments} />
                        <SubRoute path="/Watchlist" component={Watchlist} />
                        <SubRoute path="/Allinvestments" component={AllInvestmentsContainer} />
                        <SubRoute path="/regverified" component={RegVerified} />
                        <SubRoute path="/Notifications" component={Notifications} />
                        <SubRoute path="/Profile" component={Profile} />
                        <SubRoute path="/Refund" component={Refund} />
                        <SubRoute path="/funderProfile" component={FunderProfile} />
                        <SubRoute path="/VerifyDwolla" component={VerifyDwolla} />
                        <SubRoute path="/createNewUserEntity" component={CreateNewUserEntity} />
                        <SubRoute path="/EducationalPage" component={EducationalPage} />
                        <SubRoute path="/adminHome" component={Fundraiserdealcard} />
                        <SubRoute path="/Myprojects" component={Fundraiserdealcard} />
                        <SubRoute path="/AdminaddProject_D" component={Adminaddproject_d} />
                        <SubRoute path="/AdminaddProject_CF" component={Adminaddproject_cf} />
                        <SubRoute path="/CompanyEarnings" component={Companyearnings} />
                        <SubRoute path="/AdminuserInvestments" component={Adminuserinvestments} />
                        <SubRoute path="/UserInvestments" component={Userinvestments} />
                        <SubRoute path="/Addrole" component={AddRole} />
                        <SubRoute path="/AdminProjectUpdate" component={ProjectUpdate} />
                        <SubRoute path="/AdminProjectUpdate/edit" component={ProjectEditor} />
                        <SubRoute path="/AdminProjectUpdate/create" component={ProjectEditor} />
                        <SubRoute path="/UserNotifications" component={InvestorNotifications} />
                        <SubRoute path="/AdminCommunication" component={Admincommunication} />
                        <SubRoute path="/invest" component={Invest} />
                        <SubRoute path="/invest2" component={Invest2} />
                        <SubRoute path="/invest3" component={Invest3} />
                        <SubRoute path="/invest4" component={Invest4} />
                        <SubRoute path="/adminapproveprojects" component={ApproveProjects} />
                        <SubRoute path="/adminapproveproject_cf" component={ApproveProjectCF} />
                        <SubRoute path="/editproject_d" component={EditProjectD} />
                        <SubRoute path="/editproject_cf" component={EditProjectCF} />
                        <SubRoute path="/viewprofile" component={ViewProfile} />
                        <SubRoute path="/investorDashboard" component={InvContent} />
                        <SubRoute path="/Earnings2" component={InvestorEarnings} />
                        <SubRoute path="/welcome" component={NewUsersDashboard} />
                        <SubRoute path="/InvestmentOpportunities" component={InvestmentOpportunities} />
                        {/* <SubRoute path="/drillingfund" component={WildcatPioneerFund} /> */}
                        {/* <SubRoute path="/yieldfund2" component={YieldFund2} /> */}
                        <SubRoute path="/americafirstyieldfund1" component={AmericaFirstYieldFund1} />
                        <SubRoute path="/innovationfund1" component={InnovationFund1} />
                        <SubRoute path="/opportunityfund1" component={OpportunityFund1} />
                        <SubRoute path="/opportunityFundContact" component={OpportunityFundContactUs} />
                        <SubRoute path="/Bitcoindiscoveryfund" component={BitcoinDiscoveryFund} />
                        <SubRoute path="/selectedproject" component={SelectedProject} />
                        <SubRoute path="/previewprojectinvestments" component={PreviewProjects} />
                        <SubRoute path="/investmentdetails" component={InvestmentDetails} />
                        <SubRoute path="/setupprofile" component={Entityprofile} />
                        <SubRoute path="/setupprofile2" component={EntityAcc} />
                        <SubRoute path="/setupprofile3" component={Dwolapayment} />
                        <SubRoute path="/setupprofile4" component={Setupprofile4} />
                        <SubRoute path="/verifymicrodeposits" component={MicDeposits} />
                        <SubRoute path="/investors" component={InvestorContent} />
                        <SubRoute path="/fundraisers" component={OperaterContent} />
                        <SubRoute path="/admindealcard1" component={Admindealcard1} />
                        <SubRoute path="/projectnotification" component={ProjectNotification} />
                        <SubRoute path="/projectnotificationCF" component={ProjectNotificationCF} />
                        <SubRoute path="/ApproveComments" component={ApproveComments} />
                        <SubRoute path="/EditProjects" component={EditProjects} />
                        <SubRoute path="/UploadDwollaDocument" component={UploadDwollaDoc} />
                        <SubRoute path="/distribute/return-of-capital" component={DistributeReturnOfCapitalContainer} />
                        <SubRoute path="/distribute/earnings" component={Distribution} />
                        <SubRoute path="/project/edit" component={ProjectEditorContainerComponent} />
                        <SubRoute path="/project/approve" component={ProjectEditorContainerComponent} />
                        <SubRoute path="/project/ProductionData" component={ShowProductionData} />
                        <SubRoute path="/admin/project/status" component={ProjectStatusContainerComponent} />
                    <SubRoute path="/investor/my-files" component={InvestorFilesContainerComponent} />
                        <SubRoute path="/admin/investor/documents" component={AllInvestorFilesContainerComponent} />
                        <SubRoute path="/admin/tracking" component={TrackingByProjectType} />
                        <SubRoute path="/investor/email/history" component={EmailHistoryContainerComponent} />
                        <SubRoute path="/admin/email/history" component={EmailHistoryComponent} />
                        <SubRoute path="/admin/hubspot/email/import" component={HubSpotEmailImportComponent} />
                        <SubRoute path="/superadmin/transferfunds" component={AdminTransferFunds} />
                        <SubRoute path="/support" component={SupportPage} />
                    </NestedRoute>
                </Switch>
            </div>
        )
        }
    </Router>
    </HubspotProvider>
    </ParallelProvider>
);

const { object, bool } = PropTypes;

App.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default connect(mapState)(App);

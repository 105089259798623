import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from '../shared/AllInvestmentsContainerComponent/actions/actions';
import swal from 'sweetalert2';

import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getImpersonateID } from "../../utils/General";
import RequestService from "../../api/RequestService";
import { PassportButton } from "@parallelmarkets/react";
import { withParallelMarketsHOC } from "./WithParallelMarketsHOC";
import HelloSignW9 from "../../utils/HelloSignW9";
import Icon from "../shared/IconComponent/IconComponent";
import moment from "moment";

class InvestmentOpportunities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showW9AndAccreditedModal:false,
            entityW9verified:false,
            entityThirdPartyAccredited:false,
            EntityID: '',
            Entities: [],
            notAccreditedEntities:[],
            isAccredited: false,
            numEntities: 0,
            fundingSources: [],
            default_gp_lp_election: 2,
            selectedEntityType: '',
            selectedEntityStatus: 'verified',
            selectedBankStatus: 'verified',
            selectedEntityW9Status: 0,
            selectedEntityFilesUploaded: true,
            selectedEntityController: 'Self',
            isEntityVerificationModalOpen: false,
            selectedEntityBOVStatus: 'verified',
            ContactPrefSMS: false,
			enterCodeModal: false,
			inviteCode: '',
			loading: false,
			codeError: false,
			OpportunityFundCodeVerified: false,
            isPersonalAccredited: true,
            entityDetails: {
                entityType: '',
                entityName: '',
                legalFirstName: '',
                legalLastName: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                phone: '',
                country: '',
            },

        };
    }

    async componentDidMount() {
        await this.checkIsAccredited();
        await this.checkVerified();
        await this.getOpportunityFundCode();

    }
    async getOpportunityFundCode(){
        const {data}  = await RequestService.get(`/api/getOpportunityFundCode`);
            let result = await data.result;
            console.log(result);
            let value = result?.find(i => i?.code == 'efoppy1'?.trim()?.toLowerCase());
            this.setState({OpportunityFundCodeVerified: !!value});
    }
    async checkInviteCode(){
		this.setState({loading: true});
		const { data } = await RequestService.post({
			url: `/api/checkAndAddOpportunityFundCode/${this.state.inviteCode}`,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
		});
		console.log(data);
		if(data?.result?.valid){
			this.setState({OpportunityFundCodeVerified: true,enterCodeModal:false, loading:false });

		}else{
			this.setState({codeError: true});
			this.setState({loading: false});
		}

	}
    async checkIsAccredited() {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/checkPersonalAccreditation/${impersonateID}`);
        let result = await data.result;
        console.log("reslt", result);
        this.setState({ isPersonalAccredited: result?.IsAccreditated })
    }
    async checkVerified() {
        const userInvestments = await this.getuserInvestedProjects();
        const investmentInDays = (date) => {
            let date_string = date?.toString();
            var expiration = moment(date_string).format("YYYY-MM-DD");
            var current_date = moment().format("YYYY-MM-DD");
            var days = moment(current_date).diff(expiration, 'days');
            console.log("InvestmentDate days ", days);
            return days;
        }
        const investments = userInvestments?.filter(i => investmentInDays(new Date(i?.InvestmentDate)) < 93)
        console.log("Checking", investments);
        const ent = await this.getInvestorEntities();
        if(investments?.length> 0){
        let uniqeEntityInvestment = await this.findUnique(investments, a => a.EntityID);
        let values = uniqeEntityInvestment.map(a => a.EntityID)
        console.log("ddd",values);
        console.log("entities", ent)
        const filterdNotVerified  = ent?.filter(o => values.includes(o.EntityID));
        console.log("filterdNotVerified",filterdNotVerified);
        this.setState({notAccreditedEntities: filterdNotVerified})
        if(uniqeEntityInvestment?.length > 0){
            let EntityId = uniqeEntityInvestment[0]?.EntityID;
            const {data}  = await RequestService.get(`/api/userEntityW9AndAccreditedCheck/${EntityId}`);
            let result = await data.result;
            console.log("reslt", result);
            let thirdpartyAcc = filterdNotVerified?.find(i => !(i?.IsThirdPartyAccredited > 0))
            let w9verifi = filterdNotVerified?.find(i => !(i?.w8n9formVerify > 0))
            if(!result.ThirdpartyAccredited > 0 || !result.w9Verified > 0){
                this.setState({
                    entityW9verified: !!w9verifi ? false : true,
                    entityThirdPartyAccredited: !!thirdpartyAcc ? false : true,
                    showW9AndAccreditedModal: (!!thirdpartyAcc || !!w9verifi) ? true : false,
                    EntityID: EntityId,
                })
            }
            
        }}
    }
    getInvestorEntities = async () => {
        try {
            const impersonateID = await getImpersonateID();
            const { data } = await RequestService.get(`/api/investor/profile/entities/${impersonateID}`);

            if (data.code === 200) {
                let numEntities = 0;
                data.rows.map(entity => {
                    if (entity.EntityID > 0 && entity.EntityController === 'Self')
                        numEntities++;
                });

                const fundingSources = await this.getInvestorFundingSource(data?.rows);
                this.setState({
                   //Both entity and finding source has status column. They are merged later on...
                   //status will be bank status, EntityStatus will be the tru status of entity
                   //Code defaults verified for non existing banks elsewhere
                    Entities: data.rows.map(r => ({...r, status: "verified", EntityStatus: r.status})),
                    isAccredited: data.isAccredited,
                    numEntities: numEntities,
                    fundingSources: fundingSources
                });
                return data.rows.map(r => ({...r, status: "verified", EntityStatus: r.status}));
            }
            else {
                alert('Could not get list of entities. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    async getInvestorFundingSource(entities) {
        const fundingSources = [];
        const EntityIDs = entities.map(entity => entity.EntityID);

        for (const entityId of EntityIDs) {
            const source = await this.performFundingSource(entityId);
            if (source?.length > 0) {
                let fundingSource = source?.map(item => ({ ...item, EntityID: entityId }))
                fundingSources.push(...fundingSource);

            }

        }

        return fundingSources;

    };
    async performFundingSource(entityId) {

        try {
            const impersonateID = await getImpersonateID();
            const { data: BankData } = await RequestService.post({
                url: `/api/getInvestorFundingSourceData/${impersonateID}`,
                data: {
                    entityId,
                },
            });
            return BankData;
        } catch (e) {

            console.log(e)
        }

    }
    async getuserInvestedProjects() {
        try {
            const  {data}  = await RequestService.get('/api/currentUserInvestedList');
            return data;
        }
        catch (err) {
            console.log(err);
        }
    }
    findUnique(arr, predicate) {
  var found = {};
  arr.forEach(d => {
    found[predicate(d)] = d;
  });
  return Object.keys(found).map(key => found[key]); 
}

onSelectEntity = async (EntityID) => {

    for (let i = 0; i < this.state.Entities.length; i++) {
        if (this.state.Entities[i].EntityID === Number(EntityID)) {


            await this.setState({
                EntityID: EntityID,
                default_gp_lp_election: this.state.Entities[i].default_gp_lp_election,
                selectedBankStatus: this.state.Entities[i].status,
                selectedEntityBOVStatus: this.state.Entities[i].DwollaBOVerificationStatus,
                selectedEntityStatus: this.state.Entities[i].EntityStatus,
                selectedEntityW9Status: this.state.Entities[i].w8n9formVerify,
                selectedEntityId: Number(EntityID),
                selectedEntityType: this.state.Entities[i].EntityType,
                selectedEntityFilesUploaded: this.state.Entities[i].filesUploaded,
                selectedEntityController: this.state.Entities[i].EntityController,
                ContactPrefSMS: this.state.Entities[i].ContactPrefSMS,
                entityDetails: {
                    ...this.state.entityDetails, entityType: this.state.Entities[i].EntityType,
                    entityName: this.state.Entities[i].EntityName, legalFirstName: this.state.Entities[i].LegalFirstName,
                    legalLastName: this.state.Entities[i].LegalLastName, address: this.state.Entities[i].Address,
                    city: this.state.Entities[i].City, state: this.state.Entities[i].State,
                    zipCode: this.state.Entities[i].ZipCode, phone: this.state.Entities[i].Phone,
                    country: this.state.Entities[i].Country,
                }

            });
            this.signW9();
            break;
        }
        // Selected 'nothing'... Reset values to default.
        else {
            await this.setState({
                EntityID: '',
                default_gp_lp_election: 2,
                selectedEntityType: '',
                selectedBankStatus: 'verified',
                selectedEntityBOVStatus: 'verified',
                selectedEntityStatus: 'verified',
                selectedEntityW9Status: 1,
                selectedEntityId: '',
                selectedEntityFilesUploaded: true,
                isEntityVerificationModalOpen: false,
                ContactPrefSMS: false,
            },

            );
        }
    }
};
signW9 = async () => {
    const { history } = this.props;
	console.log("signW9", )
    if (this.state.selectedEntityStatus === 'verified') {
        if (this.state.selectedEntityType === 'Personal' && this.state.selectedEntityController === 'Self') {
            await HelloSignW9({ call: 'signW9Personal', EntityID: this.state.EntityID });
        }
        if (this.state.selectedEntityType === 'Personal' && this.state.selectedEntityController === 'FA') {
            await HelloSignW9({ call: 'signW9OtherPersonal', EntityID: this.state.EntityID });
        }
        else {
            await HelloSignW9({ call: 'signW9Entity', EntityID: this.state.EntityID });
        }
    }
    else {
        swal({
            type: 'warning',
            title: 'You will need to complete Investor Identity Verification first.',
            showConfirmButton: true
        })
        // .then(history.push({ pathname: '/app/VerifyDwolla' }));

    }
};
    render() {
        const yieldBG = "/img/investmentopportunities/yield-bg.png"
        const wildcatBG = "/img/investmentopportunities/wilcat-bg.png"
        const bitcoinBG = "/img/investmentopportunities/bitcoin-bg.png";
        const expiryDays = (date)=> {
            let date_string = date?.toString();
            var expiration = moment(date_string).format("YYYY-MM-DD");
            var current_date = moment().format("YYYY-MM-DD");
            var days = moment(expiration).diff(current_date, 'days');
            console.log("remaining days",days);
            return days;
          }
          const {fundingSources, notAccreditedEntities } = this.state;
        const addbankAccountToEntities = notAccreditedEntities.map((item, i) => Object.assign({}, item, fundingSources.find(f => f.EntityID === item.EntityID) ?? {}));
console.log("invested proj", this.state );
        return (

            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${yieldBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer">
                                    <h3>America First Energy Fund I</h3>
                                    <p>Just this year, America became the number one LNG exporter in the world, driven by record shipments to Europe. Over the next decade, billions of dollars in new U.S. export facilities will link domestic U.S. gas prices to much-higher prices in Europe and other international markets. In response to this, EnergyFunders presents a fund built to capitalize on surging American energy exports. 
                                        <br />
                                        <br />The America First Energy Fund I contains a set of carefully curated assets. Unlike our previous funds, the EnergyFunders team has pre-selected these diverse opportunities specifically for this fund. Our fund raise will be capped at $20MM so be sure to make your investment today!
                                    </p>
                                </div>
                                <div className="col-md-4 col-lg-3 spacer ">
                                    <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                                Periodic Distributions
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                                Lower Risk Level
                                            </span>
                                        </li>
                                        {/* <li>
                                                <img src="/img/money.svg" />
                                                <span>
                                                    Targeting IRRs of 15 - 30%
                                                </span>
                                            </li> */}
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/americafirstyieldfund1")}>
                                        View Offering
                                    </button>
                                </div>
                            </div>

                        <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${yieldBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer">
                                    <h4>Latest Investment Offering</h4>
                                    <h3>Innovation Fund I</h3>
                                    <h4> Investing in new energy tech</h4>
                                    <p>
                                    The mission of the new Innovation Fund model is to find and fund startups with innovative technologies that shake up the status quo and solve real-world problems. As an energy industry disrupter with an established digital platform, EnergyFunders is inviting its investors to be a part of the revolutionization of the car care industry through its first Innovation Fund offering.
                                    <br/>
                                    <br />
                                    By participating in Innovation Fund I, you will be able to diversify your portfolio by investing in innovative energy technologies, like Paleo Car Care, that have established revenue and a defined roadmap for growth.
                                    </p>
                                </div>
                                <div className="col-md-4 col-lg-3 spacer ">
                                    <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                            IPO or Flip Potential Trajectory
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                            Higher Risk Level
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/innovationfund1")}>
                                        View Offering
                                    </button>
                                </div>
                            </div>

                        <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${yieldBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer" style={{alignSelf:'normal'}}>
                                    <h4>Latest Investment Offering</h4>
                                    <h3>Opportunity Fund I</h3><h4> A bespoke fund specially curated to meet the needs of high net worth investors</h4>
                                    {this.state.OpportunityFundCodeVerified ? <p><p className="mb-1">A Balanced Risk/Reward Capital Allocation </p>
                                    <p className="mb-1">EnergyFunders Opportunity Fund I offers venture capital-like upside within an investment vehicle that aims to minimize downside risk.</p>
                                    <p className="mb-1">Access to:</p>
                                    <ul className="pl-4">
                                        <li>Higher-potential opportunities from selected exploratory prospects </li>
                                        <li>Income-generating assets from wells already on production</li>
                                        <li>A source of inflation-protection from exposure to income streams tied to oil and gas commodity prices, historically one of the best inflation hedges available</li>
                                        <li>Potential, 100% tax deductions from intangible drilling costs in the year of occurrence, plus additional tax deductions from tangible drilling costs through depreciation up to seven years</li>
                                    </ul>
                                    </p> : <p>This fund is <b>by invitation only</b>
                                    <p className="pt-2">If you already have a code, click <a className="text-green pointer" onClick={()=>this.setState({enterCodeModal: true}) }>here</a> to enter it</p></p>}
                                </div>
                                <div className="col-md-4 col-lg-3 spacer ">
                                    {this.state.OpportunityFundCodeVerified ? <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                                Periodic Distributions
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                            Balanced Risk Level
                                            </span>
                                        </li>
                                    </ul> : null}
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/opportunityfund1")}>
                                        View Offering
                                    </button>
                                </div>
                            </div>

                            {/* <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${wildcatBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer">
                                    <h4>Latest Investment Offering</h4>
                                    <h3>Yield Fund II</h3>
                                    <p>Following the success of the inaugural Yield Fund I, the EnergyFunders team has officially launched the second act – Yield Fund II. This fund will provide investors with diversified exposure to lower-risk proven reserves opportunities, targeting favorable returns across more than a dozen investments. Investors will get exposure to the unique benefits of direct oil and gas drilling, including possible inflation-protection and an asset class insulated against stock market volatility. Plus, investors can access opportunities to potentially take advantage of some of the most favorable tax deductions in the U.S. tax code.
                                        <br />
                                        <br />This unique asset class brings distinct challenges requiring deep technical expertise in geology, engineering, and finance.
                                    </p>
                                </div>
                                <div className="col-md-4 col-lg-3 spacer ">
                                    <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                                Periodic Distributions
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                                Lower Risk Level
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/yieldfund2")}>
                                        View Offering
                                    </button>
                                </div>
                            </div> 
                            
                            <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${wildcatBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer">
                                    <h4>Closing Soon</h4>
                                    <h3>Drilling Fund I LLC</h3>
                                    <p>The Drilling Fund will deploy capital into drilling new oil and gas wells. We aim to limit the risk associated with any single well by investing across a diversified collection of wells.
                                        <br />
                                        <br />The majority of Fund capital will go towards developing proven reserves. These drilling targets are located near existing productive wells. This provides the critical data needed for our team of oil and gas professionals to analyze the geology and reservoir characteristics of offset drilling locations. The Fund will also allocate capital towards the development of unproven reserves, also known as “wildcat” wells. These higher-risk drilling locations can offer some of the best returns you'll find in the oil patch. We will opportunistically invest in wildcat prospects where we believe the potential upside justifies the added risk involved.
                                    </p>
                                </div>
                                <div className="col-md-4 col-lg-3 spacer ">
                                    <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                                Periodic Distributions
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                                Moderate Risk Level
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/drillingfund")}>
                                        View Offering
                                    </button>
                                </div>
                            </div> */}

                            <div className="bg-dark-blue invest-opportunities my-4">
                                <div className="col-lg-3 img-wrappper pl-0" style={{ backgroundImage: `url(${bitcoinBG})` }} >

                                </div>
                                <div className="col-md-5 col-lg-4 spacer">
                                    <h4>Closing Soon</h4>
                                    <h3>Bitcoin Discovery Fund</h3>
                                    <p>
                                        The Bitcoin Discovery Fund is built
                                        to deliver you Bitcoin, or fractions
                                        of a Bitcoin, at a targeted cost basis below the market price. You
                                        have the option of accumulating your
                                        share of mined Bitcoin, or selling
                                        into the market as you receive them.

                                        <br />
                                        <br /> Our mobile mining approach benefits
                                        from cost-advantaged electricity,
                                        produced from natural gas sourced
                                        directly at the wellhead. This
                                        enables higher profit margins, while
                                        also providing a stable source of
                                        electricity supply, independent of
                                        the grid.
                                    </p>
                                </div>
                                <div className="col-md-4 col-lg-3 spacer">
                                    <ul className="list">
                                        <li>
                                            <img src="/img/time.svg" />
                                            <span>
                                                {" "}
                                                3 – 5 Year Targeted Fund
                                                Life
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/piechart.svg" />
                                            <span>
                                                Periodic Distributions
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/img/risklevel.svg" />
                                            <span>
                                                High Risk Level
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0">
                                    <button className="btn btn-primary btn-green px-5 py-4 ml-3" onClick={() => this.props.history.push("/app/Bitcoindiscoveryfund")}>
                                        View Offering
                                    </button>
                                </div>
                            </div>

                            {/* yield fund II */}


                            {/* <div className="bg-dark-blue invest-opportunities  coming" >
                                    <span className="coming-soon btn-green">Coming mid-year 2022</span>
                                    <div className="col-lg-3 img-wrappper pl-0"  style={{backgroundImage: `url(${yieldBG})`}}>
                            
                                        </div>
                                    <div className="col-md-5 col-lg-3 spacer">
                                        <h3>Yield Fund</h3>
                                        <p>
                                            The Yield Fund invests into
                                            lower-risk proven oil and gas
                                            reserves. This includes both
                                            development wells and
                                            already-producing wells.
                                            <br />
                                            <br /> This Fund is designed to
                                            generate a stable income, while also
                                            growing your investment capital over
                                            time. Investors also enjoy the
                                            opportunity for lucrative tax
                                            available from direct oil and gas
                                            investing.
                                        </p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 spacer">
                                        <ul className="list">
                                            <li>
                                                <img src="/img/time.svg" />
                                                <span>
                                                    {" "}
                                                    3 – 5 Year Targeted Fund
                                                    Life
                                                </span>
                                            </li>
                                            <li>
                                                <img src="/img/piechart.svg" />
                                                <span>
                                                    Periodic Distributions
                                                </span>
                                            </li>
                                            <li>
                                                <img src="/img/risklevel.svg" />
                                                <span>
                                                    Low to Moderate Risk Level
                                                </span>
                                            </li>
                                            <li>
                                                <img src="/img/money.svg" />
                                                <span>
                                                    Targeting project-level IRRs
                                                    of 15 – 40%
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3 col-lg-3 mt-3 mt-lg-0 d-flex justify-content-end flex-wrap spacer px-0 ">
                                        <button className="btn btn-primary btn-green px-5 py-4 "  onClick={() =>
                  window.open(`${process.env?.REACT_APP_Website_URL}/yieldfund`, "_blank")
                }>
                                            View Offering
                                        </button>
                                    </div>
                                </div> */}
                        </div>

                    </div>
                </div>
                <Modal
            onHide={()=> this.setState({enterCodeModal:false})}
                    show={this.state.enterCodeModal}
                    style={{ width: '40%', margin: '0 auto', top:"25%" }} className="gplp-voting-modal"
                >

                        <Modal.Body className="text-center">
						<h3>Please Enter Your Invite Code:</h3>
         <input type="text" style={{borderRadius:'5px', borderColor: this.state.codeError ? 'red' : 'limegreen'}} value={this.state.inviteCode} onChange={(e)=> this.setState({inviteCode: e.target.value})} name="inviteCode" />
         <br />
         {this.state.codeError && <p className="text-danger">Invalid Code</p>}
      <button className="btn  green-bg hover-light text-white rounded-0 mt-2 py-2 px-4" disabled={this.state?.loading} onClick={()=>{console.log("Invite");console.log("e.target", this.state.inviteCode);this.checkInviteCode();}}>{this.state.loading ?' Please Wait...' : 'Verify'}</button>
						</Modal.Body>
						</Modal>
                <Modal
            onHide={()=> this.setState({showW9AndAccreditedModal:false})}
                    show={this.state.showW9AndAccreditedModal}
                    style={{ margin: '0 auto', top:"25%" }} className="gplp-voting-modal modalWidth"
                >

                        <Modal.Body className="text-center">
                        <div className="font-weight-bold">
                        Please complete the following pending step(s) after you invest to finalize your investment:
            
        </div>
        <div className=" pt-5"> 
            <div >
            <div className="content">
                                            {addbankAccountToEntities?.filter(i => !(i.IsThirdPartyAccredited > 0) || !(i.w8n9formVerify > 0))?.map((entity) => (
                                                <div className='table-wrapper' style={{overflow: 'auto'}}>
                                                    <table className="table">
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <h3 className="m-0 text-left">{entity.EntityName}</h3>
                                                            </td>
                                                        </tr>
                                                        {entity.w8n9formVerify === 0 && <tr>
                                                            {/* <td style={{verticalAlign:'baseline'}}>Bank Name</td> */}
                                                        <td style={{verticalAlign:'baseline'}}>W9 Filled</td>
                                                        {/* <td style={{verticalAlign:'baseline'}}>Entity Verified</td> */}
                                                        <td><span>
                                                                {entity.w8n9formVerify === 0 ? (
                                                                    <button className="btn btn-default btn-link font-bold" onClick={() => this.onSelectEntity(entity.EntityID)}>
                                                                        Fill W9 Now
                                                                    </button>
                                                                ) : (
                                                                    "Yes"
                                                                )} </span>
                                                            </td>
                                                        </tr>}
                                                        {!(entity?.thirdPartyExpiry && expiryDays( entity?.thirdPartyExpiry ) >= 0) && <tr>
                                                            <td style={{verticalAlign:'baseline'}}>Accreditation Status {" "}
                                                        <OverlayTrigger
																placement="bottom"
																overlay={
																	<Tooltip id={"infoboot"}>
																		<div style={{
																			padding: 5,
																			fontSize: '1.2em'
																		}}>Verify your accreditation status through our partner, Parallel Markets</div>
																	</Tooltip>}>
																<a><Icon iconName="info-circle" extraClasses="infoboot"  /></a>
															</OverlayTrigger>
                                                            </td>
                                                            <td>
                                                            {entity?.thirdPartyExpiry && expiryDays( entity?.thirdPartyExpiry ) >= 0 ? <div className="font-bold"> Yes </div> :
                                                            <> 
                                                            <div className="btn btn-link pt-1" onClick={() =>this.props.history.push('/app/Profile')}>Visit Account Settings to Begin</div>
                                                            </>  }
                                                                    
                                                                    </td>
                                                            </tr>}
                                                            {/* <div className=""> Get Accredited through our partner Parallel Markets</div> */}
                                                            {/* <span className="font-normal">Please click the Parallel Passport button below to begin the accreditation verification process. </span>
                                                            <div className="btn btn-link pt-1"> <PassportButton showCancelButton={true} /></div>  */}
                                                            {/* <span>Please begin the accreditation verification process from Account Settings </span> */}
                                                            {/* <td>
                                                                <span>
                                                                    {entity?.bankName?.length > 1 ?
                                                                        entity.bankName
                                                                        : (
                                                                            <Link to="/app/setupprofile3" className='cursor-pointer font-bold'>
                                                                                Add New Bank Account
                                                                            </Link>
                                                                        )}</span>
                                                                {entity.status?.toLowerCase() !== 'verified' &&
                                                                    <Link to="/app/verifymicrodeposits" className='cursor-pointer font-bold'>
                                                                        Verify Micro Deposits
                                                                    </Link>
                                                                }

                                                            </td> */}

                                                            
                                                            {/* <td><span>
                                                                {entity.EntityStatus?.toString().toLowerCase() === "verified" ? "Yes" : entity.EntityStatus?.toString().toLowerCase() === "document" ? (<button
                                                                    className="btn btn-default btn-link font-bold"
                                                                    onClick={() => window.location = '/app/UploadDwollaDocument'}
                                                                    type="button"
                                                                >
                                                                    Upload Entity Verification File(s) (Required)
                                                                </button>) : (<button onClick={() =>
                                                                    this.toggleVerificationModal(entity)
                                                                } className="btn btn-default btn-link font-bold">
                                                                    Verify this Entity
                                                                </button>)}</span></td> */}
                                                            {/* accreditation */}

                                                                
                                                            {/* // <div className={expiryDays( entity?.thirdPartyExpiry ) <= 7 ? 'text-danger font-bold' : 'text-green font-bold' } style={{textDecoration:'none'}}>Accreditation expires at {moment(entity?.thirdPartyExpiry).format("YYYY-MM-DD")} </div> 
                                                            // : entity?.thirdPartyExpiry && expiryDays( entity?.thirdPartyExpiry ) <= 0 ? <div className="btn-link font-bold"> Accreditation expired on {moment(entity?.thirdPartyExpiry).format("YYYY-MM-DD")} </div> :  */}

                                                            {/* accreditation */}
                                                       
                                                    </table>
                                                </div>
                                            ))}</div>
                {/* <Button className="btn px-5 py-3 btn-green pt-1 rounded" onClick={() => this.onSelectEntity(this.state.EntityID)}>Fill W9</Button> */}
                </div>
                {/* <div className="btn btn-link pt-1"><PassportButton showCancelButton={true} /></div> */}
        </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button onClick={()=> this.setState({showW9AndAccreditedModal:false})}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                <Modal
                    onHide={() => this.setState({ isPersonalAccredited: true })}
                    show={!this.state.isPersonalAccredited}
                    style={{ width: '40%', margin: '0 auto', top: "25%" }} className="gplp-voting-modal"
                >

                    <Modal.Body className="text-center">
                        <h4>Unaccredited Investors may purchase shares in certain EnergyFunders offerings through the tZERO platform.  Create an account here: </h4>

                        <br />
                        <a href="https://www.tzero.com/investors/register" className="btn  green-bg hover-light text-white rounded-0 mt-2 py-2 px-4" target="_blank" rel="noopener noreferrer">{'tZERO Markets | Create Account'}</a>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({ isPersonalAccredited: true })}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const { object, bool } = PropTypes;

InvestmentOpportunities.propTypes = {
    session: object.isRequired,
    allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
    const allInvestmentActions = bindActionCreators(AllInvestmentActions, dispatch);
    return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
    session: _state.session.user,
    allInvestments: _state.allInvestments,
});



const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestmentOpportunities);

export default withRouter(withParallelMarketsHOC(connectedComponent));




import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Wildcat_Project_ID } from '../../utils/General';

class WildcatPioneerFund extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			selectedProjectCategory: "all",
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0, 0);
	}

	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
		const documentIcon = "/img/document.svg";
		const ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		const wildcatFundOverview = "/img/investmentopportunities/wildcat-fund-overview.png";
		const wildcatFundSummary = "/img/investmentopportunities/wildcat-fund-summary.png";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>Drilling Fund I LLC</h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
								<p>
									The Drilling Fund will deploy capital into drilling new oil and gas wells. We aim to limit the risk associated with any single well by investing across a diversified collection of wells.
									<br />
									<br />The majority of Fund capital will go towards developing proven reserves. These drilling targets are located near existing productive wells. This provides the critical data needed for our team of oil and gas professionals to analyze the geology and reservoir characteristics of offset drilling locations.
									<br />
									<br />The Fund will also allocate capital towards the development of unproven reserves, also known as “wildcat” wells. These higher-risk drilling locations can offer some of the best returns you'll find in the oil patch. We will opportunistically invest in wildcat prospects where we believe the potential upside justifies the added risk involved.
								</p>
							</div>
							<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-left py-3 px-3">
												<ReactSVG src="/img/investmentopportunities/oil.svg" />
												<span className="pl-4">
													100% Oil &amp; Gas
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={() => this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Wildcat_Project_ID,

												}
											})

											}
										>
											Start Investing Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>3-5 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>Periodic</span> Payments
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>Moderate</span> Risk Level
								</li>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/chart.svg" />
									<span></span>
								</li> */}
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$5,000</span> Minimum Investment
								</li>

								<li>
									<ReactSVG src="/img/investmentopportunities/taxes.svg" />
									<span>Tax Savings</span> Potential
									Deductions Against Active Income
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/exclusive.svg" />
									<span>Exclusive Access</span> Private Market
									Opportunities
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							<div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												3.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$500,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-5">
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								<h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 15%. And second, 80% to Investors
									and 20% to the Manager.
								</p>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4"><a href="/documents/drillingfund/LLC-Agreement-EnergyFunders-Drilling-Fund-I-LLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4"><a href="/documents/drillingfund/disclosure-document-energy-funders-drilling-fund-i-llc-final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4"><a href="/documents/drillingfund/LLC-Agreement-EnergyFunders-Drilling-Fund-I-LLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${wildcatFundSummary})` }}></div>
									<h3>Drilling Fund I LLC Two-page Summary</h3>
									<div className="mt-4"><a href="/documents/drillingfund/drillingfund-2-pager.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
							</ul>
						</div>
						{/* <OurTeam /> */}
						<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
							<div className="pull-left d-flex align-items-center left-sec"><ReactSVG src={ProjectUpdatesIcon} /> <span>Drilling Fund I LLC</span></div>
							<div className="pull-right rt-sec"><button className="btn btn-primary  px-5 py-4 ml-3 font-bold" onClick={() => this.props.history.push({
								pathname: '/app/invest',
								state: {
									ProjectType: 'RegD',
									ProjectID: Wildcat_Project_ID,

								}
							})

							}>
								Start Investing Today
							</button></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

WildcatPioneerFund.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(WildcatPioneerFund);

export default withRouter(connectedComponent);

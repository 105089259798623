import React from 'react';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

const HubspotFormComponent = (props) => {
    const { loaded, error, formCreated } = useHubspotForm({
        portalId: "2935563",
    formId: props?.form_id,
    target: "#my-hubspot-form"
    });
// console.log("form id", props?.form_id);
    return (
        <div>
            <div id="my-hubspot-form"></div>
        </div>
    )
}

export default HubspotFormComponent;
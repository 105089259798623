import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import RequestService from "../../api/RequestService";
import HubspotFormComponent from "./hubspotFormComponent";


class OpportunityFundContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            enterCodeModal: false,
            OpportunityFundCodeVerified: false,
            selectedProjectCategory: "all",
            inviteCode: '',
            loading: false,
            codeError: false,
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let a = await this.getOpportunityFundCode();
        if (a) {
            console.log("Opportunity Code Verified");
        } else {
            this.props.history.push("/app/opportunityfund1")
        }

    }
    async getOpportunityFundCode() {
        const { data } = await RequestService.get(`/api/getOpportunityFundCode`);
        let result = await data.result;
        console.log(result);
        let value = result?.find(i => i?.code == 'efoppy1'?.trim()?.toLowerCase());
        this.setState({ OpportunityFundCodeVerified: !!value });
        return !!value;
    }
    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    <div
                        className="right_col invest-opportunities-detail"
                        role="main"
                    >

                        <div className="col-md-12 col-sm-12 col-xs-12 px-0">

                            {this.state.OpportunityFundCodeVerified ? <div className="container">
                                <div className="d-flex justify-content-center ">

                                    <div className="ps-md-5 col-md-12 col-lg-8 text-center justify-content-center position-relative rt-sec">
                                        <h2 style={{ fontSize: "2.6rem" }}>Request to Join The Opportunity Fund I</h2>
                                        <p>
                                            If You{"'"}d like to be part of the Opportunity Fund I, fill out the form below and a member of our team will contact you.
                                        </p>
                                        <div className="mt-5">
                                            <HubspotFormComponent form_id="1f071a7f-ed8f-49a9-9a01-b8328da65e13" />
                                        </div>
                                    </div>
                                </div>
                            </div> : <div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const { object, bool } = PropTypes;

OpportunityFundContactUs.propTypes = {
    session: object.isRequired,
    allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
    const allInvestmentActions = bindActionCreators(
        AllInvestmentActions,
        dispatch
    );
    return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
    session: _state.session.user,
    allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpportunityFundContactUs);

export default withRouter(connectedComponent);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestService from '../../../../../api/RequestService';
import SelectComponent from '../../../../shared/InputComponents/SelectComponent/SelectComponent';
import CheckboxInputComponent from '../../../../shared/InputComponents/CheckboxInputComponent/CheckboxInputComponent';
import CollapsiblePanelComponent from '../../../../shared/CollapsiblePanelComponent/CollapsiblePanelComponent';
import SingleLineInputComponent
    from '../../../../shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import DateInputComponent from '../../../../shared/InputComponents/DateInputComponent/DateInputComponent';
import moment from 'moment';
import Icon from '../../../../shared/IconComponent/IconComponent';
import NewEntityContainerComponent from '../../../../shared/NewEntityContainerComponent/NewEntityContainerComponent';

import NewBankAccountContainerComponent from '../../../../shared/NewEntityContainerComponent/NewBankAccountContainerComponent';
import HideComponent from '../../../../shared/HideComponent/HideComponent';
import sweetAlert from 'sweetalert2'
import { DATE_FORMAT_MDY_HIS } from '../../../../../utils/General'

import { numberMaskify } from '../../../../../utils/General';
import swal from 'sweetalert2';
import ReceiveOnlyEntityModal from './ReceiveOnlyEntityModal';


class InvestorEntityViewerContainerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.UserID,

            Entity: [],
            EntityDetails: [],
            selectedEntity: 0,
            ThirdPartyAccreditationCertifiedAt: null,
            ThirdPartyAccreditationExpiresAt: null,
            ThirdPartyAccreditationStatus: '',
            ThirdPartyAccreditationUpdateNote: '',
            noteError: false,
            EntityAccreditation: [],
            EntityAccreditationDetails: [],
            selectedEntityAccreditation: 0,
            tpanotes: [],

            isNewEntityModalOpen: false,
            isReceiveOnlyModalOpen: false,
            isNewBankAccountModalOpen: false,
            isAccreditationModalOpen: false,
            fundingSources: [],
            EntityTaxIDCollection: {
                SSN: "",
                EIN: "",
                TaxClassification: "",
                Notes: ""
            },

        };
    }



    componentDidMount() {
        window.scrollTo(0, 0);
        this.getEntityName();
    }



    toggleNewEntityModal = () => {
        const { isNewEntityModalOpen } = this.state;

        this.setState({ isNewEntityModalOpen: !isNewEntityModalOpen });
    };
    toggleReceiveOnlyEntityModal = () => {
        const { isReceiveOnlyModalOpen } = this.state;

        this.setState({ isReceiveOnlyModalOpen: !isReceiveOnlyModalOpen });
    };


    toggleNewBankAccountModal = () => {
        const { isNewBankAccountModalOpen } = this.state;

        this.setState({ isNewBankAccountModalOpen: !isNewBankAccountModalOpen });
    };


    // Example of setState Function
    viewAccreditationInfo = () => {
        this.setState(prevState => ({ isAccreditationModalOpen: !prevState.isAccreditationModalOpen }));

        // Also written like this (long form):
        /*
        this.setState((prevState, props) => {
            return { isAccreditationModalOpen: !prevState.isAccreditationModalOpen }
        });
         */
    };




    onCloseAccreditationModal = () => {
        this.setState({ isAccreditationModalOpen: !this.state.isAccreditationModalOpen });
    };



    getEntityName = async () => {
        try {
            const { data } = await RequestService.get(`/api/getAllInvestorEntities/${this.state.userId}`);

            if (data && data.length === 1) {
                const { EntityID } = data[0];

                this.getEntityByEntityID(EntityID);
                const fundingSources = await this.performFundingSource(EntityID);
                this.getEntityTaxIDCollection(EntityID);
                this.setState({ selectedEntity: EntityID, fundingSources: fundingSources });
            }

            this.setState({ Entity: data });
        }
        catch (err) {
            console.log(err);
        }
    };



    getEntityAccreditationList = async (EntityID) => {
        try {
            const { data } = await RequestService.get(`/api/getEntityAccreditationList/${EntityID}`);
            const { EntityAccreditationProfileID } = data[0];
console.log("EntityAccreditationProfileID",EntityAccreditationProfileID);
            this.getEntityAccreditationByID(EntityAccreditationProfileID);
            const {data: dd} = await RequestService.get(`/api/getAdminUpdateNotes/thirdpartyaccreditation/${EntityAccreditationProfileID}`)
            console.log("accreditation ddd", dd);
            this.setState({tpanotes: dd});
            this.setState({
                selectedEntityAccreditation: EntityAccreditationProfileID,
                EntityAccreditation: data
            });
        }
        catch (err) {

        }
    };

    getEntityTaxIDCollection = async (EntityID) => {
        try {
            const { data } = await RequestService.get(`/api/getEntitytaxID/${EntityID}`);

            if (data.code === 200) {
                let EntityTaxIDCollection = data?.result[0];
                if (EntityTaxIDCollection) {
                    this.setState({ EntityTaxIDCollection: EntityTaxIDCollection });
                }
                else {
                    this.setState({
                        EntityTaxIDCollection: {
                            SSN: "",
                            EIN: "",
                            TaxClassification: "",
                            Notes: ""
                        }
                    });
                }

            }

        }
        catch (err) {

        }
    };

    updateEntityTaxIDCollection = async () => {
        const { EntityTaxIDCollection, EntityDetails } = this.state;
        const { EntityID } = EntityDetails;
        let formBody = [];
        formBody.push("SSN=" + encodeURIComponent(EntityTaxIDCollection?.SSN));
        formBody.push("EIN=" + encodeURIComponent(EntityTaxIDCollection?.EIN));
        formBody.push("TaxClassification=" + encodeURIComponent(EntityTaxIDCollection?.TaxClassification));
        formBody.push("Notes=" + encodeURIComponent(EntityTaxIDCollection?.Notes));

        formBody = formBody.join("&");


        const { data } = await RequestService.post({
            url: `/api/updateEntitytaxID/${EntityID}`,
            data: formBody
        });

        if (data.code === 200) {
            sweetAlert("Tax Details Updated", "", "success");
            this.getEntityTaxIDCollection(EntityID);

        }
    }

    onHandleEntityChange = async (event) => {
        const { name, value } = event.target;

        // console.log('asdf = ', event, name, value);

        this.getEntityByEntityID(value);
        this.getEntityTaxIDCollection(value);
        const fundingSources = await this.performFundingSource(value);
        this.setState({ [name]: value, fundingSources: fundingSources });
    };



    onHandleEntityAccreditationChange = (event) => {
        const { name, value } = event.target;

        this.getEntityAccreditationByID(value);
        this.setState({ [name]: value });
    };

    performFundingSource = async (entityId) => {
        const { userId } = this.state;
        try {

            const { data: BankData } = await RequestService.post({
                url: `/api/getInvestorFundingSourceData/`,
                data: {
                    entityId,
                    userId
                },
            });
            return BankData;
        } catch (e) {

            console.log(e)
        }

    }

    getEntityByEntityID = async (id) => {
        try {
            const { data } = await RequestService.get(`/api/getEntityByEntityID/${id}`);

            this.setState({ EntityDetails: data[0] });
            this.setState({ ThirdPartyAccreditationExpiresAt: data[0]?.tpAccreditationExpiresAt, ThirdPartyAccreditationCertifiedAt: data[0]?.tpAccreditationCertifiedAt, ThirdPartyAccreditationStatus: data[0]?.tpAccreditationStatus  })

            this.getEntityAccreditationList(id);
        }
        catch (err) {
            console.log(err);
        }
    };

    updateIsThirdPartyAccredited = async (EntityID, Email) => {
        if (EntityID && this.state.ThirdPartyAccreditationCertifiedAt && this.state.ThirdPartyAccreditationExpiresAt && !!this.state.ThirdPartyAccreditationStatus) {
            if(!!this.state.ThirdPartyAccreditationUpdateNote){
                this.setState({noteError: false});
            const IsThirdPartyAccredited = (this.expiryDate(moment(this.state.ThirdPartyAccreditationExpiresAt).format('YYYY-MM-DD')) >= 0 && this.state.ThirdPartyAccreditationStatus == 'current') ? 1 : 0;
            const result = await swal({
                title: 'Do you want to change the Third Party Accredited Status?',
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Change Status',
            });
            if (result.value) {
                    let formBody = [];
                    formBody.push("certifiedAt=" + encodeURIComponent(moment(this.state.ThirdPartyAccreditationCertifiedAt).format('YYYY-MM-DD')));
                    formBody.push("expiresAt=" + encodeURIComponent(moment(this.state.ThirdPartyAccreditationExpiresAt).format('YYYY-MM-DD')));
                    formBody.push("notes=" + encodeURIComponent(this.state.ThirdPartyAccreditationUpdateNote));
                    formBody.push("EntityID=" + encodeURIComponent(EntityID));
                    formBody.push("IsThirdPartyAccredited=" + encodeURIComponent(IsThirdPartyAccredited));
                    formBody.push("tpAccreditationStatus=" + encodeURIComponent(this.state.ThirdPartyAccreditationStatus))
                    formBody.push("Email=" + encodeURIComponent(Email));
                    formBody = formBody.join("&");
                    try {
                        const { data } = await RequestService.post({
                            url: '/api/updateIsThirdPartyAccredited',
                            data: formBody,
                        });
                        console.log("userrr updated", data);
                        if (data.code === 200) {
                            await this.getEntityByEntityID(EntityID);
                            swal('Third Party Accredited Details Updated!', '', 'success');
                        } else {
                            swal('Can not update Third Party Accredited Details!', '', 'error');
                        }
                    }
                    catch (err) {
                        // swal('Can not update Is Third Party Accredited Status!', '', 'error');

                        console.log("error on ui", err);
        }}
    }else{
        this.setState({noteError: true});
    }
        }else{
            if(!EntityID || EntityID == 0 ){
                swal('Please Select a Entity', '', 'info');
            }else{
            swal('Please Fill all Fields!', '', 'error');
            }
        }
    };



    getEntityAccreditationByID = async (id) => {
        try {
            if (id > 0) {
                const { data } = await RequestService.get(`/api/getEntityAccreditationByID/${id}`);

                this.setState({ EntityAccreditationDetails: data[0] });
            }
        }
        catch (err) {
            console.log(err);
        }
    };



    changeW9Status = async () => {
        const result = await swal({
            title: 'Do you want to change the W9 Status?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Change W9 Status',
        });

        if (result.value) {
            try {
                const { data: status } = await RequestService.post({
                    url: `/api/changeW9Status`,
                    data: {
                        UserID: this.state.userId,
                        EntityID: this.state.EntityDetails.EntityID,
                        w8n9formVerify: !this.state.EntityDetails.w8n9formVerify
                    }
                });

                if (status.code === 200) {
                    this.getEntity(this.state.EntityDetails.EntityID);
                    this.setState({ selectedEntity: this.state.EntityDetails.EntityID });

                    swal('W9 Form Status Updated!', '', 'success');
                }
                else {
                    alert('Could not update form status.');
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    };



    renderEntitySelectInput = () => {
        const { Entity, selectedEntity } = this.state;
        const _entity = Entity.map(e => ({
            id: e.EntityID,
            title: e.EntityLabel,
            isDisabled: (e.IsDisabled ? e.IsDisabled : '')
        }));

        return (
            <SelectComponent
                xs={12}
                name="selectedEntity"
                onChange={this.onHandleEntityChange}
                options={_entity}
                selectTitle="Investor entities"
                value={selectedEntity}
            />
        );
    };



    renderEntityAccreditationSelectInput = () => {
        const { EntityAccreditation, selectedEntityAccreditation } = this.state;
        const _entityAccreditation = EntityAccreditation.map(e => ({
            id: e.EntityAccreditationProfileID,
            title: `Date: ${new Date(e.DateEntered).toLocaleDateString('en-us', DATE_FORMAT_MDY_HIS)} - V${e.UserVersion}`,
        }));
console.log("selectedEntityAccreditation", selectedEntityAccreditation)
        return (
            <SelectComponent
                xs={12}
                name="selectedEntityAccreditation"
                onChange={this.onHandleEntityAccreditationChange}
                options={_entityAccreditation}
                value={selectedEntityAccreditation}
                disableNone={false}
            />
        );
    };



    renderAccreditationModal = () => {
        const { EntityAccreditationProfileID, DateEntered, UserVersion, marital_status,
            Two_back_year_income, Last_year_income, Projected_current_year_income, net_worth,
            investmentsFor, RiskTolerance, CurrentInvestments, profCertifications, IsAccreditated, Text
        } = this.state.EntityAccreditationDetails;

        const DateEnteredFormatted = new Date(String(DateEntered)).toLocaleDateString('en-us', DATE_FORMAT_MDY_HIS);
        const DateEnteredYear = new Date(DateEnteredFormatted).getFullYear();

        return (
            <Modal
                className="no-margin"
                show={this.state.isAccreditationModalOpen}
                onHide={this.onCloseAccreditationModal}
            >
                <Modal.Header closeButton>
                    <h3 className="heading-text capitalize-text">
                        Accreditation Information
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                elementValue={String(EntityAccreditationProfileID)}
                                maxInputLength={null}
                                inputTitle="ID"
                            />
                        </div>
                        <div className="col-xs-8">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={DateEnteredFormatted}
                                inputTitle="Date Entered"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={UserVersion}
                                inputTitle="User Version"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={marital_status}
                                inputTitle="Marital Status"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={RiskTolerance}
                                inputTitle="Risk Tolerance"
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Projected_current_year_income}
                                inputTitle={DateEnteredYear + ' Projected Income'}
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Last_year_income}
                                inputTitle={DateEnteredYear - 1 + ' Income'}
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Two_back_year_income}
                                inputTitle={DateEnteredYear - 2 + ' Income'}
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={net_worth}
                                inputTitle="Net Worth"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={investmentsFor}
                                inputTitle="Investments For"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={CurrentInvestments}
                                inputTitle="Current Investments"
                            />
                        </div>
                        <div className="col-xs-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={profCertifications === 1 ? 'Yes' : 'No'}
                                inputTitle="Professional Certifications"
                            />
                        </div>
                        <div className="col-xs-6">
                            <CheckboxInputComponent
                                checkTitle=" Is Accredited"
                                checked={IsAccreditated === 1}
                                divStyle={{ float: 'left' }}
                            />
                            <HideComponent hide={IsAccreditated === 0}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={String(EntityAccreditationProfileID)}>
                                            <div style={{ padding: 5, fontSize: '1.2em' }}>{Text}</div>
                                        </Tooltip>}>
                                    <a style={{ cursor: 'pointer' }}><Icon iconName="info-circle" extraClasses="card-info tooltip-margin" /></a>
                                </OverlayTrigger>
                            </HideComponent>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-xs-12">
                        <div className="pull-left">

                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };

    entityTaxIDInputChangeHandler = (event) => {
        const { EntityTaxIDCollection } = { ...this.state };
        const currentState = EntityTaxIDCollection;
        const { name, value } = event.target;
        currentState[name] = value;
        this.setState({ EntityTaxIDCollection: currentState });

    }
 expiryDate = (date)=> {
        let date_string = date?.toString();
        var expiration = moment(date_string).format("YYYY-MM-DD");
        var current_date = moment().format("YYYY-MM-DD");
        var days = moment(expiration).diff(current_date, 'days');
        console.log("remaining days",days);
        return days;
      }

    render() {
        const {
            EntityDetails, isNewEntityModalOpen, isNewBankAccountModalOpen, isReceiveOnlyModalOpen, userId, Entity, fundingSources, EntityTaxIDCollection, selectedEntityAccreditation
        } = this.state;

        const {
            EntityID, EntityName, EntityType, Address, City, State, ZipCode, Country, Phone,
            ContactPrefSMS, IsAccreditated, verifiedAccounts, LegalFirstName, LegalLastName,
            DwollaVerificationStatus, Text, Deleted, w8n9formVerify, Tax4, DOB, tpAccreditationExpiresAt
        } = EntityDetails;
        const { Email } = this.props;
        const accreditationStatus = [{
            id: 'current',
            title: 'Current',
        },{
            id: 'pending',
            title: 'Pending',
        },{
            id: 'submitter_pending',
            title: 'Submitter Pending',
        },{
            id: 'third_party_pending',
            title: 'Third Party Pending',
        },{
            id: 'expired',
            title: 'Expired',
        },{
            id: 'rejected',
            title: 'Rejected',
        },]

        return (
            <CollapsiblePanelComponent
                panelTitle={<h3 className="heading-text">Entities</h3>}
                isExpanded
            >
                {this.renderEntitySelectInput()}
                {(Entity.length < 10) && (
                    <div className="pull-right">
                        <button
                            onClick={this.toggleReceiveOnlyEntityModal}
                            className="btn btn-primary btn-green"
                            type="button"
                        >
                            <Icon iconName="plus" extraClasses="white-text" />
                            {' '}
                            Add a Receive Only Entity
                        </button>
                        <button
                            onClick={this.toggleNewEntityModal}
                            className="btn btn-primary btn-green"
                            type="button"
                        >
                            <Icon iconName="plus" extraClasses="white-text" />
                            {' '}
                            Add a New Entity
                        </button>
                        <button
                            onClick={this.toggleNewBankAccountModal}
                            className="btn btn-primary btn-green"
                            type="button"
                        >
                            <Icon iconName="plus" extraClasses="white-text" />
                            {' '}
                            Add Bank Account
                        </button>
                    </div>
                )}
                <NewEntityContainerComponent
                    parentUpdateMethod={this.getEntityName}
                    onCloseModal={this.toggleNewEntityModal}
                    isModalOpen={isNewEntityModalOpen}
                    investorId={userId}
                    isAdmin
                />
                <NewBankAccountContainerComponent
                    Entity={this.state.Entity}
                    parentUpdateMethod={this.getEntityName}
                    onCloseModal={this.toggleNewBankAccountModal}
                    isModalOpen={isNewBankAccountModalOpen}
                    investorId={userId}
                    isAdmin
                />
                <ReceiveOnlyEntityModal
                    Entity={this.state.Entity}
                    onCloseModal={this.toggleReceiveOnlyEntityModal}
                    isModalOpen={isReceiveOnlyModalOpen}
                    investorId={userId}
                    Email={Email}
                    isAdmin
                />
                <div className="col-xs-12">
                    <fieldset>
                        <legend>Entity Info
                            {Deleted === 1 && (
                                <div className="pull-right">
                                    <button
                                        className="btn btn-danger"
                                        type="button"
                                    >
                                        <Icon iconName="warning" extraClasses="white-text" />
                                        {' '}
                                        THIS ENTITY IS DELETED
                                    </button>
                                </div>
                            )}
                        </legend>

                        <div className="col-xs-12 col-md-6">
                            <SingleLineInputComponent
                                elementValue={String(EntityID)}
                                maxInputLength={null}
                                inputTitle="ID"
                            />
                        </div>
                        <div className="col-xs 12 col-md-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={EntityType}
                                inputTitle="Type"
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={EntityName}
                                inputTitle="Entity Name"
                            />
                        </div>
                        <div className="col-xs-3">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={LegalFirstName}
                                inputTitle="Legal FN"
                            />
                        </div>
                        <div className="col-xs-3">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={LegalLastName}
                                inputTitle="Legal LN"
                            />
                        </div>
                        <div className="col-xs-6">
                            <DateInputComponent
                                elementValue={moment(DOB).format('YYYY-MM-DDTHH:mm:ss')}
                                inputTitle="Date of Birth"
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={DwollaVerificationStatus}
                                inputTitle="Dwolla Status"
                            />
                        </div>
                        <div className="col-xs-6">
                            <CheckboxInputComponent
                                checkTitle=" Bank Account Verified"
                                checked={verifiedAccounts > 0}
                            />
                            </div>
            <div className="col-xs-6">
                            <CheckboxInputComponent
                                checkTitle=" Is Accredited"
                                checked={IsAccreditated === 1}
                                divStyle={{ float: 'left' }}
                            />
                            <HideComponent hide={IsAccreditated === 0}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={String(EntityID)}>
                                            <div style={{ padding: 5, fontSize: '1.2em' }}>{Text}</div>
                                        </Tooltip>}>
                                    <a style={{ cursor: 'pointer' }}><Icon iconName="info-circle" extraClasses="card-info tooltip-margin" /></a>
                                </OverlayTrigger>
                            </HideComponent>
                        </div><div className="col-xs-6">
                            <CheckboxInputComponent
                                checkTitle=" W9 Completed"
                                checked={w8n9formVerify === 1}
                                onClick={this.changeW9Status}
                            // divStyle={{float: 'right'}}
                            />
                        </div>
                        {/* <div className="col-xs-3">
							<SingleLineInputComponent
								maxInputLength={null}
								elementValue={Tax4}
								inputTitle="Last 4 Tax ID"
							/>
						</div> */}
                    </fieldset>
                </div>
                <div className="col-md-12 my-4">
                    <fieldset>
                        <legend>Entity Tax ID</legend>
                    </fieldset>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">SSN</label>
                            <input type="text" className="form-control input" value={EntityTaxIDCollection?.SSN}
                                name="SSN"
                                onChange={this.entityTaxIDInputChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">EIN</label>
                            <input type="text" className="form-control input" value={EntityTaxIDCollection?.EIN}
                                name="EIN"
                                onChange={this.entityTaxIDInputChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">Tax Classification</label>
                            <input type="text" className="form-control input" value={EntityTaxIDCollection?.TaxClassification}
                                name="TaxClassification"
                                onChange={this.entityTaxIDInputChangeHandler}
                            />
                        </div>

                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">Notes</label>
                            <input type="text" className="form-control input" value={EntityTaxIDCollection?.Notes}
                                name="Notes"
                                onChange={this.entityTaxIDInputChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 text-center">
                        <button
                            onClick={this.updateEntityTaxIDCollection}
                            className="btn btn-primary btn-green"
                            type="button"
                            style={{ marginTop: 3 }}
                        >
                            Update Tax Details
                        </button>

                    </div>
                </div>
                <div className="col-md-12 my-4">
                    <fieldset>
                        <legend>  Third Party Accredited <span><input id='IsThirdPartyAccredited'
                            name={'IsThirdPartyAccredited'}
                            type="checkbox"
                            checked={tpAccreditationExpiresAt ? this.expiryDate(tpAccreditationExpiresAt) >= 0 : false}
                            disabled={true}
                            className="offerings-header-checkbox"
                        />
                            <label htmlFor={'IsThirdPartyAccredited'} className="no-padding fontSize14">
                                {' '}
                            </label></span></legend>
                    </fieldset>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">Submitted On</label>
                            <input id={'ThirdPartyAccreditationCertifiedAt'}
                                onChange={(e) => this.setState({ ThirdPartyAccreditationCertifiedAt: e.target.value })}
                                className="form-control input"
                                name={'ThirdPartyAccreditationCertifiedAt'}
                                type={'date'}
                                value={!!this.state.ThirdPartyAccreditationCertifiedAt && moment(this.state.ThirdPartyAccreditationCertifiedAt).format('YYYY-MM-DD')} />
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <label className="input-label">Expires On</label>
                            <input id={'ThirdPartyAccreditationExpiresAt'}
                                onChange={(e) => this.setState({ ThirdPartyAccreditationExpiresAt: e.target.value })}
                                className="form-control input"
                                name={'ThirdPartyAccreditationExpiresAt'}
                                type={'date'}
                                value={!!this.state.ThirdPartyAccreditationExpiresAt && moment(this.state.ThirdPartyAccreditationExpiresAt).format('YYYY-MM-DD')} />
                        </div>
                    </div>
                    <div className="">
                        <div className="form-group">
                            {/* <label className="input-label">Status</label> */}
                            <SelectComponent
                xs={12}
                name="ThirdPartyAccreditationStatus"
                onChange={(e)=> 
                    this.setState({ThirdPartyAccreditationStatus: e.target.value })
                    // console.log("selected valueeee", e.target.value)
                }
                selectTitle="Status"
                options={accreditationStatus}
                value={this.state.ThirdPartyAccreditationStatus}
                disableNone={true}
            />
                        </div>
                    </div>
                    {this.state?.tpanotes?.length > 0 && <div className="col-xs-12">
                    <table className="table table-striped jambo_table">
                        <thead>
                            <tr>
                                <th>Edited By</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                           {this.state?.tpanotes?.map(i => <tr>
                                <td><div>{i?.firstName} {i?.lastName}</div>
                                <div>{moment(i?.createdAt).format('MM-DD-YYYY')}</div></td>
                                <td>{i?.notes}</td>
                            </tr>) }

                        </tbody>
                    </table>
                    </div>}

                    <div className="col-xs-12">
                        <div className="form-group">
                            <label className="input-label">Notes<span className="text-danger">*</span></label>
                            <textarea id={'ThirdPartyAccreditationUpdateNote'}
                                onChange={(e) => this.setState({ ThirdPartyAccreditationUpdateNote: e.target.value })}
                                className="form-control input"
                                name={'ThirdPartyAccreditationUpdateNote'} />
                        </div>
                    </div>
                    {(!this.state.ThirdPartyAccreditationUpdateNote && this.state.noteError) && <p className="text-danger pt-4 mb-0 pl-4">Please Enter Note</p>}
                    <div className="col-xs-12 text-center">
                        <button
                            onClick={() => this.updateIsThirdPartyAccredited(EntityID, Email)}
                            className="btn btn-primary btn-green"
                            type="button"
                            style={{ marginTop: 3 }}
                        >
                            Update Details
                        </button>

                    </div>
                </div>
                <div className="col-xs-12  my-4">
                    <fieldset>
                        <legend>Bank Accounts</legend>
                    </fieldset>
                    {fundingSources?.map(entity => (<table className="table table-striped jambo_table">
                        <thead>
                            <tr>
                                <th>Entity Name</th>
                                <th>Bank Name </th>
                                <th >Account Number </th>
                                <th>Account Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{entity?.name}</td>
                                <td>{entity?.bankName}</td>
                                <td >{numberMaskify(entity?.AccountNumber)} </td>
                                <td>{entity?.bankAccountType}</td>
                            </tr>

                        </tbody>
                    </table>))}
                </div>
                <div className="col-xs-12">
                    <fieldset>
                        <legend>Accreditation Info</legend>
                    </fieldset>
                    <div className="col-xs-8">
                        {this.renderEntityAccreditationSelectInput()}
                    </div>
                    <div className="col-xs-4">
                        <button
                            onClick={this.viewAccreditationInfo}
                            className="btn btn-primary btn-green"
                            type="button"
                            style={{ marginTop: 3 }}
                        >
                            View Info
                        </button>
                    </div>
                </div>

                {this.renderAccreditationModal()}

                <div className="col-xs-12"><br /></div>

                <div className="col-xs-12">
                    <fieldset>
                        <legend>Contact</legend>

                        <div className="col-xs-12">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Address}
                                inputTitle="Address"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={City}
                                inputTitle="City"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={State}
                                inputTitle="State"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={ZipCode}
                                inputTitle="Zip Code"
                            />
                        </div>
                        <div className="col-xs-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Phone}
                                inputTitle="Phone"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <SingleLineInputComponent
                                maxInputLength={null}
                                elementValue={Country}
                                inputTitle="Country"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <CheckboxInputComponent
                                checkTitle=" Receive SMS Messages"
                                checked={!!ContactPrefSMS}
                            />
                        </div>
                    </fieldset>
                </div>
            </CollapsiblePanelComponent>
        );
    }
}

const { any, object, bool } = PropTypes;

InvestorEntityViewerContainerComponent.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
    UserID: any.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(InvestorEntityViewerContainerComponent));

import React from 'react';

export const InvestorItems = {
    sectionTitle: 'Investor',
    isOpen: true,
    linkList: [
		{ route: '/app/Allinvestments', iconName: 'imgIconGraph',iconHoverName: 'imgIconDashboardHover', title: 'Investment Opportunities' },
        { route: '/app/investorDashboard', iconName: 'imgIconDashboard', title: 'Dashboard' },
        // { route: '/app/Investments', iconName: 'imgIconMoneyBag', title: 'My Investments' },
        // { route: '/app/Earnings2', iconName: 'imgIconWallet', title: 'My Earnings' },
        { route: '/app/MyBankAccounts', iconName: 'imgIconBank', title: 'My Bank Accounts' },
        // { route: '/app/investor/my-files', iconName: 'file', title: 'My Documents' },
        { route: '/app/UserNotifications', iconName: 'imgIconNotifications', title: 'View Project Updates' },
        // { route: '/app/investor/email/history', iconName: 'history', title: 'My Email History' },
        { route: '/app/Profile', iconName: 'imgIconSettings', title: 'My Account Settings' },
    ],
};
export const NewInvestorItems = {
    sectionTitle: 'Investor',
    isOpen: true,
    linkList: {
        dashboard:[ 
            // { route: '/app/investorDashboard', iconName: 'imgIconDashboard',iconHoverName: 'imgIconDashboardHover', title: 'Dashboard' },
            { route: '/app/UserNotifications',iconName: 'imgIconProjectUpdates',iconHoverName: 'imgIconProjectUpdatesHover', title: 'Fund Overview' },
        { route: '/app/InvestmentOpportunities', iconName: 'imgIconInvestmentOpportunities',iconHoverName: 'imgIconInvestmentOpportunitiesHover', title: 'Investment Opportunities' }
    ],
        yourFund:[
  { route: '/app/Allinvestments', iconName: 'imgIconGraph',iconHoverName: 'imgIconDashboardHover', title: 'Investment Opportunities' },
{ route: '/app/Earnings2', iconName: 'imgIconEarnings',iconHoverName: 'imgIconEarningsHover', title: 'Earnings' },
{ route: '/app/UserNotifications',iconName: 'imgIconProjectUpdates',iconHoverName: 'imgIconProjectUpdatesHover', title: 'Project Updates' },

],
yourAccount:[
    { route: '/app/Earnings2', iconName: 'imgIconEarnings',iconHoverName: 'imgIconEarningsHover', title: 'Investments & Earnings' },
    { route: '/app/investor/my-files',iconName: 'imgIconDocument',iconHoverName: 'imgIconDocumentHover', title: 'Documents' },
    // { route: '/app/MyBankAccounts', iconName: 'imgIconBank',iconHoverName: 'imgIconBankHover', title: 'Bank Accounts' },
 { route: '/app/Messages',iconName: 'imgIconMessage',iconHoverName: 'imgIconMessageHover', title: 'Messages and Support' },
  { route: '/app/Profile', iconName: 'imgIconSettings',iconHoverName: 'imgIconSettingsHover', title: 'Account Settings' },
]
    }	
    //    
    //     // { route: '/app/Investments', iconName: 'imgIconMoneyBag', title: 'My Investments' },
    //     // { route: '/app/Earnings2', iconName: 'imgIconWallet', title: 'My Earnings' },
    //    
    //     // 
    //     ,
    //     // { route: '/app/investor/email/history', iconName: 'history', title: 'My Email History' },
    //    
    // ],

};


export const FundRaiserItems = {
    sectionTitle: 'Fundraiser',
    isOpen: true,
    linkList: [
        { route: '/app/Myprojects', iconName: 'bar-chart-o', title: 'Submit Projects' },
        { route: '/app/UserInvestments', iconName: 'bar-chart-o ', title: 'My Fund Raises' },
    ],
};

export const SuperAdminItems = {
    sectionTitle: 'Admin',
    isOpen: true,
    linkList: {
        admin: [{ route: '/app/admindashboard', iconName: 'home', title: 'Dashboard' },
        { route: '/app/investors', iconName: 'group', title: 'Investors' },
        { route: '/app/fundraisers', iconName: 'group', title: 'FundRaisers' },
        { route: '/app/distribute/earnings', iconName: 'money', title: 'Distribute Earnings' },
        { route: '/app/distribute/return-of-capital', iconName: 'briefcase', title: 'Distribute Return Of Capital' },
        { route: '/app/adminapproveprojects', iconName: 'bar-chart-o', title: 'Approve Projects' },
        { route: '/app/EditProjects', iconName: 'bar-chart-o', title: 'Edit Project investments' },
        { route: '/app/previewprojectinvestments', iconName: 'bar-chart-o', title: 'Preview Project Investments' },
        { route: '/app/Allinvestments', iconName: 'bar-chart-o', title: 'All Investments' },
        { route: '/app/AdminuserInvestments', iconName: 'bar-chart-o ', title: 'User Investments' },
        { route: '/app/Refund', iconName: 'bar-chart-o', title: 'Refund User Investment' },
        { route: '/app/CompanyEarnings', iconName: 'money', title: 'Company Earnings' },
        { route: '/app/AdminProjectUpdate', iconName: 'bell-o', title: 'Send Project Updates' },
        { route: '/app/ApproveComments', iconName: 'comments', title: 'Approve Comments' },
        { route: '/app/admin/investor/documents', iconName: 'files-o', title: 'All Investor Documents' },
        { route: '/app/admin/tracking', iconName: 'compass', title: 'All Tracking Data' },
        // { route: "/app/admin/project/status", iconName: "bullseye", title: "Project Status" },   // Will Be Completed at a Later Date.
        { route: '/app/admin/email/history', iconName: 'history', title: 'All Email History' },
        { route: '/app/admin/hubspot/email/import', iconName: 'envelope-o', title: 'HubSpot Email Import' },
    ]},
};

export const AdminItems = {
    sectionTitle: 'Admin',
    isOpen: true,
    linkList: {
      admin:  [{ route: '/app/admindashboard', iconName: 'home', title: 'Admin Dashboard' },
        { route: '/app/investors', iconName: 'group', title: 'Investors' },
        { route: '/app/fundraisers', iconName: 'group', title: 'FundRaisers' },
        { route: '/app/distribute/earnings', iconName: 'money', title: 'Distribute Earnings' },
        { route: '/app/distribute/return-of-capital', iconName: 'briefcase', title: 'Distribute Return Of Capital' },
        { route: '/app/adminapproveprojects', iconName: 'bar-chart-o', title: 'Approve Projects' },
        { route: '/app/EditProjects', iconName: 'bar-chart-o', title: 'Publish Projects' },
        { route: '/app/previewprojectinvestments', iconName: 'bar-chart-o', title: 'Preview Project Investments' },
        { route: '/app/Allinvestments', iconName: 'bar-chart-o', title: ' All Investments' },
        { route: '/app/AdminuserInvestments', iconName: 'bar-chart-o ', title: 'User Investments' },
        { route: '/app/CompanyEarnings', iconName: 'money ', title: 'Company Earnings' },
        { route: '/app/AdminProjectUpdate', iconName: 'bell-o ', title: 'Send Project Updates' },
        { route: '/app/AdminCommunication', iconName: 'clock-o ', title: 'Communication' },
        { route: '/app/ApproveComments', iconName: 'comments ', title: 'Approve Comments' },
        { route: '/app/admin/investor/documents', iconName: 'files-o', title: 'All Investor Documents' },
        { route: '/app/admin/tracking', iconName: 'compass', title: 'All Tracking Data' },
        // { route: "/app/admin/project/status", iconName: "bullseye", title: "Project Status" },   // Will Be Completed at a Later Date.
        { route: '/app/admin/email/history', iconName: 'history', title: 'All Email History' },
      ]}
};
